import React, {useCallback, useEffect, useMemo, useState} from "react"
import { Context as AuthContext } from './context'
import API, { fetchResource, METHOD } from '../../app/API';
import {USER_TYPES} from "../profile/constants";
import { useLocation } from 'react-router-dom';

const TOKEN_NAME = 'token'

async function getToken() {
  const token = await localStorage.getItem(TOKEN_NAME);

  return token || '';
}

export function Provider({ children }) {
  const [inSafeLocation, setInSafeLocation] = useState('');
  const [isAuth, setIsAuth] = useState(false);
  const [profile, setProfile] = useState({});
  const [userEmail, setUserEmail] = useState('');
  const location = useLocation();

  useState(() => {
    if (!inSafeLocation && !isAuth && !location.pathname.match('auth')) {
      setInSafeLocation(location.pathname);
    }
  }, [location, inSafeLocation, isAuth]);

  const signIn = useCallback(async ({ email, password }) => {
    try {
      const {data, errors} = await fetchResource({
        method: METHOD.POST,
        url: API.AUTH.SIGN_IN,
        data: {
          email,
          password,
        },
      });

      if (data) {
        await localStorage.setItem(TOKEN_NAME, `Bearer ${data.access_token}`);
        await localStorage.setItem('role', data.role);
        setProfile(data);
        setIsAuth(true);
      }
      return errors;
    } catch (e) {
      console.log(e, 'failed login');
      return null;
    }
  }, []);

  const checkToken = useCallback(async () => {
    try {
      const token = await getToken();

      if (token) {
        const { data } = await fetchResource({
          url: API.AUTH.CHECK_ACCESS,
          method: METHOD.GET,
          headers: {
            Authorization: token,
          },
        });

        setIsAuth(!!data);
      } else {
        setIsAuth(false);
      }
    } catch (e) {
      console.log(e, 'failed check access');
      setIsAuth(false);
    }
  }, []);

  useEffect(() => {
    // TODO: remove profile from profile reducer and use only here as a single true value.
    ;(async function() {
      const role = await localStorage.getItem('role');

      setProfile({
        role
      });
    })();
  }, []);

  const authContext = useMemo(
    () => ({
      isAuth,
      profile,
      email: userEmail,
      inSafeLocation,

      setIsAuth,
      setInSafeLocation,

      signUp: async ({ email, password, phone, company, user }) => {
        try {
          const params = {
            email,
            password,
            phone,
            company: user === USER_TYPES.RECRUITER ? company : undefined,
          };

          const { errors } = await fetchResource({
            method: METHOD.POST,
            url: API.AUTH.SIGN_UP.REQUEST(user),
            data: params,
          });

          return errors;
        } catch (error) {
          console.error("Failed sign up", error)
        }
      },
      signOut: async () => {
        try {
          const token = await getToken()

          if (token) {
            const { data } = await fetchResource({
              method: METHOD.POST,
              url: API.AUTH.SIGN_OUT,
              headers: {
                Authorization: token,
              },
            });

            if (data) {
              await localStorage.removeItem(TOKEN_NAME);
              setIsAuth(false);
            }
          } else {
            setIsAuth(false);
          }
        } catch (error) {
          console.error("Failed logOut", error)
        }
      },
      signIn,
      checkToken,
      restorePass: async ({ email }) => {
        try {
          const { errors } = await fetchResource({
            url: API.AUTH.RESET.REQUEST,
            method: METHOD.PUT,
            data: {
              email,
            },
          });

          setUserEmail(email);

          return errors
        } catch (e) {
          console.log(e, 'failed restore password');
        }
      },
      confirmRestorePass: async ({ key }) => {
        try {
          const { errors } = await fetchResource({
            url: API.AUTH.RESET.CHECK(key),
            method: METHOD.GET,
          });

          return errors;
        } catch (e) {
          console.log(e, 'failed confirmation');
        }
      },
      sendNewPassword: async ({ password, token }) => {
        try {
          const { errors, data } = await fetchResource({
            url: API.AUTH.RESET.CONFIRM,
            method: METHOD.PUT,
            data: {password, token},
          });

          if (data) {
            await signIn({ password, email: userEmail });
          }

          return errors;
        } catch (e) {
          console.log(e, 'failed send new password');
        }
      },
      confirmSignUp: async ({ key }) => {
        try {
          const { errors, data } = await fetchResource({
            method: METHOD.GET,
            url: API.AUTH.SIGN_UP.CONFIRM(key),
          });

          if (data) {
            await localStorage.setItem(TOKEN_NAME, `Bearer ${data.access_token}`);
            setIsAuth(true);
          }

          return errors
        } catch (e) {
          console.log(e, 'failed confirm register');
          return null;
        }
      },
    }), [
      isAuth,
      userEmail,
      inSafeLocation,
      profile,
    ])

  return (
    <AuthContext.Provider value={authContext}>
      {children}
    </AuthContext.Provider>
  )
}
