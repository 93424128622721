import React, { useEffect } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import routs from '../../modules/router/routes';
import { Account } from './Account';
import { Page, Nav } from '../../components/ui-kit';
import { Settings } from './Settings';
import withBreadCrumb from './../../HOC/withBreadCrumb';
import {ProfileActions} from './../../modules/profile';
import './styles.sass';
import { useIntl } from 'react-intl';
import mapNav from '../../utils/mapNav';

const PAGES = {
  account: {
    page: Account,
    link: routs.PROFILE_ACCOUNT,
    text: { id: 'NAV.ACCOUNT.INFO' },
  },
  settings: {
    page: Settings,
    link: routs.PROFILE_SETTINGS,
    text: { id: 'NAV.ACCOUNT.SETTINGS' },
  },
};


const Profile = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  useEffect(() => {
    dispatch(ProfileActions.getProfile());
  }, []);

  return (
    <div className="Users has-nav">
      <Nav list={mapNav(Object.entries(PAGES), '', formatMessage)} />
      <div className="Users__scroll-view">
        <Switch>
          {
            Object.entries(PAGES).map(([key, { link, page: Page, text }]) => (
              <Route
                key={key}
                path={link}
                component={withBreadCrumb(Page, link, text)}
              />
            ))
          }
          <Redirect from={routs.PROFILE} to={routs.PROFILE_ACCOUNT} exact />
        </Switch>
      </div>
    </div>
  );
};

export default withBreadCrumb(Profile, routs.PROFILE, {id: 'NAV.ACCOUNT.INDEX'});
