import React from 'react';
import cx from 'classnames';
import './styles.sass';

const CommentCount = ({ count, unreadCount, onClick, isViewed, isHiddenCounter }) => (
  <div
    className={cx('CommentCount', {
      'is-unread': !isViewed,
      'is-not-empty': count > 0
    })}
    onClick={onClick}
  >
    <div className="icon-comment">
      {!!unreadCount && <div className="CommentCount__unread">{unreadCount}</div>}
    </div>
    {
      !isHiddenCounter && (
        <div className="CommentCount__counter">{count}</div>
      )
    }
  </div>
);

CommentCount.defaultProps = {
  unreadCount: 0,
};

export default CommentCount;
