import ProfileTypes from './constants';
import * as ProfileActions from './actions';

export const initialState = {
  flags: {
    profile: false,
    uploadAvatar: false,
    uploadCV: false,
    updating: false,
    restorePass: false,
    resetEmail: false,
    companyGet: false,
    companyUpd: false,
  },
  profile: {},
  company: null,
};


export default function (state = initialState, { type, payload }) {
  switch (type) {
    case ProfileActions.updateCompany.REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          companyUpd: true,
        },
      };
    case ProfileActions.updateCompany.SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          companyUpd: false,
        },
        profile: payload.data,
      };
    case ProfileActions.updateCompany.FAILURE:
      return {
        ...state,
        flags: {
          ...state.flags,
          companyUpd: false,
        },
      };

    case ProfileTypes.GET_COMPANY_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          companyGet: true,
        },
      };
    case ProfileTypes.GET_COMPANY_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          companyGet: false,
        },
        company: payload.data,
      };
    case ProfileTypes.GET_COMPANY_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          companyGet: false,
        },
      };

    // UPLOAD_FILE
    case ProfileTypes.UPLOAD_FILE_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          uploadCV: true,
        },
      };
    case ProfileTypes.UPLOAD_FILE_SUCCESS:
    case ProfileTypes.UPLOAD_FILE_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          uploadCV: false,
        },
      };

    // UPLOAD_IMAGE
    case ProfileTypes.UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          uploadAvatar: true,
        },
      };
    case ProfileTypes.UPLOAD_IMAGE_SUCCESS:
    case ProfileTypes.UPLOAD_IMAGE_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          uploadAvatar: false,
        },
      };

    // GET_PROFILE
    case ProfileTypes.GET_PROFILE_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          profile: true,
        },
      };
    case ProfileTypes.GET_PROFILE_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          profile: false,
        },
        profile: payload.data,
      };
    case ProfileTypes.GET_PROFILE_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          profile: false,
        }
      };

    // TODO: remove spreading of profile.
    case ProfileTypes.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          update: true,
        }
      };
    case ProfileTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          update: false,
        },
        profile: {
          ...payload.data,
          role: state.profile.role,
        },
      };
    case ProfileTypes.UPDATE_PROFILE_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          update: false,
        }
      };


    case ProfileTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          restorePass: true,
        }
      };
    case ProfileTypes.RESET_PASSWORD_SUCCESS:
    case ProfileTypes.RESET_PASSWORD_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          restorePass: false,
        }
      };

    case ProfileTypes.RESET_EMAIL_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          resetEmail: true,
        }
      };
    case ProfileTypes.RESET_EMAIL_SUCCESS:
    case ProfileTypes.RESET_EMAIL_FAILURE:
      return {
        ...state,
        flags: {
          ...state.flags,
          resetEmail: false,
        }
      };
    default:
      return state;
  }
}
