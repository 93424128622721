import React from 'react';
import Upload from './../Upload';
import Button from './../Button';
import cx from 'classnames';
import './styles.sass';
import { injectIntl } from 'react-intl';

type Props = {
  id: string,
  fileRenderer: (value: string) => any,
  onUpload: () => any,
  align: string,
  accepts: string[],
  label: string,
  readOnly: boolean,
}

class FormUploader extends React.Component<Props> {
  handleUpload = ({ FormData }) => {
    const {
      onUpload,
      input: {
        onChange,
      },
    } = this.props;

    onUpload({
      FormData,
      callBack: ({ name }) => onChange(name),
    })
  };

  handleDelete = () => {
    const {
      input: {
        onChange,
      },
    } = this.props;

    onChange('');
  };

  renderRemove = (isRendered) => {
    if (isRendered) {
      return (
        <Button
          color="link"
          label={this.props.deleteLabel}
          onClick={this.handleDelete}
        />
      );
    }

    return null;
  };

  render() {
    const {
      input: {
        value,
        name,
      },
      accepts,
      label,
      id,
      fileRenderer,
      align,
      loading,
      meta,
      required,
      intl: {
        formatMessage
      },
      btnColor,
    } = this.props;
    const hasError = Boolean(meta && meta.touched && meta.error);

    return (
      <div className={cx('FormUploader', { [`is-${align}`]: true })}>
        <input
          type="text"
          hidden
          id={id}
          name={name}
        />
        {fileRenderer && fileRenderer(
          value,
          this.renderRemove(value),
          this.handleDelete
        )}
        {value ? this.renderRemove(!fileRenderer) : (
          <Upload
            onChange={this.handleUpload}
            accepts={accepts}
          >
            <Button
              loading={loading}
              label={label}
              color={btnColor || 'link'}
              onClick={this.handleDelete}
            />
            <span className="FormUploader__required">{required ? '*' : ''}</span>
          </Upload>
        )}
        {hasError && (
          <div className="TextField__error">
            {formatMessage({ id: `ERRORS.${meta.error}` })}
          </div>
        )}
      </div>
    );
  }
}

FormUploader.defaultProps = {
  deleteLabel: 'Delete',
  align: 'left',
  required: false
};

export default injectIntl(FormUploader);
