import React, { useEffect } from 'react';
import type { FormProps } from 'redux-form';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useIntl } from 'react-intl';
import { ProjectActions, ProjectSelectors } from './../../../../modules/projects';
import { ProfileSelectors, ProfileActions } from './../../../../modules/profile';
import { Nav } from './../../../../components/ui-kit';
import Info from './Info';
import Questionnaire from './Questionnaire';
import mapNav from '../../../../utils/mapNav';
import Statuses from './Statuses';
import routes from '../../../../modules/router/routes';
import {useAuthContext} from "../../../../hooks";

type Props = {
  isEdit: boolean,
  submitAction: () => any,
} & FormProps


export const TABS = {
  info: {
    link: '/info',
    text: { id: 'CANDIDATES.TABS.INFO', }
  },
  questionnaire: {
    link: '/questionnaire',
    text: { id: 'CANDIDATES.TABS.QUEST' },
  },
  status: {
    link: '/status',
    text: { id: 'CANDIDATES.TABS.STATUS' },
  },
};
const filterNav = (array, ifAdd) => array.filter(({ key }) => {
  return !ifAdd || (ifAdd && key !== 'questionnaire' && key !== 'status')
});


const Candidate = ({
  match: {
    params,
    url,
  },
  candidate,
  actions,
  uploadingAvatar,
  profile,
  ...props
}: Props) => {
  const indexRoute = `${url}${TABS.info.link}`;
  const isEdit = params && params.candidateMode && params.candidateMode === 'edit';
  const hasId = params && params.candidateId;
  const ifView = (hasId && !isEdit);
  const ifEdit = (hasId && isEdit);
  const ifAdd = (!hasId && !isEdit);
  const readOnly = props.project && props.project.status === 3;
  const { formatMessage } = useIntl()
  useEffect(() => {
    if (params && params.candidateId) {
      actions.getCandidate(params);
    }
  }, []);
  const {profile: {role}} = useAuthContext();

  props.role = role;

  return (
    <>
      <BreadcrumbsItem to={url}>
        {Boolean((ifEdit || ifView) && candidate) && `${candidate.surname} ${candidate.name}`}
        {
          ifAdd && formatMessage({ id: 'CANDIDATES.NEW_CANDIDATE' })
        }
      </BreadcrumbsItem>
      <Nav list={filterNav(mapNav(Object.entries(TABS), url, formatMessage), ifAdd)} />
      <Switch>
        <Route
          path={url + TABS.info.link}
          render={(routProps) => (
            <>
              <BreadcrumbsItem to={url + TABS.info.link}>
                {formatMessage(TABS.info.text)}
              </BreadcrumbsItem>
              <Info
                {...props}
                ifView={ifView || readOnly}
                url={url}
                ifAdd={ifAdd}
                uploadingAvatar={uploadingAvatar}
                actions={actions}
                submitAction={ifAdd ? ProjectActions.addCandidate : ProjectActions.updateCandidate}
                routProps={params}
                profile={profile}
                initialValues={(candidate && (isEdit || ifView)) ? candidate : {
                  status_candidate: 0,
                }}
                formatMessage={formatMessage}
              />
            </>
          )}
        />
        <Route
          path={url + TABS.questionnaire.link}
          render={(routProps) => {
            if (!ifAdd) {
              return (
                <>
                  <BreadcrumbsItem to={url + TABS.questionnaire.link}>
                    {formatMessage(TABS.questionnaire.text)}
                  </BreadcrumbsItem>
                  <Questionnaire
                    {...props}
                    url={url}
                    actions={actions}
                    readOnly={ifView}
                    routProps={params}
                    formatMessage={formatMessage}
                  />
                </>
              )
            }

            return null;
          }}
        />
        <Route
          path={url + TABS.status.link}
          render={(routProps) => {
            if (!ifAdd) {
              return (
                <>
                  <BreadcrumbsItem to={url + TABS.status.link}>
                    {formatMessage(TABS.status.text)}
                  </BreadcrumbsItem>
                  <Statuses
                    {...props}
                    actions={actions}
                    routProps={routProps}
                    readOnly={ifView}
                    matchParams={params}
                    formatMessage={formatMessage}
                  />
                </>
              )
            }

            return null;
          }}
        />
        <Redirect from={routes.PROJECT() + '/candidates' + routes.CANDIDATE()} to={indexRoute} exact/>
      </Switch>
    </>
  );
};

export default withRouter(
  connect(
    state => ({
      project: ProjectSelectors.selectProject(state),
      flags: ProjectSelectors.selectFlags(state),
      statuses: ProjectSelectors.selectStatuses(state),
      candidateFields: ProjectSelectors.selectCandidateFields(state),
      candidate: ProjectSelectors.selectCandidate(state),
      comments: ProjectSelectors.selectComments(state),
      uploadingAvatar: ProfileSelectors.selectFlags(state).uploadAvatar,
      profile: ProfileSelectors.selectProfile(state),
    }),
    dispatch => ({
      actions: bindActionCreators({
        ...ProjectActions,
        uploadAvatar: ProfileActions.uploadAvatar,
      }, dispatch),
    }),
  )(Candidate),
);

