import { call, put, select } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';
import { push } from 'connected-react-router';
import {
  ProjectTypes,
  ProjectActions,
  ProjectSelectors
} from '../index';
import API, { METHOD, withToken } from '../../../app/API';
import { matchPath } from 'react-router';
import actionCreator, { errorNotify, successNotify } from '../../../utils/actionCreator';
import routs from './../../router/routes';
import { IntlSelectors } from './../../intl';
import { fitCommentToPattern } from '../utils';

export function* finishProject({ payload: {
  callBack,
  filter,
  project,
} }) {
  yield changeProjectStatus({
    payload: {
      project,
      status: 'FINISH',
      filter,
    },
  });
  yield put(actionCreator(ProjectTypes.FINISH_PROJECT_SUCCESS))
  callBack();
}

export function* changeProjectStatus({
 payload: {
   status,
   project,
   filter,
 }
}) {
  try {
    const SCHEME = {
      RESTORE: 1,
      FINISH: 3,
      ACTIVATE: 1,
    }

    const { data, errors } = yield call(withToken, {
      url: API.PROJECTS.PROJECT(project.id),
      method: METHOD.PUT,
      data: {
        ...project,
        status: SCHEME[status],
      },
    });

    if (data) {
      yield put(ProjectActions.changeProjectStatus.success());
      yield put(actionCreator(ProjectTypes.GET_PROJECTS_REQUEST, {
        status: filter.value
      }))
    } else {
      throw new SubmissionError(errors)
    }
  } catch (e) {
    yield put(actionCreator(ProjectActions.changeProjectStatus.FAILURE));
  }
}

export function* postReview({ payload: { params, callBack } }) {
  try {
    const { data } = yield call(withToken, {
      url: API.PROJECTS.LEAVE_REVIEW,
      data: params,
      method: METHOD.POST,
    })

    if (callBack) {
      callBack();
    }
    yield put(actionCreator(ProjectTypes.POST_REVIEW_SUCCESS, { data }));
  } catch (e) {
    yield put(actionCreator(ProjectTypes.POST_REVIEW_ERROR));
    // yield put(successNotify({ id: 'NOTIFICATIONS.SUCCESS.CHANGED_CANDIDATE_STATUS' }));
  }
}


export function* getProjectStatuses({ payload: { projectId } }) {
  try {
    const { data } = yield call(withToken, {
      url: API.PROJECTS.PROJECT_STATUSES.LIST,
      params: {
        project_id: projectId,
      },
      method: METHOD.GET,
    })

    yield put(actionCreator(ProjectTypes.GET_PROJECT_STATUSES_SUCCESS, { data }));
  } catch (e) {
    yield put(actionCreator(ProjectTypes.GET_PROJECT_STATUSES_ERROR));
  }
}

export function* postProjectComment({ payload: { projectId, message, callBack, file } }) {
  try {
    const { data } = yield call(withToken, {
      url: API.PROJECTS.PROJECT_STATUSES.POST,
      data: {
        project_id: projectId,
        message,
        file,
      },
      method: METHOD.POST,
    })
    yield put(actionCreator(ProjectTypes.GET_PROJECT_STATUSES_REQUEST, {
      projectId
    }));

    yield put(actionCreator(ProjectTypes.POST_PROJECT_COMMENT_SUCCESS, { data }));
    callBack();
  } catch (e) {
    yield put(actionCreator(ProjectTypes.POST_PROJECT_COMMENT_ERROR));
  }
}

export function* addCandidate({ payload }) {
  try {
    const pathname = yield select(state => state.router.location.pathname);
    const locale = yield select(IntlSelectors.selectCurrentLocale);
    const { params: { projectId, mode } } = matchPath(pathname, { path: routs.PROJECT() });

    const msg = fitCommentToPattern({
      candidate: `${payload.name} ${payload.surname}`,
      comment: payload.comments,
      locale,
    });

    const { data, errors } = yield call(withToken, {
      url: API.PROJECTS.CANDIDATE(),
      method: METHOD.POST,
      data: {
        ...payload,
        project_id: projectId,
        status_comment: msg
      },
    });

    if (data) {
      const rootRoute = payload.status_candidate === 0
        ? '/candidates' + routs.CANDIDATE({ mode: 'edit', candidateId: data.id }) +'/questionnaire'
        : '/stop-list';
      yield put(successNotify({ id: 'NOTIFICATIONS.SUCCESS.ADDED_CANDIDATE' }));
      yield put(ProjectActions.addCandidate.success({ data }));
      yield put(push(`${routs.PROJECT({ mode, projectId })}${rootRoute}`));
    } else {
      throw new SubmissionError(errors)
    }
  } catch (e) {
    yield put(ProjectActions.addCandidate.failure(e));
  }
}

export function* changeCandidateStatus({
  payload: {
    status,
    comment,
    salary,
    date,
    time,
    projectId,
  },
}) {
  try {
    const locale = yield select(IntlSelectors.selectCurrentLocale);
    const candidateSort = yield select(ProjectSelectors.selectCandidatesSortParams);

    const { data, errors } = yield call(withToken, {
      url: API.PROJECTS.CANDIDATES_STATUS(status.candidateId),
      method: METHOD.PUT,
      data: {
        status: +status.value,
        comment: fitCommentToPattern({ comment, date, time, status, salary, locale }),
      }
    });
    if (data) {
      yield put(successNotify({ id: 'NOTIFICATIONS.SUCCESS.CHANGED_CANDIDATE_STATUS' }));
      yield put(ProjectActions.changeCandidateStatus.success());
      yield put(actionCreator(ProjectTypes.GET_CANDIDATES_REQUEST, {
        sort: candidateSort,
        projectId,
        status: -7,
      }));
    }
    if (errors) {
      yield put(errorNotify({ id: 'NOTIFICATIONS.ERROR.UPDATE_STATUS' }));
      throw new SubmissionError(errors)
    }
  } catch (e) {
    yield put(ProjectActions.changeCandidateStatus.failure(e));
  }
}

export function* postComment({
  payload: {
    comment,
    callBack,
    stateId,
    type,
    file,
  }
}) {
  try {
    const locale = yield select(IntlSelectors.selectCurrentLocale);
    yield call(withToken, {
      url: API.PROJECTS.CANDIDATE_FEED,
      method: METHOD.POST,
      data: {
        type,
        state_id: stateId,
        //reply_id: ''
        file,
        message: fitCommentToPattern({ comment, locale }),
      }
    });

    yield put(actionCreator(ProjectTypes.POST_COMMENT_SUCCESS));
    yield put(actionCreator(ProjectTypes.GET_CANDIDATE_ACTIVITIES_REQUEST, {
      stateId,
      type,
    }));
    callBack();
  } catch (e) {
    yield put(actionCreator(ProjectTypes.POST_COMMENT_ERROR, { e }));
  }
}


export function* init() {
  const statuses = yield select(ProjectSelectors.selectStatuses);

  if (!Object.keys(statuses.guaranteed_period).length) {
    yield call(getStatuses);
  }
}

export function* getProject({ payload: { projectId } }) {
  try {
    const { data } = yield call(withToken, {
      url: API.PROJECTS.PROJECT(projectId),
      method: METHOD.GET,
    });

    yield put(actionCreator(ProjectTypes.GET_PROJECT_SUCCESS, { data }));
  } catch (e) {
    yield put(actionCreator(ProjectTypes.GET_PROJECT_ERROR, { e }));
  }
}


export function* addProject({ payload }) {
  try {
    const { data, errors } = yield call(withToken, {
      url: API.PROJECTS.CORE,
      method: METHOD.POST,
      data: {
        ...payload,
        guaranteed_period: payload.guaranteed_period ? payload.guaranteed_period.value : null,
        status: 2,
      },
    });

    if (errors) {
      throw new SubmissionError(errors)
    }

    if (data) {
      yield put(ProjectActions.addProject.success( data ));
      yield put(successNotify({ id: 'NOTIFICATIONS.SUCCESS.CREATED_PROJECT' }));
      yield put(push(routs.PROJECT({ projectId: data.id, mode: 'edit' }) + '/questionnaire'));
    }
  } catch (e) {
    yield put(ProjectActions.addProject.failure(e));
  }
}

export function* getProjects({ payload: { status } }) {
  try {
    const { data } = yield call(withToken, {
      url: API.PROJECTS.CORE,
      params: {
        status,
      },
      method: METHOD.GET,
    });

    yield put(actionCreator(ProjectTypes.GET_PROJECTS_SUCCESS, { data }));
  } catch (e) {
    yield put(actionCreator(ProjectTypes.GET_PROJECTS_ERROR, { e }));
  }
}

export function* updateProject({ payload: { id, ...rest } }) {
  try {
    const { data, errors } = yield call(withToken, {
      url: API.PROJECTS.PROJECT(id),
      method: METHOD.PUT,
      data: {
        ...rest,
        guaranteed_period: rest.guaranteed_period ? rest.guaranteed_period.value : null,
      },
    });

    if (data) {
      yield put(ProjectActions.updateProject.success({ data }));
    }
    if (errors) {
      throw new SubmissionError(errors)
    }
  } catch (e) {
    yield put(ProjectActions.updateProject.failure(e));
  }
}

export function* getFields({ payload: { projectId } }) {
  try {
    const { data } = yield call(withToken, {
      url: API.PROJECTS.FIELD(),
      method: METHOD.GET,
      params: {
        project_id: projectId,
      },
    });

    yield put(actionCreator(ProjectTypes.GET_FIELDS_SUCCESS, {
      data: data || [],
    }));
  } catch (e) {
    yield put(actionCreator(ProjectTypes.GET_FIELDS_ERROR, { e }));
  }
}

export function* getStatuses() {
  try {
    const { data } = yield call(withToken, {
      url: API.PROJECTS.STATUSES,
      method: METHOD.GET,
    });

    if (data) {
      yield put(actionCreator(ProjectTypes.GET_STATUSES_SUCCESS, { data }));
    } else {
      yield put(actionCreator(ProjectTypes.GET_STATUSES_ERROR));
    }
  } catch (e) {
    yield put(actionCreator(ProjectTypes.GET_STATUSES_ERROR, { e }));
  }
}

export function* addField({ payload: { projectId, name, callBack } }) {
  try {
    const fields = yield select(ProjectSelectors.selectProjectFields);
    const { data } = yield call(withToken, {
      url: API.PROJECTS.FIELD(),
      method: METHOD.POST,
      data: {
        project_id: projectId,
        name,
      },
    });

    if (data) {
      yield put(actionCreator(ProjectTypes.ADD_FIELD_SUCCESS, {
        data: [...fields, data],
      }));
      if (callBack) {
        callBack();
      }
    } else {
      yield put(actionCreator(ProjectTypes.ADD_FIELD_ERROR));
    }
  } catch (e) {
    yield put(actionCreator(ProjectTypes.ADD_FIELD_ERROR, { e }));
  }
}

export function* updateField({ payload: { name, order, id, callBack } }) {
  try {
    const fields = yield select(ProjectSelectors.selectProjectFields);

    yield call(withToken, {
      url: API.PROJECTS.FIELD(id),
      method: METHOD.PUT,
      data: {
        name,
        order,
      },
    });
    yield put(actionCreator(ProjectTypes.UPDATE_FIELD_SUCCESS, {
      data: fields.map((currentField) => {
        if (+currentField.id === +id) {
          return {
            ...currentField,
            name,
            order,
          };
        }

        return currentField;
      })
    }));

    if (callBack) {
      callBack();
    }
  } catch (e) {
    yield put(actionCreator(ProjectTypes.UPDATE_FIELD_ERROR, { e }));
  }
}

export function* deleteField({ payload: { id, callBack } }) {
  try {
    const fields = yield select(ProjectSelectors.selectProjectFields);
    const response = yield call(withToken, {
      url: API.PROJECTS.FIELD(id),
      method: METHOD.DELETE,
    });

    yield put(actionCreator(ProjectTypes.REMOVE_FIELD_SUCCESS, {
      data: fields.filter(prevField => +prevField.id !== +id),
      response,
    }));
    if (callBack) {
      callBack();
    }
  } catch (e) {
    yield put(actionCreator(ProjectTypes.REMOVE_FIELD_ERROR, { e }));
  }
}

export function* activateProject({ payload: { projectId } }) {
  try {
    const project = yield select(ProjectSelectors.selectProject);
    const { data, errors } = yield call(withToken, {
      url: API.PROJECTS.PROJECT(projectId),
      method: METHOD.PUT,
      data: {
        ...project,
        status: 1,
      },
    });

    if (data) {
      yield put(actionCreator(ProjectTypes.ACTIVATE_PROJECT_SUCCESS, {
        data: {
          ...project,
          status: 1,
        },
      }));
      yield put(successNotify({ id: 'NOTIFICATIONS.SUCCESS.PUBLISHED_PROJECT' }));
    } else {
      yield put(actionCreator(ProjectTypes.ACTIVATE_PROJECT_ERROR, { errors }));
      yield put(errorNotify({ id: 'NOTIFICATIONS.ERROR.ACTIVATE_PROJECT' }));
    }
  } catch(e) {
    yield put(actionCreator(ProjectTypes.ACTIVATE_PROJECT_ERROR, { e }));
  }
}

export function* getCandidates({ payload: { projectId, status, sort } }) {
  try {
    const { data } = yield call(withToken, {
      url: API.PROJECTS.CANDIDATES,
      method: METHOD.GET,
      params: {
        project_id: projectId,
        status_candidate: status,
        sort,
      },
    });

    yield put(actionCreator(ProjectTypes.GET_CANDIDATES_SUCCESS, { data, sort }));
  } catch (e) {
    yield put(actionCreator(ProjectTypes.GET_CANDIDATES_ERROR, { e }));
  }
}

export function* getRecruiters({ payload: { projectId } }) {
  try {
    const { data } = yield call(withToken, {
      url: API.PROJECTS.RECRUITERS(projectId),
      method: METHOD.GET,
    });

    yield put(actionCreator(ProjectTypes.GET_RECRUITERS_SUCCESS, { data }));
  } catch (e) {
    yield put(actionCreator(ProjectTypes.GET_RECRUITERS_ERROR, { e }));
  }
}

export function* deleteProject({ payload: { projectId, callBack, status } }) {
  try {
    const projects = yield select(ProjectSelectors.selectProjects);
    const isForce = status === 'FORCE_DELETE';

    if (isForce) {
      yield call(withToken, {
        url: API.PROJECTS.CLOSE_PROJECT(projectId),
        method: METHOD.PUT,
      });
    } else {
      yield call(withToken, {
        url: API.PROJECTS.PROJECT(projectId),
        method: METHOD.DELETE,
      });
    }

    // this "if" handles case deleting a project from projects list page. Both, force and regular.
    // Otherwise should redirect to list page from details page
    if (status === 'DELETE' || isForce) {
      yield put(actionCreator(ProjectTypes.DELETE_PROJECT_SUCCESS, {
        projects: projects.filter(item => item.id !== projectId)
      }));
    } else {
      yield put(successNotify({ id: 'NOTIFICATIONS.SUCCESS.DELETED_PROJECT' }));
      yield put(actionCreator(ProjectTypes.DELETE_PROJECT_SUCCESS, { projects }));
      yield put(push(routs.PROJECTS('active')));
    }
    if (callBack) {
      callBack();
    }
  } catch (e) {
    yield put(actionCreator(ProjectTypes.DELETE_PROJECT_ERROR, { e }));
  }
}


export function* uploadFile({ payload: { FormData, callBack } }) {
  try {
    const { data } = yield call(withToken, {
      data: FormData,
      url: API.UPLOAD_FILE,
      method: METHOD.POST,
    });

    yield put(actionCreator(ProjectTypes.UPLOAD_FILE_SUCCESS, data));
    if (callBack) {
      callBack(data);
    }
  } catch (e) {
    yield put(actionCreator(ProjectTypes.UPLOAD_FILE_ERROR));
  }
}

export function* getCandidate({ payload: { candidateId } }) {
  try {
    const { data } = yield call(withToken, {
      url: API.PROJECTS.CANDIDATE(candidateId),
      method: METHOD.GET,
    });

    yield put(actionCreator(ProjectTypes.GET_CANDIDATE_SUCCESS, { data }));
  } catch (e) {
    yield put(actionCreator(ProjectTypes.GET_CANDIDATE_ERROR, { e }));
  }
}

export function* updateCandidate({
  payload: {
    id,
    project_id,
    ...candidate
  },
}) {
  try {
    const { data, errors } = yield call(withToken, {
      url: API.PROJECTS.CANDIDATE(id),
      method: METHOD.PUT,
      data: candidate,
    });

    if (data) {
      yield put(successNotify({ id: 'NOTIFICATIONS.SUCCESS.UPDATED_CANDIDATE' }));
      yield put(ProjectActions.updateCandidate.success({ data }));
    }
    if (errors) {
      throw new SubmissionError(errors)
    }
  } catch (e) {
    yield put(ProjectActions.updateCandidate.failure(e));
  }
}

export function* deleteCandidate({
  payload: {
    candidateId,
    projectId,
    mode,
  }
}) {
  try {
    const candidates = yield select(ProjectSelectors.selectCandidates);
    const { data } = yield call(withToken, {
      url: API.PROJECTS.CANDIDATE(candidateId),
      method: METHOD.DELETE,
    });

    yield put(actionCreator(ProjectTypes.DELETE_CANDIDATE_SUCCESS, {
      data: candidates.filter(({ id }) => id !== candidateId),
      src: data,
    }));
    yield put(push(routs.PROJECT({ projectId, mode }) + '/candidates'));
  } catch (e) {
    yield put(actionCreator(ProjectTypes.DELETE_CANDIDATE_ERROR, { e }));
  }
}

export function* getCandidateFields({ payload: { candidateId } }) {
  try {
    const { data } = yield call(withToken, {
      url: API.PROJECTS.CANDIDATE_FIELDS.FULL_LIST(candidateId),
      method: METHOD.GET,
    });

    yield put(actionCreator(ProjectTypes.GET_CANDIDATE_FIELDS_SUCCESS, { data }));
  } catch (e) {
    yield put(actionCreator(ProjectTypes.GET_CANDIDATE_FIELDS_ERROR, { e }));
  }
}

export function* getInbox() {
  try {
    const { data } = yield call(withToken, {
      url: API.PROJECTS.CORE,
      method: METHOD.GET,
      params: {
        new: true,
      },
    });

    yield put(actionCreator(ProjectTypes.GET_INBOX_SUCCESS, { data }));
  } catch (e) {
    yield put(actionCreator(ProjectTypes.GET_INBOX_ERROR, { e }));
  }
}

export function* doRequestProject({
  payload: {
    comment,
    id,
    reward,
    paymentTerm,
    callBack,
    isEdit
  }
}) {
  try {
    const inbox = yield select(ProjectSelectors.selectInbox);
    const response = yield call(withToken, {
      url: API.PROJECTS.RECRUITERS(id),
      method: isEdit ? METHOD.PUT : METHOD.POST,
      data: {
        comment,
        payment_term: paymentTerm,
        reward,
      },
    });
    if(!response.error) {
      const list = inbox.map(project => project.id === id ? { ...project, requested: true } : project);

      yield put(actionCreator(ProjectTypes.DO_REQUEST_PROJECT_SUCCESS, { data: list }));
      yield put(successNotify({ id: 'NOTIFICATIONS.SUCCESS.SENT_REQUEST_PROJECT' }));
    }
    console.log(response)

    callBack();
  } catch (e) {
    yield put(actionCreator(ProjectTypes.DO_REQUEST_PROJECT_ERROR, { e }));
  }
}

export function* sendRecruiterComment({
  payload: {

  }
}) {
  try {

  } catch (e) {

  }
}

export function* getRecruiterProposal({ payload }) {
  try {
    const {data} = yield call(withToken, {
      method: METHOD.GET,
      url: API.PROJECTS.GET_RECRUITER_PROPOSAL(payload),
    })
    yield put(actionCreator(ProjectTypes.GET_RECRUITER_PROPOSAL_SUCCESS, {data}));
  } catch (e) {
    // yield put(actionCreator(ProjectTypes.GET_RECRUITER_PROPOSAL_ERROR));
  }
}


export function* getRecruiterComments({
  payload
}) {
  try {
    const {data} = yield call(withToken, {
      method: METHOD.GET,
      url: API.PROJECTS.SEND_COMMENT(payload.id, payload.recruiterId),
    })
    yield put(actionCreator(ProjectTypes.GET_RECRUITER_COMMENTS_SUCCESS, {data}));
  } catch (e) {
    yield put(actionCreator(ProjectTypes.GET_RECRUITER_COMMENTS_ERROR));
  }
}

export function* getCandidateStatuses() {
  try {
    const { data: { status_candidate } } = yield call(withToken, {
      url: API.PROJECTS.CANDIDATES_STATUSES,
      method: METHOD.GET,
    });

    yield put(actionCreator(ProjectTypes.GET_CANDIDATE_STATUSES_SUCCESS, { data: status_candidate }));
  } catch (e) {
    yield put(actionCreator(ProjectTypes.GET_CANDIDATE_STATUSES_ERROR, { e }));
  }
}

export function* addCandidateField({
  payload: {
    routProps: {
      candidateId,
    },
    id,
    name,
    callBack,
    fieldId,
  },
}) {
  try {
    const fields = yield select(ProjectSelectors.selectCandidateFields);
    const { data, errors } = yield call(withToken, {
      url: API.PROJECTS.CANDIDATE_FIELDS.FIELD(),
      method: METHOD.POST,
      data: {
        text: name,
        candidate_id: candidateId,
        question_id: id,
      }
    });

    if (data) {
      yield put(actionCreator(ProjectTypes.ADD_CANDIDATE_FIELD_SUCCESS, {
        data: fields.map(field => {
          if (field.id === fieldId) {
            return {
              ...field,
              questionnaire: data,
            };
          }

          return field;
        })
      }));
      callBack();
    } else {
      yield put(actionCreator(ProjectTypes.ADD_CANDIDATE_FIELD_ERROR, { errors }));
    }
  } catch (e) {
    yield put(actionCreator(ProjectTypes.ADD_CANDIDATE_FIELD_ERROR, { e }));
  }
}

export function* updateCandidateField({
  payload: {
    name,
    questionnaire: {
      id,
    },
    fieldId,
    callBack,
  },
}) {
  try {
    const fields = yield select(ProjectSelectors.selectCandidateFields);
    const { data, errors } = yield call(withToken, {
      url: API.PROJECTS.CANDIDATE_FIELDS.FIELD(id),
      method: METHOD.PUT,
      data: {
        text: name,
      },
    });

    if (data) {
      yield put(actionCreator(ProjectTypes.UPDATE_CANDIDATE_FIELD_SUCCESS, {
        data: fields.map(field => {
          if (field.id === fieldId) {
            return {
              ...field,
              questionnaire: data,
            };
          }

          return field;
        })
      }));
      callBack();
    } else {
      yield put(actionCreator(ProjectTypes.UPDATE_CANDIDATE_FIELD_ERROR, { errors }));
    }
  } catch (e) {
    yield put(actionCreator(ProjectTypes.UPDATE_CANDIDATE_FIELD_ERROR, { e }));
  }
}

export function* removeCandidateField({ payload: { questionnaire, id } }) {
  try {
    const fields = yield select(ProjectSelectors.selectCandidateFields);
    const { data, errors } = yield call(withToken, {
      url: API.PROJECTS.CANDIDATE_FIELDS.FIELD(questionnaire.id),
      method: METHOD.DELETE,
    });

    if (data) {
      yield put(actionCreator(ProjectTypes.REMOVE_CANDIDATE_FIELD_SUCCESS, {
        data: fields.map(field => {
          if (field.id === id) {
            return {
              ...field,
              questionnaire: null,
            };
          }

          return field;
        }),
      }));
    } else {
      yield put(actionCreator(ProjectTypes.REMOVE_CANDIDATE_FIELD_ERROR, { errors }));
    }
  } catch (e) {
    yield put(actionCreator(ProjectTypes.REMOVE_CANDIDATE_FIELD_ERROR, { e }));
  }
}

export function* acceptRecruiter({ payload: { projectId, recruiterId } }) {
  try {
    const { data } = yield call(withToken, {
      url: API.PROJECTS.CONFIRM.RECRUITER({ projectId, recruiterId }),
      method: METHOD.PUT,
      data: {
        status: 2,
      },
    });
    yield put(successNotify({ id: 'NOTIFICATIONS.SUCCESS.CHOSEN_PROJECT_REQRUITER' }));

    yield put(actionCreator(ProjectTypes.ACCEPT_RECRUITER_SUCCESS, { data }));
    yield put(actionCreator(ProjectTypes.GET_RECRUITERS_REQUEST, { projectId }));
  } catch (e) {
    yield put(actionCreator(ProjectTypes.ACCEPT_RECRUITER_ERROR, { e }));
  }
}



export function* askProjectPause({ payload: { project: { id }, role } }) {
  try {
    const projects = yield select(ProjectSelectors.selectProjects);
    const { errors } = yield call(withToken, {
      url: API.PROJECTS.PAUSE_REQUEST(id),
      method: role === 'admin' ? METHOD.PUT : METHOD.POST,
    });

    if (!errors) {
      yield put(ProjectActions.askProjectPause.success({
        data: projects.map((project) => {
          if (project.id === id) {
            return {
              ...project,
              pause: role === 'admin' ? 0 : 1,
              status: role === 'admin' ? 4 : project.status,
            };
          }

          return project;
        })
      }));
    } else {
      throw new SubmissionError(errors)
    }
  } catch (e) {
    yield put(ProjectActions.askProjectPause.failure(e));
  }
}


export function* getCandidateActivities({ payload: { stateId, type } }) {
  try {
    const { data } = yield call(withToken, {
      url: API.PROJECTS.CANDIDATE_FEED,
      method: METHOD.GET,
      params: {
        state_id: stateId,
        page: 0,
        type,
      },
    });

    yield put(actionCreator(ProjectTypes.GET_CANDIDATE_ACTIVITIES_SUCCESS, { data }));
  } catch (e) {
    yield put(actionCreator(ProjectTypes.GET_CANDIDATE_ACTIVITIES_ERROR, { e }));
  }
}

export function* getFullComment({ payload: { row, type, projectId } }) {
  try {
    if (type === 'RECRUITER') {
      const { data } = yield call(withToken, {
        url: API.PROJECTS.COMMENTS.RECRUITER(projectId, row.id),
        method: METHOD.GET,
      });

      yield put(actionCreator(ProjectTypes.GET_FULL_COMMENT_SUCCESS, {
        data: {
          msg: data.comment,
          row,
        }
      }));
    }
  } catch (e) {
    yield put(actionCreator(ProjectTypes.GET_FULL_COMMENT_SUCCESS, { e }));
  }
}


export function* showErrorNotify({ payload }) {
  try {
    const error_401 = 'Для этой функции нужна авторизация администратора.';
    const error_422 = 'Не тот статус';

    if (payload.errors && payload.errors.message.match(401)) {
      yield put(errorNotify(error_401))
    } else if ((payload.errors && payload.errors.message.match(422))) {
      yield put(errorNotify(error_422))
    } else {
      yield put(errorNotify(payload.errors.message))
    }
  } catch (e) {
    console.log(payload, 'payload');
  }
}

export function* changeRecruiter({ payload: { recruiter, project: { id }, role } }) {
  try {
    const projects = yield select(ProjectSelectors.selectProjects);
    const isAdmin = role === 'admin';
    const { errors } = yield call(withToken, {
      url: isAdmin
        ? API.PROJECTS.CHOSE_RECRUITER(id, recruiter.id)
        : API.PROJECTS.CHANGE_RECRUITER(id),
      method: isAdmin ? METHOD.PUT : METHOD.POST,
    });

    if (!errors) {
      yield put(ProjectActions.changeRecruiter.success({
        data: projects.map((project) => {
          if (project.id === id) {
            return {
              ...project,
              change: isAdmin ? 0 : 1,
              recruiter: isAdmin ? recruiter : project.recruiter
            };
          }

          return project;
        })
      }));
    } else {
      throw new SubmissionError(errors)
    }
  } catch (e) {
    yield put(ProjectActions.changeRecruiter.failure(e));
  }
}

export function* uploadAttachments({ payload: {FormData, callBack} }) {
  try {
    const response = yield call(withToken, {
      url: API.UPLOAD_ATTACHMENT,
      method: METHOD.POST,
      data: FormData,
    })
    yield put(actionCreator(ProjectTypes.UPLOAD_ATTACHMENTS_SUCCESS));
    callBack(response);
  } catch (e) {
    yield put(actionCreator(ProjectTypes.UPLOAD_ATTACHMENTS_ERROR));
  }
}