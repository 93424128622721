import Notifications from 'react-notification-system-redux';

/**
 * Action generator. Always returns all params as properties od payload
 * @param {String} type
 * @param {Object} params
 */

export default (type: string, params = {}) => ({ type, payload: { ...params } });
/**
 * Call an error notification
 * @param {String} message
 * @param {String} position
 */
export const errorNotify = (message = "...", position = "br") => {
  return Notifications.error({
    message,
    position,
    autoDismiss: 6
  });
};

/**
 * Call a success notification
 * @param {String} message
 * @param {String} position
 */
export const successNotify = (message = "...", position = "br") => {
  return Notifications.success({
    message,
    position,
    autoDismiss: 6
  });
};
