import React from 'react';
import { components } from 'react-select';
import cn from 'classnames';

export const Option = props => (
  <components.Option
    {...props}
    className={cn({ 'has-icon': props.data.icon })}
  >
    {
      props.data.icon && (
        <span className="Dropdown__icon"><i className={props.data.icon} /></span>
      )
    }
    <span className="Dropdown__helper">{props.children}</span>
  </components.Option>
);


export const Menu = props => (
  <components.Menu {...props}>
    {props.children}
  </components.Menu>
);


export const Null = () => null;

export const Control = (props) => {
  const data = props.getValue()[0];

  return (
    <components.Control {...props}>
      <div style={{ background: data ? data.color : '' }} className="Dropdown__control-help">
        {props.children}
      </div>
    </components.Control>
  )
};
export const DropdownIndicator = ({ selectProps }: Object) => (
  <span className={cn('icon-caret', { 'is-rotated': selectProps.menuIsOpen })} />
);

export const Group = (props) => (
  <components.Group {...props}>
    <div className="Dropdown__heading">
      <span
        className="Dropdown__group-bar"
        style={{ background: props.data.color }}
      />
      <h6 style={{ color: props.data.color }}>
        {props.data.label}
      </h6>
    </div>
    <div className="Dropdown__heading-list">
      {props.children}
    </div>
  </components.Group>
);
