class routes {
  static HOME = '/';

  static AUTH = '/auth';

  static LOGIN = `${routes.AUTH}/login`;

  // client or recruiter
  static SIGN_UP = `${routes.AUTH}/sign-up`;

  static CONFIRM_SIGN_UP = (key = ':key', role = ':role') => `${routes.SIGN_UP}/${role}/confirmation/${key}`;

  static FORGOT_PASSWORD = `${routes.AUTH}/restore-pass`;

  static CONFIRM_RESTORING = (key = ':key', email = ':email') => `${routes.FORGOT_PASSWORD}/confirm/${key}/${email}`;

  static PROJECTS__ROOT = '/projects';

  static PROJECTS = (filterType = ':filterType') =>  `/projects/list/${filterType}`;

  static COMPANY = '/company';

  static COMPANY_PROFILE = `${routes.COMPANY}/profile`;
  static COMPANY_MEMBERS = `${routes.COMPANY}/members`;

  static PROFILE = '/profile';

  static PROFILE_SETTINGS = `${routes.PROFILE}/settings`;

  static PROFILE_ACCOUNT = `${routes.PROFILE}/account`;

  static PROJECT = ({ projectId = ':projectId', mode = ':mode' } = {}) => `${routes.PROJECTS__ROOT}/${projectId}/${mode}`;

  static PROJECT_CLIENT = ({ userId = ':userId' } = {}) => `/client/${userId}`;


  static PROJECT_RECRUITER = ({
    projectId = ':projectId',
    userId = ':userId'
  } = {}) => `${routes.PROJECT({ mode: 'edit', projectId })}/recruiters/${userId}`;

  static ADD_CANDIDATE = '/add-candidate';

  static ADD_TO_STOP_LIST = '/add-stop-list';

  static STOP_LIST_CANDIDATE = ({ candidateId = ':candidateId', mode = ':candidateMode' } = {}) => `/stop-list/${candidateId}/${mode}`;

  static CANDIDATE = ({ candidateId = ':candidateId', mode = ':candidateMode' } = {}) => `/${candidateId}/${mode}`;

  static ADD_PROJECT = `${routes.PROJECTS__ROOT}/add-project`;

  static CUSTOMERS = '/customers';

  static CUSTOMER_PAGE = ({ userId = ':userId', mode = ':mode' } = {}) => `${routes.CUSTOMERS}/${userId}/${mode}`;

  static RECRUITER_PAGE = ({
    userId = ':userId',
    mode = ':mode',
  } = {}) => `${routes.RECRUITERS}/${userId}/${mode}`;

  static RECRUITERS = '/recruiters';

  static INBOX = '/inbox';
}

export default routes;
