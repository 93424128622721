import React from 'react';
import ReactDOM from 'react-dom';
import './assets/sass/index.scss';
import * as serviceWorker from './serviceWorker';
import { configureStore, history, runRootSaga } from './app/store';
import App from './app/App';
import './assets/beagle-v1.7.1/dist/html/assets/css/app.min.css';
import './assets/sass/index.scss';

const store = configureStore();
runRootSaga();

ReactDOM.render(
  <App store={store} history={history} />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
