import { UsersTypes, UsersActions } from './index';

export const initialState = {
  flags: {
    users: false,
    statuses: false,
    userGet: false,
    userUpd: false,
    activatingId: null,
    uploadAvatar: false,
  },
  users: [],
  statuses: null,
  user: {},
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    // UPLOAD_USER_LOGO
    case UsersTypes.UPLOAD_USER_LOGO_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          uploadAvatar: true,
        },
      };
    case UsersTypes.UPLOAD_USER_LOGO_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          uploadAvatar: true,
        },
      };
    case UsersTypes.UPLOAD_USER_LOGO_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          uploadAvatar: true,
        },
      };
    //  UPLOAD_COMPANY_LOGO
    case UsersTypes.UPLOAD_COMPANY_LOGO_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          uploadAvatar: true,
        },
      };
    case UsersTypes.UPLOAD_COMPANY_LOGO_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          uploadAvatar: false,
        },
      };
    case UsersTypes.UPLOAD_COMPANY_LOGO_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          uploadAvatar: false,
        },
      };


    case UsersActions.updateUser.REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          userUpd: true,
        },
      };
    case UsersActions.updateUser.SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          userUpd: false,
        },
        user: payload.data,
      };
    case UsersActions.updateUser.FAILURE:
      return {
        ...state,
        flags: {
          ...state.flags,
          userUpd: false,
        },
      };
    // GET_USER
    case UsersTypes.GET_USER_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          userGet: true,
        },
        user: initialState.user,
      };
    case UsersTypes.GET_USER_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          userGet: false,
        },
        user: payload.data,
      };
    case UsersTypes.GET_USER_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          userGet: false,
        },
      };

    // ACTIVATE_USER
    case UsersTypes.ACTIVATE_USER_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          activatingId: payload.id,
        }
      };
    case UsersTypes.ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          activatingId: null,
        },
        users: payload.data || [],
      };
    case UsersTypes.ACTIVATE_USER_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          activatingId: null,
        }
      };

    // GET_STATUSES
    case UsersTypes.GET_STATUSES_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          statuses: true,
        }
      };
    case UsersTypes.GET_STATUSES_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          statuses: false,
        },
        statuses: payload.data,
      };
    case UsersTypes.GET_STATUSES_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          statuses: false,
        }
      };

    // GET_USERS
    case UsersTypes.GET_USERS_REQUEST:
      return {
        ...state,
        users: [],
        flags: {
          ...state.flags,
          users: true,
        }
      };
    case UsersTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        users: payload.data || [],
        flags: {
          ...state.flags,
          users: false,
        }
      };
    case UsersTypes.GET_USERS_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          users: false,
        }
      };
    default:
      return state;
  }
}
