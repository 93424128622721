import React from 'react';
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import routs from '../../modules/router/routes';
import Customer from './Customer';
import withBreadCrumb from './../../HOC/withBreadCrumb';
import List from './List';

const Customers = () => (
  <Switch>
    <Route path={routs.CUSTOMERS} component={List} exact />
    <Route path={routs.CUSTOMER_PAGE()} component={Customer} />
    <Redirect to={routs.CUSTOMERS} />
  </Switch>
);

export default withBreadCrumb(Customers, routs.CUSTOMERS, {id: 'NAV.CUSTOMERS'});
