import React from 'react';
import './styles.sass';

type Props = {
  children: any,
}

const Page = ({ children }: Props) => (
  <div className="Page">
    {children}
  </div>
);

export default Page;
