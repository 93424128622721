import React, {useCallback, useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import routs from '../../modules/router/routes';
import { useIntl } from 'react-intl';
import { Button, TextField, Dialog } from '../../components/ui-kit';
import { reducePasswordError } from './index'
import { useForm, Controller } from 'react-hook-form';
import { Yup, email, password } from '../../utils/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuthContext } from '../../hooks';
import { injectMetaIntoError } from './ConfirmRestoring';
import cn from 'classnames';

const schema = Yup.object().shape({
  email: email(),
  password: password(),
  phone: Yup.string().required('REQUIRED'),
});

const TABS = [{
  type: 'client',
  icon: 'icon-client-signup',
}, {
  type: 'recruiter',
  icon: 'icon-recruiter-signup',
}];

export const SignUp = () => {
  const history = useHistory();
  const [isRegFormSent, setIsRegFormSent] = useState(false);
  const { formatMessage } = useIntl();
  const auth = useAuthContext();
  const reducePlaceholder = reducePasswordError(formatMessage)

  const form = useForm({
    defaultValues: {
      email: '',
      password: '',
      phone: '',
      user: TABS[0].type,
    },
    resolver: yupResolver(schema),
  });

  const handleSubmit = useCallback(async (values) => {
    const payload = {
      ...values,
      phone: values.phone.replace(/[^\d]/g, '')
    }

    const errors = await auth.signUp(payload)

    if (errors) {
      Object.entries(errors).forEach(([key, error]) => {
        form.setError(key, {
          type: "manual",
          message: error,
        });
      })
    } else {
      form.clearErrors();
      setIsRegFormSent(true);
    }
  }, []);

  const handleRedirect = useCallback(() => {
    setIsRegFormSent(false);
    history.push(routs.LOGIN);
  }, [])

  return (
    <div className="Auth__form is-register">
      <Dialog
        isOpen={isRegFormSent}
        onToggle={handleRedirect}
        actions={[{
          onClick: handleRedirect,
          label: formatMessage({ id: 'COMMON.CLOSE' }),
          color: 'primary',
          key: 'CLOSE',
        }]}
      >
        <div className="Auth__dialog">
          <div className="Auth__title">
            <h1>{formatMessage({ id: 'AUTH.SIGN_UP.THANKS_FOR_REGISTER' })}</h1>
            <p>{formatMessage({ id: 'AUTH.SIGN_UP.CHECK_EMAIL_TO_COMPLETE_REGISTRATION' })}</p>
          </div>
        </div>
      </Dialog>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <Controller
          name="user"
          control={form.control}
          render={({ value, onChange }) => (
            <div className="Auth__tabs">
              {TABS.map(({ type, icon }) => (
                <div
                  className={cn('Auth__tab', { 'is-active': type === value })}
                  key={type}
                  onClick={() => onChange(type)}
                >
                  <span className={icon} />
                  <div className="Auth__tab-helper">
                    <span className="Auth__tab-label">{formatMessage({ id: 'AUTH.REGISTER_AS_A' })}</span>
                    <span className="Auth__tab-type">
                      {formatMessage({ id: `AUTH.${type.toUpperCase()}` })}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        />
        <div className="Auth__form-group">
          <Controller
            control={form.control}
            name="email"
            render={input => (
              <TextField
                type="text"
                maxRows={1}
                input={input}
                meta={{
                  error: form.errors.email ? form.errors.email.message : ''
                }}
                required
                label={formatMessage({ id: 'FORM.E_MAIL' })}
                autoComplete="off"
              />
            )}
          />
        </div>
        <div className="Auth__form-group">
          <Controller
            control={form.control}
            name="password"
            render={input => (
              <TextField
                input={input}
                type="password"
                maxRows={1}
                required
                meta={{
                  formatted: true,
                  error: form.errors.password
                    ? injectMetaIntoError(form.errors.password.message, formatMessage)
                    : ''
                }}
                label={formatMessage({ id: 'FORM.PASSWORD' })}
                placeholder={reducePlaceholder}
                hint={reducePlaceholder}
              />
            )}
          />
        </div>
        <div className="Auth__form-group">
          <Controller
            control={form.control}
            name="phone"
            render={input => (
              <TextField
                input={input}
                type="tel"
                maxRows={1}
                meta={{
                  error: form.errors.phone ? form.errors.phone.message : ''
                }}
                required
                label={formatMessage({ id: 'FORM.PHONE_NUMBER' })}
                autoComplete="off"
              />
            )}
          />
        </div>
        <br/>
        <div className="Auth__form-footer">
          <Button
            type="submit"
            label={formatMessage({ id: 'COMMON.SIGN_UP' })}
            loading={form.formState.isSubmitting}
            color="primary"
          />
        </div>
        <div className="Auth__center">
          <p>{formatMessage({ id: 'AUTH.SIGN_UP.ALREADY_HAVE_AN_ACCOUNT' })}</p>
          &nbsp;&nbsp;
          <Button
            to={routs.LOGIN}
            tag={Link}
            label={formatMessage({ id: 'COMMON.LOGIN' })}
            size="sm"
            color="link"
          />
        </div>
      </form>
    </div>
  );
};
