import React, {useEffect, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from 'react-redux'
import {Button, Dialog, TextField} from "../../../components/ui-kit";
import {calcReward} from "../../../modules/projects/utils";
import { ProjectSelectors, ProjectActions } from './../../../modules/projects';
import { ProfileSelectors } from '../../../modules/profile';
import cx from 'classnames';

export const RecruiterChannel = ({
  isOpen,
  onToggle,
  data = {},
  projectId,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [comment, setComment] = useState('');
  const [reward, setReward] = useState('');
  const [paymentTerm, setPaymentTerm] = useState('');

  const flags = useSelector(ProjectSelectors.selectFlags);
  const profile = useSelector(ProfileSelectors.selectProfile);
  const proposal = useSelector(ProjectSelectors.selectRecruiterProposal);

  const isAdmin = profile && profile.role === 'admin';
  const isRecruiter = profile && profile.role === 'recruiter';
  const isClient = profile && profile.role === 'client';
  const handleSubmit = () => {
    const action = ProjectActions.doRequestProject({
      comment,
      reward,
      paymentTerm,
      id: data.id,
      callBack: onToggle,
      isEdit: data.requested,
    });
    dispatch(action);
  };

  const calcRewardValue = useMemo(() => {
    if (data && data.salary) {
      return calcReward(data.salary, reward ? reward : data.reward);
    }

    return '';
  }, [data, reward])


  useEffect(() => {
    if (isRecruiter) {
      dispatch(ProjectActions.getRecruiterProposal({ projectId: data.id }))
    } else {
      dispatch(ProjectActions.getRecruiterProposal({
        projectId,
        recruiterId: data.id
      }))
    }
  }, [data, projectId, isRecruiter, profile])

  useEffect(() => {
    if (isRecruiter && data) {
      setComment('');
      setReward(data.reward);
      setPaymentTerm(data.payment_term);
    }

    if ((isRecruiter || isAdmin) && proposal) {
      setComment(proposal.comment);
      setReward(proposal.reward);
      setPaymentTerm(proposal.payment_term);
    }

    if (isClient && proposal) {
      setComment(proposal.comment);
      setReward(proposal.reward);
      setPaymentTerm(proposal.payment_term);
    }
  }, [proposal, isOpen, data, isRecruiter, isClient]);

  return (
    <Dialog
      isOpen={isOpen}
      onToggle={onToggle}
      className="Recruiter-dialog"
      footer={isRecruiter && (
        <div className="Recruiter-dialog__footer">
          <Button
            onClick={onToggle}
            label={formatMessage({ id: 'COMMON.NO' })}
            color="link"
          />
          <Button
            prefix="icon-ok2"
            onClick={handleSubmit}
            label={formatMessage({ id: 'COMMON.SEND' })}
            loading={Boolean(flags.requestingId)}
          />
        </div>
      )}
    >
      <div className="row">
        <div
          className={cx('form-group', 'col-xs-12', 'col-md-6', {
            'field-has-changed': (+reward !== +data.reward && reward) || (proposal && proposal.old && +proposal.reward !== +proposal.old.reward),
          })}
        >
          <TextField
            disabled={!isRecruiter}
            label={formatMessage({ id: 'FORM.PROJECT.REWARD' })}
            placeholder={formatMessage({ id: 'FORM.PROJECT.REWARD_PLACEHOLDER_1' })}
            unit={formatMessage({ id: 'COMMON.UNITS.UAH' })}
            input={{
              value: reward,
              onChange: setReward,
            }}
          />
        </div>
        <div className="form-group col-xs-12 col-md-6">
          <TextField
            type="text"
            name="reward2"
            id="reward2"
            input={{
              value: calcRewardValue || '',
              onChange: () => false
            }}
            unit={<span style={{ fontSize: 16 }}>%</span>}
            placeholder={formatMessage({ id: 'FORM.PROJECT.REWARD_PLACEHOLDER_2' })}
            label={' '}
            disabled={true}
          />
        </div>
      </div>
      <div className="row">
        <div
          className={cx('form-group', 'col-xs-12', 'col-md-6', {
            'field-has-changed': (paymentTerm !== data.payment_term && paymentTerm) || (proposal && proposal.old && proposal.payment_term !== proposal.old.payment_term)
          })}
        >
          <TextField
            disabled={!isRecruiter}
            label={formatMessage({ id: 'FORM.PROJECT.PAYMENT_TERM' })}
            placeholder={formatMessage({ id: 'FORM.PROJECT.PAYMENT_TERMS_PLACEHOLDER' })}
            input={{
              value: paymentTerm,
              onChange: setPaymentTerm,
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-xs-12 col-md-12">
          <TextField
            disabled={!isRecruiter}
            label={formatMessage({ id: 'FORM.COMMENT' })}
            input={{
              value: comment,
              onChange: setComment,
            }}
            minRows={5}
            maxRows={8}
          />
        </div>
      </div>
    </Dialog>
  )
}