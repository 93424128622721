import React, {useCallback, useState} from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';
import { useAuthContext } from '../../hooks';

import { TextField, Button } from '../../components/ui-kit'
import routs from '../../modules/router/routes';
import {email, password, Yup} from "../../utils/yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {injectMetaIntoError} from "./ConfirmRestoring";

const schema = Yup.object().shape({
  email: Yup.string().required('ERRORS.REQUIRED'),
  password: Yup.string().required('ERRORS.REQUIRED'),
});

export const Login = () => {
  const auth = useAuthContext();
  const [error, setError] = useState('');
  const { formatMessage } = useIntl();
  const form = useForm({
    defaultValues: {
      password: '',
      email: '',
    },
    // mode: 'onChange',
    // reValidateMode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const handleSubmit = useCallback(async values => {
    const errors = await auth.signIn(values);

    if (errors) {
      setError(errors._error)
    }
  }, []);

  return (
    <div className="Auth__form">
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <div className="Auth__title">
          <h1>{formatMessage({ id: 'AUTH.WELCOME_TITLE' })}</h1>
          <p>{formatMessage({ id: 'AUTH.WELCOME_SUBTITLE' })}</p>
        </div>
        <div className="Auth__form-group">
          <Controller
            control={form.control}
            name="email"
            render={(input) => (
              <TextField
                input={input}
                type="email"
                maxRows={1}
                meta={{
                  formatted: true,
                  error: form.errors.email
                    ? injectMetaIntoError(form.errors.email.message, formatMessage)
                    : ''
                }}
                label={formatMessage({ id: 'FORM.E_MAIL' })}
                autoComplete="off"
              />
            )}
            defaultValue=""
          />
        </div>
        <div className="Auth__form-group">
          <Controller
            control={form.control}
            name="password"
            render={(input) => (
              <TextField
                input={input}
                type="password"
                meta={{
                  formatted: true,
                  error: form.errors.password
                    ? injectMetaIntoError(form.errors.password.message, formatMessage)
                    : ''
                }}
                maxRows={1}
                label={formatMessage({ id: 'FORM.PASSWORD' })}
              />
            )}
            defaultValue=""
          />
        </div>
        {
          !!error && (
            <div className="Auth__error">
              {formatMessage({ id: error })}
            </div>
          )
        }
        <div className="Auth__tools">
          <Button
            to={routs.FORGOT_PASSWORD}
            tag={Link}
            label={formatMessage({ id: 'COMMON.FORGOT_PASSWORD' })}
            size="sm"
            color="link"
          />
        </div>
        <div className="Auth__form-footer">
          <Button
            type="submit"
            label={formatMessage({ id: 'COMMON.LOGIN' })}
            loading={form.formState.isSubmitting}
            color="primary"
            rounded
          />
        </div>
        <div className="Auth__center">
          <p>
            {formatMessage({ id: 'AUTH.STILL_NOT_REGISTERED' })}&nbsp;
          </p>
          <Button
            to={routs.SIGN_UP}
            tag={Link}
            label={formatMessage({ id: 'COMMON.SIGN_UP' })}
            size="sm"
            color="link"
          />
        </div>
      </form>
    </div>
  );
};
