import ProjectsTypes from './constants';
import * as ProjectActions from './actions';

export const initialState = {
  flags: {
    postReview: false,
    projects: false,
    projectGet: false,
    projectUpd: false,
    fields: false,
    fieldRmId: null,
    fieldUpdId: null,
    fieldAddId: null,
    activation: false,
    stopList: false,
    candidates: false,
    recruiters: false,
    deletingProjectId: null,
    candidateUpd: false,
    deletingCandidate: false,
    uploading: false,
    candidateFields: false,
    inbox: false,
    requestingId: null,
    comments: false,
    candidateFieldId: null,
    acceptingRecruiterId: null,
    changingStatusCandidate: false,
    askPause: false,
    comment: false,
    commentPost: false,
    projectStatuses: false,
    projectComment: false,
    uploadAttachments: false,
    recruiterCommentsGet: false,
    recruiterCommentsSend: false,
  },
  fullComment: {
    msg: '',
    row: null
  },
  projects: [],
  project: null,
  statuses: {
    guaranteed_period: {},
  },
  fields: [],
  candidates: [],
  candidateSort: '',
  stopList: [],
  recruiters: [],
  candidate: null,
  candidateFields: [],
  inbox: [],
  candidateStatuses: [],
  comments: [],
  projectStatuses: [],

  recruiterComments: {},
  recruiterProposal: null,
};


export default function (state = initialState, { type, payload }) {
  switch (type) {
    case ProjectsTypes.POST_REVIEW_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          postReview: true
        },
      };
    case ProjectsTypes.POST_REVIEW_SUCCESS:
    case ProjectsTypes.POST_REVIEW_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          postReview: false
        },
      };

    // GET_RECRUITER_PROPOSAL
    case ProjectsTypes.GET_RECRUITER_PROPOSAL_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          recruiterProposal: true
        },
        recruiterProposal: null,
      };
    case ProjectsTypes.GET_RECRUITER_PROPOSAL_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          recruiterProposal: false
        },
        recruiterProposal: payload.data
      };
    case ProjectsTypes.GET_RECRUITER_PROPOSAL_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          recruiterProposal: false
        },
      };

    // SEND_RECRUITER_COMMENT
    case ProjectsTypes.SEND_RECRUITER_COMMENT_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          recruiterCommentsSend: true
        },
      };
    case ProjectsTypes.SEND_RECRUITER_COMMENT_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          recruiterCommentsSend: false
        },
      };
    case ProjectsTypes.SEND_RECRUITER_COMMENT_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          recruiterCommentsSend: false
        },
      };

    case ProjectsTypes.GET_RECRUITER_COMMENTS_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          recruiterCommentsGet: true
        },
      };
    case ProjectsTypes.GET_RECRUITER_COMMENTS_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          recruiterCommentsGet: false
        },
        recruiterComments: payload.data,
      };
    case ProjectsTypes.GET_RECRUITER_COMMENTS_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          recruiterCommentsGet: false
        },
      };

    case ProjectsTypes.UPLOAD_ATTACHMENTS_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          uploadAttachments: true
        },
      };
    case ProjectsTypes.UPLOAD_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          uploadAttachments: false
        },
      };
    case ProjectsTypes.UPLOAD_ATTACHMENTS_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          uploadAttachments: false
        },
      };


    case ProjectsTypes.POST_PROJECT_COMMENT_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          projectComment: true
        },
      };
    case ProjectsTypes.POST_PROJECT_COMMENT_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          projectComment: false
        },
      };
    case ProjectsTypes.POST_PROJECT_COMMENT_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          projectComment: false
        },
      };

    case ProjectsTypes.GET_PROJECT_STATUSES_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          projectStatuses: true
        },
      };
    case ProjectsTypes.GET_PROJECT_STATUSES_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          projectStatuses: false
        },
        projectStatuses: payload.data,
      };
    case ProjectsTypes.GET_PROJECT_STATUSES_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          projectStatuses: false
        },
      };

    case ProjectsTypes.POST_COMMENT_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          commentPost: true
        },
      };
    case ProjectsTypes.POST_COMMENT_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          commentPost: false
        },
      };
    case ProjectsTypes.POST_COMMENT_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          commentPost: false
        },
      };

    case ProjectsTypes.GET_FULL_COMMENT_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          comment: true
        },
        fullComment: {
          row: payload.row,
          msg: ''
        }
      };

    case ProjectsTypes.GET_FULL_COMMENT_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          comment: false
        },
        fullComment: payload.data
      };
    case ProjectsTypes.GET_FULL_COMMENT_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          comment: false
        },
      };

    case ProjectActions.askProjectPause.REQUEST:
    case ProjectActions.changeRecruiter.REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          askPause: true
        },
      };
    case ProjectActions.askProjectPause.SUCCESS:
    case ProjectActions.changeRecruiter.SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          askPause: false
        },
        projects: payload.data
      };
    case ProjectActions.askProjectPause.FAILURE:
    case ProjectActions.changeRecruiter.FAILURE:
      return {
        ...state,
        flags: {
          ...state.flags,
          askPause: false
        },
      };

    case ProjectActions.changeCandidateStatus.REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          changingStatusCandidate: payload.status.candidateId,
        },
      };
    case ProjectActions.changeCandidateStatus.SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          changingStatusCandidate: null,
        },
      };
    case ProjectActions.changeCandidateStatus.FAILURE:
      return {
        ...state,
        flags: {
          ...state.flags,
          changingStatusCandidate: 0,
        },
      };

    case ProjectsTypes.ACCEPT_RECRUITER_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          acceptingRecruiterId: payload.recruiterId,
        }
      };
    case ProjectsTypes.ACCEPT_RECRUITER_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          acceptingRecruiterId: null,
        },
      };
    case ProjectsTypes.ACCEPT_RECRUITER_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          acceptingRecruiterId: null,
        }
      };


    case ProjectsTypes.REMOVE_CANDIDATE_FIELD_REQUEST:
    case ProjectsTypes.ADD_CANDIDATE_FIELD_REQUEST:
    case ProjectsTypes.UPDATE_CANDIDATE_FIELD_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          candidateFieldId: payload.id,
        }
      };
    case ProjectsTypes.REMOVE_CANDIDATE_FIELD_SUCCESS:
    case ProjectsTypes.ADD_CANDIDATE_FIELD_SUCCESS:
    case ProjectsTypes.UPDATE_CANDIDATE_FIELD_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          candidateFieldId: null,
        },
        candidateFields: payload.data,
      };
    case ProjectsTypes.REMOVE_CANDIDATE_FIELD_ERROR:
    case ProjectsTypes.ADD_CANDIDATE_FIELD_ERROR:
    case ProjectsTypes.UPDATE_CANDIDATE_FIELD_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          candidateFieldId: null,
        }
      };

    //  GET_CANDIDATE_COMMENTS
    case ProjectsTypes.GET_CANDIDATE_ACTIVITIES_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          comments: true,
        }
      };
    case ProjectsTypes.GET_CANDIDATE_ACTIVITIES_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          comments: false,
        },
        comments: payload.data,
      };
    case ProjectsTypes.GET_CANDIDATE_COMMENTS_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          comments: false,
        }
      };

    case ProjectsTypes.GET_CANDIDATE_STATUSES_SUCCESS:
      return {
        ...state,
        candidateStatuses: payload.data,
      };

    // GET_RECRUITERS
    case ProjectsTypes.GET_RECRUITERS_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          recruiters: true,
        },
        recruiters: [],
      };
    case ProjectsTypes.GET_RECRUITERS_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          recruiters: false
        },
        recruiters: payload.data,
      };
    case ProjectsTypes.GET_RECRUITERS_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          recruiters: false
        }
      };
    case ProjectsTypes.DO_REQUEST_PROJECT_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          requestingId: payload.id,
        }
      };
    case ProjectsTypes.DO_REQUEST_PROJECT_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          requestingId: null,
        },
        inbox: payload.data,
      };
    case ProjectsTypes.DO_REQUEST_PROJECT_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          requestingId: null,
        }
      };


    case ProjectsTypes.GET_INBOX_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          inbox: true,
        },
        inbox: [],
      };
    case ProjectsTypes.GET_INBOX_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          inbox: false,
        },
        inbox: payload.data,
      };
    case ProjectsTypes.GET_INBOX_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          inbox: false,
        }
      };

    // GET_CANDIDATE_FIELDS
    case ProjectsTypes.GET_CANDIDATE_FIELDS_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          candidateFields: true,
        }
      };
    case ProjectsTypes.GET_CANDIDATE_FIELDS_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          candidateFields: false,
        },
        candidateFields: payload.data,
      };
    case ProjectsTypes.GET_CANDIDATE_FIELDS_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          candidateFields: false,
        }
      };

    // DELETE_CANDIDATE
    case ProjectsTypes.DELETE_CANDIDATE_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          deletingCandidate: true,
        }
      };
    case ProjectsTypes.DELETE_CANDIDATE_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          deletingCandidate: false,
        },
        candidate: initialState.candidate,
      };
    case ProjectsTypes.DELETE_CANDIDATE_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          deletingCandidate: false,
        }
      };
    // UPLOAD_FILE
    case ProjectsTypes.UPLOAD_FILE_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          uploading: true,
        }
      };
    case ProjectsTypes.UPLOAD_FILE_SUCCESS:
    case ProjectsTypes.UPLOAD_FILE_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          uploading: false,
        }
      };

    // GET_CANDIDATE
    case ProjectsTypes.GET_CANDIDATE_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          candidateGet: true,
        }
      };
    case ProjectsTypes.GET_CANDIDATE_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          candidateGet: false,
        },
        candidate: payload.data,
      };
    case ProjectsTypes.GET_CANDIDATE_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          candidateGet: false,
        }
      };


    case ProjectActions.addCandidate.REQUEST:
    case ProjectActions.updateCandidate.REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          candidateUpd: true,
        }
      };
    case ProjectActions.updateCandidate.SUCCESS:
    case ProjectActions.addCandidate.SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          candidateUpd: false,
        },
        candidate: payload.data,
      };
    case ProjectActions.addCandidate.FAILURE:
    case ProjectActions.updateCandidate.FAILURE:
      return {
        ...state,
        flags: {
          ...state.flags,
          candidateUpd: false,
        }
      };


    //  DELETE_PROJECT
    case ProjectsTypes.DELETE_PROJECT_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          deletingProjectId: payload.projectId,
        }
      };
    case ProjectsTypes.DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          deletingProjectId: null,
        },
        project: null,
        projects: payload.projects,
      };
    case ProjectsTypes.DELETE_PROJECT_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          deletingProjectId: null,
        }
      };

    //  GET_CANDIDATES
    case ProjectsTypes.GET_CANDIDATES_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          candidates: true,
        },
        candidates: [],
      };
    case ProjectsTypes.GET_CANDIDATES_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          candidates: false,
        },
        candidates: payload.data,
        candidateSort: payload.sort,
      };
    case ProjectsTypes.GET_CANDIDATES_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          candidates: false,
        }
      };

    // ACTIVATE_PROJECT
    case ProjectActions.changeProjectStatus.REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          activation: true,
        }
      };
    case ProjectActions.changeProjectStatus.SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          activation: false,
        },
        //projects: payload.data,
      };
    case ProjectActions.changeProjectStatus.FAILURE:
      return {
        ...state,
        flags: {
          ...state.flags,
          activation: false,
        }
      };
    case ProjectsTypes.ACTIVATE_PROJECT_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          activation: true,
        }
      };
    case ProjectsTypes.ACTIVATE_PROJECT_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          activation: false,
        },
        project: payload.data,
      };
    case ProjectsTypes.ACTIVATE_PROJECT_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          activation: false,
        }
      };
    // UPDATE_FIELD
    case ProjectsTypes.UPDATE_FIELD_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          fieldUpdId: payload.id,
        }
      };
    case ProjectsTypes.UPDATE_FIELD_SUCCESS:
      return {
        ...state,
        fields: payload.data,
        flags: {
          ...state.flags,
          fieldUpdId: null,
        }
      };
    case ProjectsTypes.UPDATE_FIELD_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          fieldUpdId: null,
        }
      };


    // DELETE_FIELD
    case ProjectsTypes.REMOVE_FIELD_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          fieldRmId: payload.id,
        }
      };
    case ProjectsTypes.REMOVE_FIELD_SUCCESS:
      return {
        ...state,
        fields: payload.data,
        flags: {
          ...state.flags,
          fieldRmId: null,
        }
      };
    case ProjectsTypes.REMOVE_FIELD_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          fieldRmId: null,
        }
      };


    // ADD_FIELDS
    case ProjectsTypes.ADD_FIELD_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          fieldAddId: true,
        }
      };
    case ProjectsTypes.ADD_FIELD_SUCCESS:
      return {
        ...state,
        fields: payload.data,
        flags: {
          ...state.flags,
          fieldAddId: false,
        }
      };
    case ProjectsTypes.ADD_FIELD_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          fieldAddId: false
        }
      };

    // GET_FIELDS
    case ProjectsTypes.GET_FIELDS_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          fields: true,
        }
      };
    case ProjectsTypes.GET_FIELDS_SUCCESS:
      return {
        ...state,
        fields: payload.data,
        flags: {
          ...state.flags,
          fields: false,
        }
      };
    case ProjectsTypes.GET_FIELDS_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          fields: false,
        }
      };

    // GET_STATUSES
    case ProjectsTypes.GET_STATUSES_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          statuses: true,
        }
      };
    case ProjectsTypes.GET_STATUSES_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          statuses: false,
        },
        statuses: payload.data,
      };
    case ProjectsTypes.GET_STATUSES_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          statuses: false,
        }
      };

    // GET_PROJECTS
    case ProjectsTypes.GET_PROJECTS_REQUEST:
      return {
        ...state,
        projects: [],
        flags: {
          ...state.flags,
          projects: true,
        }
      };

    case ProjectsTypes.GET_PROJECTS_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          projects: false,
        },
        projects: payload.data || [],
      };
    case ProjectsTypes.GET_PROJECTS_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          projects: false,
        }
      };
    // GET_PROJECT
    case ProjectsTypes.GET_PROJECT_REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          projectGet: true,
        }
      };

    case ProjectsTypes.GET_PROJECT_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          projectGet: false,
        },
        project: payload.data,
      };
    case ProjectsTypes.GET_PROJECT_ERROR:
      return {
        ...state,
        flags: {
          ...state.flags,
          projectGet: false,
        }
      };

    // UPDATE_PROJECT
    case ProjectActions.addProject.REQUEST:
    case ProjectActions.updateProject.REQUEST:
      return {
        ...state,
        flags: {
          ...state.flags,
          projectUpd: true,
        }
      };

    case ProjectActions.updateProject.SUCCESS:
    case ProjectActions.addProject.SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          projectUpd: false,
        },
        project: payload.data,
      };
    case ProjectActions.addProject.FAILURE:
    case ProjectActions.updateProject.FAILURE:
      return {
        ...state,
        flags: {
          ...state.flags,
          projectUpd: false,
        }
      };
    default:
      return state;
  }
}
