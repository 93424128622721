import React from 'react';
import cn from 'classnames';
import { Button as CoreBtn } from 'reactstrap';
import './styles.scss';
import Spinner from './../Spinner';

const Button = ({
  label,
  prefix,
  loading,
  disabled,
  suffix,
  block,
  rounded,
  isYellow,
  isGreen,
  isJustify,
  invertedLoader,
  isWhite,
  ...props
}) => {
  return (
    <div
      className={cn('Button', {
        'align-center': !prefix && !suffix,
        'is-block': block,
        'is-rounded': rounded,
        'is-yellow': isYellow,
        'is-green': isGreen,
        'is-white': isWhite,
        'is-justify': isJustify,
        'has-suffix': suffix,
        'has-prefix': prefix,
        'no-label': !label,
      })}
    >
      <CoreBtn
        {...props}
        disabled={Boolean(disabled || loading)}
        block
      >
        {prefix && <span className="Button__prefix"><i className={prefix} /></span>}
        {label && <span className="Button__helper">{label}</span>}
        {suffix && <span className="Button__suffix"><i className={suffix} /></span>}
        {
          loading && (
            <span className="Button__loader">
              <Spinner size="md" color={invertedLoader ? '#fff' : '#009EB9'}/>
            </span>
          )
        }
      </CoreBtn>
    </div>
  );
};

Button.defaultProps = {
  size: 'md',
  loading: false,
  prefix: '',
  block: false,
  suffix: '',
  color: 'primary',
};

export default Button;
