import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as notifications } from 'react-notification-system-redux';
import { reducer as form } from 'redux-form';
import profile from '../modules/profile';
import projects from '../modules/projects';
import users from '../modules/users';
import intl from '../modules/intl'

export default history => combineReducers({
  router: connectRouter(history),
  form,
  profile,
  projects,
  users,
  notifications,
  intl,
});
