import React, { useState, useEffect, useCallback } from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import './styles.sass';
import cx from 'classnames';
import Spinner from '../Spinner'
import { Dropdown } from '../Dropdown';


createTheme('solarized', {});

export const Table = ({
  columns,
  rows,
  appendHeader,
  prependHeader,
  loading,
  makeRef = () => false,
  sort: {
    options = [],
    callBack = () => false,
    enabled = false,
    defaultValue = '',
    forceReset = () => false,
    placeholder
  } = {}
}) => {
  const isHeaderExist = Boolean(appendHeader || prependHeader);
  const [sortValue, changeSortValue] = useState({});

  const handleChangeSort = useCallback((value) => {
    if (value !== sortValue) {
      changeSortValue(forceReset(value.value) ? null : value);
      callBack(value);
    }
  }, [sortValue]);

  useEffect(() => {
    const newSortValue = options.find(({ sort }) => sort === defaultValue);

    handleChangeSort(newSortValue || {});
  }, [defaultValue]);

  console.log(sortValue)

  return (
    <div className={cx('Grid', { 'has-header': isHeaderExist })}>
      {isHeaderExist && (
        <div className="Grid__header">
          <div className="Grid__header-cell">{prependHeader}</div>
          {(appendHeader && !enabled) && (
            <div className="Grid__header-cell">{appendHeader}</div>
          )}
          {enabled && (
            <div className="Grid__header-cell">
              <Dropdown
                isDisabled={loading}
                input={{
                  value: sortValue,
                  onChange: handleChangeSort,
                }}
                placeholder={placeholder}
                isDefaultClass={!!sortValue}
                id="gridSortable"
                options={options}
              />
            </div>
          )}
        </div>
      )}
      <div className="Grid__table" ref={makeRef}>
        <DataTable
          columns={columns}
          data={rows}
          noHeader
          persistTableHead
          progressPending={loading}
          progressComponent={<div className="Grid__loader"><Spinner size={30} /></div>}
        />
      </div>
    </div>
  );
};


Table.defaultProps = {
  overflowYOffset: 0,
};

