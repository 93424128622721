import React, {useMemo} from "react"
import { Context as AuthContext } from './context'
import { useResize, BREAKPOINTS } from './useResize';

export function Provider({ children }) {
  const {width, is} = useResize();

  const resizeContext = useMemo(() => ({ width, is, bp: BREAKPOINTS }), [width, is])

  return (
    <AuthContext.Provider value={resizeContext}>
      {children}
    </AuthContext.Provider>
  )
}
