import React, {useCallback, useEffect} from 'react';
import { useIntl } from 'react-intl';
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useDispatch, useSelector} from 'react-redux';
import { TextField, Button } from './../../../components/ui-kit';
import { ProfileActions, ProfileSelectors } from './../../../modules/profile';
import {email, Yup} from "../../../utils/yup";

const schema = Yup.object().shape({
  email: email(),
});

export const ResetEmail = () => {
  const dispatch = useDispatch();
  const flags = useSelector(ProfileSelectors.selectFlags);
  const { formatMessage } = useIntl()
  const form = useForm({
    defaultValues: {
      email: ''
    },
    resolver: yupResolver(schema),
  });

  const handleSubmit = useCallback(values => {
    dispatch(ProfileActions.resetEmail(values));
  }, []);

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <div className="row">
        <div className="col-xs-6 col-md-6 col-lg-8 form-group">
          <Controller
            name="email"
            control={form.control}
            render={(field) => (
              <TextField
                // type="email"
                input={field}
                meta={{
                  error: form.errors.email ? form.errors.email.message : ''
                }}
                maxRows={1}
                required
                placeholder={formatMessage({ id: 'FORM.E_MAIL' })}
                autoComplete="off"
              />
            )}
          />
        </div>
        <div className="col-xs-6 col-md-6 col-lg-4 form-group">
          <Button
            type="submit"
            label={formatMessage({ id: 'COMMON.SAVE' })}
            loading={flags.resetEmail}
            color="primary"
            rounded
          />
        </div>
      </div>
    </form>
  );
};
