import React from 'react';
import Button from '../Button';
import Files from 'react-files';

type Props = {
  label: string,
  onChange?: () => void,
  loading: boolean,
  accepts?: string[],
  maxSize?: number,
  children: any,
  multiple: boolean,
}

class Upload extends React.PureComponent<Props> {
  handleChange = (files) => {
    const data = new FormData();

    files.forEach((file) => {
      data.append("file", file);
    });

    this.props.onChange({ FormData: data });
  };

  render() {
    const {
      label,
      loading,
      accepts,
      maxSize,
      children,
      //multiple,
    } = this.props;

    return (
      <Files
        ref={(node) => { this.root = node; }}
        className="files-dropzone"
        onChange={this.handleChange}
        onError={(er) => console.log(er)}
        accepts={accepts}
        multiple={false}
        maxFiles={1}
        maxFileSize={maxSize * 1000000}
        clickable
      >
        {
          children || (
            <Button
              label={label}
              loading={loading}
              prefix="icon-add"
              rounded
            />
          )
        }
      </Files>
    );
  }
}

Upload.defaultProps = {
  maxSize: 100,
  onChange: () => {},
  accepts: [],
};

export default Upload;
