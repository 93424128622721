import {
  useState,
  useEffect,
} from 'react';
import axios from 'axios';

export function useApi(method, params, skip, onSuccess) {
  const signal = axios.CancelToken.source();
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState();
  const [data, setData] = useState();
  // used to force fetch

  useEffect(() => {
    let isCancelled = false;

    ;(async function fetch() {
      setFetching(true);

      try {
        if (!skip) {
          const result = await method(params, {cancelToken: signal.token});

          if (!isCancelled) {
            if (result.data) {
              setData(result.data);
              if (onSuccess) {
                onSuccess(result.data)
              }
            }

            setError(result);
          }
        }
      } catch (e) {
        if (axios.isCancel(e)) {
          console.log('Error: ', e); // => prints: Api is being canceled
        } else {
          setError(e);
        }
      } finally {
        setFetching(false);
        isCancelled = true;
      }
    })()

    return function cleanUp() {
      signal.cancel('Api is being canceled');
    };
  }, [skip, method, params]);

  return {
    fetching,
    data,
    error,
    update: setData,
  };
}
