import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-pluralrules/dist/locale-data/ru';
import '@formatjs/intl-pluralrules/dist/locale-data/uk';



import enTranslationMessages from './translations/en.json';
import ruTranslationMessages from './translations/ru.json';
import ukTranslationMessages from './translations/uk.json';

export const DEFAULT_LOCALE = 'ru';

// prettier-ignore
export const appLocales = ['en', 'ru', 'uk'];

export const localeOptions = appLocales.map(key => ({ label: key, value: key }));

export const translationMessages = {
  ru: flattenObject(ruTranslationMessages),
};

translationMessages.en = addFallbackMessages(flattenObject(enTranslationMessages));
translationMessages.uk = addFallbackMessages(flattenObject(ukTranslationMessages));

function flattenObject(ob) {
  const toReturn = {};

  Object.entries(ob).forEach(([key, value]) => {
    if (value && typeof value === 'object') {
      const flatObject = flattenObject(ob[key]);

      Object.entries(flatObject).forEach(([innerKey, innerValue]) => {
        toReturn[`${key}.${innerKey}`] = innerValue;
      });
    } else {
      toReturn[key] = value;
    }
  });

  return toReturn;
}

function addFallbackMessages(flattenMessages) {
  const fallbacks = Object.entries(translationMessages[DEFAULT_LOCALE])
    .reduce((result, [msgKey, msgVal]) => ({
      ...result,
      [msgKey]: `fb[${DEFAULT_LOCALE}]:${msgVal}`,
    }), {});

  return {
    ...fallbacks,
    ...flattenMessages,
  };
}
