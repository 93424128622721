import { DEFAULT_LOCALE } from './i18n';
import * as IntlActions from './actions';
import IntlTypes from './constants';
import * as IntlSelectors from './selectors';

const locale = localStorage.getItem('locale');

export const initialState = {
  locale: locale || DEFAULT_LOCALE,
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case IntlTypes.CHANGE_LOCALE_SUCCESS:
      return {
        ...state,
        locale: payload.value
      };
    default:
      return state;
  }
}
export { IntlActions };
export { IntlTypes };
export { IntlSelectors };


export default reducer;
