import React, {useEffect, useState, useCallback, useMemo} from 'react';
import { Link } from 'react-router-dom';
import { Grid, Button, Avatar, Dropdown, Dialog, CommentCount } from '../../../../components/ui-kit';
import routs from '../../../../modules/router/routes';
import { useIntl } from 'react-intl';
import {
  CANDIDATE_GROUPS,
  CANDIDATE_STATUSES,
  SORT_VARIANTS,
  SORT_OPTIONS,
} from '../../../../modules/projects/constants';
import CandidateStForm from './CandidateStForm'
import { mapCandidateStatusesToOptions } from '../../../../modules/projects/utils';

function isOptionDisabled(value) {
  return (option) => option.key === value.key;
}

const getColumns = ({
  rootLink,
  history,
  changeStatus,
  role,
  disabled,
  formatMessage,
  hasHiredCandidate,
}) => {
  return [{
    name: '№',
    selector: 'index',
    width: '50px',
    center: true,
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.CANDIDATE' }),
    selector: 'name',
    width: '150px',
    grow: 1,
    cell: ({ name, surname, logo, id  }) => (
      <div
        className="Users__avatar is-interact"
        onClick={() => {
          history.push(`${rootLink}${routs.CANDIDATE({ candidateId: id, mode: 'edit' })}/info`)
        }}
      >
        <Avatar src={logo} label={surname + name} size="sm" />
        <div className="Users__avatar-name">{surname} {name}</div>
      </div>
    ),
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.COMPANY' }),
    selector: 'current_company',
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.POSITION' }),
    selector: 'current_position',
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.SALARY_EXPECTATION' }),
    width: '100px',
    selector: 'salary_expectations',
    center: true,
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.COMMENTS' }),
    selector: 'status_count',
    width: '100px',
    center: true,
    cell: ({ status_count, ...rest }) => {
      const statusesRoute = `${rootLink}${routs.CANDIDATE({ candidateId: rest.id, mode: 'edit' })}/status`;

      return (
        <CommentCount
          count={status_count}
          onClick={() => history.push(statusesRoute)}
        />
      );
    }
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.STATUS' }),
    selector: 'status',
    allowOverflow: true,
    cell: (row) => {
      const value = CANDIDATE_STATUSES[row.status_candidate];
      const { color, label } = CANDIDATE_GROUPS[value.group];
      const input = {
        value: {
          ...value,
          label: formatMessage({ id: label || value.label })
        },
        onChange: (selectData) => changeStatus(selectData, row),
      };
      const isDisabled =
        role === 'admin'
        || (role === 'client' && value.key === 'STATUS_CANDIDATE_NEW')
        || disabled
        || value.key === 'STATUS_THE_CANDIDATE_IS_HIRED';
      const options = mapCandidateStatusesToOptions(
        CANDIDATE_GROUPS,
        CANDIDATE_STATUSES,
        {
          role,
          value,
          formatMessage,
          hasHiredCandidate
        }
      );

      return (
        <div className="Projects__grid-dropdown">
          <div className="Projects__dropdown-layer" style={{ background: color }}/>
          <Dropdown
            input={input}
            id={row.id}
            isDisabled={isDisabled}
            options={options}
            isOptionDisabled={isOptionDisabled(value, role)}
          />
        </div>
      );
    }
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.STATUS_ADDITIONAL' }),
    selector: 'status_candidate',
    format: (row) => formatMessage({ id: CANDIDATE_STATUSES[row.status_candidate].label }),
  }];
};


const Candidates = ({
  actions,
  projectId,
  flags,
  candidates,
  rootLink,
  role,
  history,
  isActive,
  isArchieved,
  candidateSort,
  project,
}) => {
  const [status, changeStatus] = useState({});
  const { formatMessage } = useIntl();
  const [isOpenStDialog, openStDialog] = useState(false);

  const closeDialog = useCallback(() => {
    openStDialog(false)
  }, []);

  const sortCandidates = useCallback(({ sort }) => {
    actions.getCandidates({ projectId, status: -7, sort });
  }, [projectId]);

  useEffect(() => {
    actions.getCandidates({ projectId, status: -7 });
  }, [projectId])

  useEffect(() => {
    if (flags.changingStatusCandidate === null) {
      closeDialog();
      changeStatus({});
    }
  }, [flags.changingStatusCandidate]);

  const changeCandidateStatus = useCallback((status, candidate) => {
    openStDialog(true);
    changeStatus({...status, candidateId: candidate.id})
  }, []);

  const sortParams = useMemo(() => {
    return {
      options: SORT_OPTIONS.map(({ label, ...rest }) => {
        console.log(rest, label)
        return {
          ...rest,
          label: formatMessage({ id: label })
        };
      }),
      callBack: sortCandidates,
      enabled: true,
      defaultValue: candidateSort,
      forceReset: (value) => value === SORT_VARIANTS.NONE,
      placeholder: formatMessage({ id: 'COMMON.SORT_BY' }),
    };
  }, [sortCandidates, candidateSort]);

  const extractCardColor = useCallback((row) => {
    const value = CANDIDATE_STATUSES[row.status_candidate];
    const { color } = CANDIDATE_GROUPS[value.group];

    return color;
  }, []);


  return (
    <>
      <Dialog
        isOpen={Boolean(isOpenStDialog)}
        title={status.title}
        onToggle={closeDialog}
        wrapClassName="Projects__st-dialog"
      >
        {status.value && (
          <CandidateStForm
            initialValues={{ status, projectId }}
            formatMessage={formatMessage}
            onCancel={closeDialog}
            isOpen={Boolean(isOpenStDialog)}
            loading={Boolean(flags.changingStatusCandidate)}
          />
        )}
      </Dialog>
      <Grid
        rows={candidates}
        columns={getColumns({
          rootLink,
          history,
          changeStatus: changeCandidateStatus,
          role,
          disabled: !isActive || isArchieved,
          formatMessage,
          hasHiredCandidate: project ? +project.number_of_hired_people : 0
        })}
        loading={flags.candidates}
        cardMode="candidate"
        prependHeader={!isArchieved && (
          <Button
            label={formatMessage({ id: 'CANDIDATES.NEW_CANDIDATE' })}
            color="slim"
            tag={Link}
            prefix="icon-plus"
            to={`${rootLink}${routs.ADD_CANDIDATE}/info`}
          />
        )}
        extractCardColor={extractCardColor}
        sort={sortParams}
      />
    </>
  );
};

export default Candidates;
