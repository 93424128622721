import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  TextField,
  Button,
  Avatar,
  Rating,
  FormUploader
} from './../../../components/ui-kit';
import {UsersActions, UsersSelectors} from './../../../modules/users';
import { useIntl } from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import cx from 'classnames';
import { useAuthContext } from "../../../hooks";


const Account = ({
  handleSubmit,
  error,
  initialValues,
  flags,
  readOnly,
}) => {
  const { profile: { role } } = useAuthContext();
  const dispatch = useDispatch();
  const user = useSelector(UsersSelectors.selectUser);
  const { formatMessage } = useIntl();

  if (readOnly) {
    return (
      <>
        <div className="Profile__shade-block Profile__view-profile">
          <div className="Profile__aside">
            <div className="Profile__avatar">
              <Avatar
                src={user.logo}
                label={`${user.first_name} ${user.last_name}`}
              />
            </div>
          </div>
          <div className="Profile__content">
            <div className="Profile__view-header">
              <h2>{user.first_name} {user.last_name}</h2>
              <div className="Profile__view-rating">
                <div className="Profile__view-reviews">
                  <div className="Profile__view-value">
                    {formatMessage({ id: 'COMMON.REVIEWS' })}: {user.reviews_count}
                  </div>
                </div>
                <Rating value={user.rating} isDisable size={16} displayValue />
              </div>
            </div>
            <div className="Profile__view-details">
              <div className="row">
                <div className="form-group col-xs-12 col-md-6 col-lg-6">
                  <div className="Profile__view-label">
                    {formatMessage({ id: 'FORM.INDUSTRIES' })}
                  </div>
                  <div className="Profile__view-value">{user.industries}</div>
                </div>
                <div className="form-group col-xs-12 col-md-6 col-lg-6">
                  <div className="Profile__view-label">
                    {formatMessage({ id: 'FORM.SPECIALIZATION' })}
                  </div>
                  <div className="Profile__view-value">{user.specialization}</div>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-xs-12 col-lg-12">
                  <div className="Profile__view-label">
                    {formatMessage({ id: 'FORM.EXPERIENCE' })}
                  </div>
                  <div className="Profile__view-value">{user.experience}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-xs-12 col-lg-12">
                <div className="Profile__view-value">{user.description}</div>
              </div>
            </div>
          </div>
        </div>
        {!!user.reviews && !!user.reviews.length && (
          <div className="Profile__shade-block Profile__reviews-profile">
            {user.reviews.map(review => (
              <div key={review.id} className="Profile__review-row">
                <Avatar
                  src={review.user.logo}
                  label={`${review.user.first_name} ${review.user.last_name}`}
                />
                <div className="Profile__review">
                  <div className="Profile__review-author">
                    {review.user.first_name} {review.user.last_name[0]}.
                  </div>
                  <div className="Profile__review-stars">
                    <div>
                      <div className="Profile__review-label">
                        {formatMessage({ id: 'FORM.LEVEL' })}
                      </div>
                      <Rating value={review.level} isDisable size={16} displayValue />
                    </div>
                    <div>
                      <div className="Profile__review-label">{formatMessage({ id: 'FORM.COMMUNICATION' })}</div>
                      <Rating value={review.communication} isDisable size={16} displayValue />
                    </div>
                    <div>
                      <div className="RecruiterReview__label">{formatMessage({ id: 'FORM.SPEED' })}</div>
                      <Rating value={review.speed} isDisable size={16} displayValue />
                    </div>
                  </div>
                </div>
                <div className="Profile__review-comment">
                  {review.message}
                </div>
              </div>
            ))}
          </div>
        )}
      </>
    )
  }

  return (
    <form onSubmit={handleSubmit(UsersActions.updateUser)}>
      <div className="Profile__wrapper">
        <div className="Profile__aside">
          <div className="Profile__avatar">
            <Field
              name="logo"
              component={FormUploader}
              label={formatMessage({ id: 'FORM.UPLOAD_PICTURE' })}
              accepts={['image/*']}
              onUpload={value => dispatch(UsersActions.uploadUserLogo(value))}
              id="logo"
              align="center"
              deleteLabel={formatMessage({ id: 'FORM.DELETE_PICTURE' })}
              fileRenderer={(value, Remove) => (
                <>
                  <Avatar
                    loading={flags.uploadAvatar}
                    src={value}
                    label={`${initialValues.first_name} ${initialValues.last_name}`}
                  />
                  {Remove}
                </>
              )}
            />
          </div>
        </div>
        <div className="Profile__content">
          <div className="row">
            <div className="form-group col-xs-12 col-md-6 col-lg-6">
              <Field
                type="text"
                name="last_name"
                id="last_name"
                required
                component={TextField}
                label={formatMessage({ id: 'FORM.LAST_NAME' })}
              />
            </div>
            <div className="form-group col-xs-12 col-md-6 col-lg-6">
              <Field
                type="text"
                name="first_name"
                id="first_name"
                required
                component={TextField}
                label={formatMessage({ id: 'FORM.FIRST_NAME' })}
              />
            </div>
          </div>
          <div className="row">
            {role !== 'client' && (
              <div className="form-group col-xs-12 col-md-6 col-lg-6">
                <Field
                  type="tel"
                  name="phone"
                  component={TextField}
                  id="phone"
                  autoComplete="off"
                  label={formatMessage({ id: 'FORM.PHONE_NUMBER' })}
                />
              </div>
            )}
            <div
              className={cx('form-group', {
                'col-6': role !== 'client',
                'col-12': role === 'client',
              })}
            >
              <Field
                type="text"
                name="specialization"
                id="specialization"
                required
                component={TextField}
                label={formatMessage({ id: 'FORM.SPECIALIZATION' })}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-xs-12 col-md-6 col-lg-6">
              <Field
                type="text"
                name="industries"
                component={TextField}
                id="industries"
                label={formatMessage({ id: 'FORM.INDUSTRIES' })}
              />
            </div>
            <div className="form-group col-xs-12 col-md-6 col-lg-6">
              <Field
                type="text"
                name="experience"
                id="experience"
                component={TextField}
                label={formatMessage({ id: 'FORM.EXPERIENCE' })}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-12">
              <Field
                type="textarea"
                name="description"
                id="description"
                component={TextField}
                label={formatMessage({ id: 'FORM.SHORT_DESCRIPTION' })}
                minRows={7}
                maxRows={17}
              />
            </div>
          </div>
          {!readOnly && (
            <div className="form-group">
              <Button
                type="submit"
                label={formatMessage({ id: 'COMMON.SAVE' })}
                loading={flags.userUpd}
                color="primary"
                rounded
              />
            </div>
          )}
        </div>
      </div>
      {error && (
        <div className="Auth__error">{formatMessage({ id: error })}</div>
      )}
    </form>
  );
};

export default reduxForm({
  form: 'update-customer-account',
  destroyOnUnmount: false,
  enableReinitialize: true,
})(Account);
