import { put } from 'redux-saga/effects';
import IntlTypes from '../constants';
import actionCreator from '../../../utils/actionCreator';

export function* handleChangeLocale({ payload }) {
  try {
    localStorage.setItem('locale', payload.value);
    yield put(actionCreator(IntlTypes.CHANGE_LOCALE_SUCCESS, payload));
  } catch (e) {
    yield put(actionCreator(IntlTypes.CHANGE_LOCALE_ERROR));
  }
}

export function* setInitialLocale() {
  const locale = localStorage.getItem('locale');

  if (locale) {
    yield put(actionCreator(IntlTypes.CHANGE_LOCALE_SUCCESS, { value: locale }));
  }
}
