import React, { useEffect, useCallback, useMemo } from 'react';
import {
  Grid,
  Avatar,
} from '../../../../components/ui-kit';
import routs from '../../../../modules/router/routes';
import { DATE_FORMAT } from '../../../../modules/projects/constants';
import { matchPath } from 'react-router';
import moment from 'moment';
import Comment from '../Comment';
import CommentForm from '../CommentForm';


const getColumns = ({ params, formatMessage, history: { push } }) => {
  return [{
    name: '№',
    selector: 'index',
    width: '50px',
    center: true,
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.DATE' }),
    selector: 'date',
    width: '120px',
    format: ({ date }) => moment.unix(date).format(DATE_FORMAT)
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.AUTHOR' }),
    selector: 'manager',
    width: '200px',
    cell: ({ user: { last_name, first_name, logo, id, role }}) => {
      function handleRedirect() {
        const projectPath = routs.PROJECT(params);
        const clientPath = routs.PROJECT_CLIENT({ userId: id, projectId: params.projectId }) + '/account';
        const recruiterPath = routs.PROJECT_RECRUITER({ userId: id, projectId: params.projectId });

        push(role === 'client' ? projectPath + clientPath : recruiterPath);
      }

      return (
        <div
          className="Users__avatar is-interact"
          onClick={handleRedirect}
        >
          <Avatar src={logo} label={first_name + last_name} size="sm" />
          <div className="Users__avatar-name">{first_name} {last_name}</div>
        </div>
      );
    }
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.COMMENT' }),
    allowOverflow: true,
    selector: 'message',
    format: ({ message, viewed, file }) => (
      <Comment
        file={file}
        text={message}
        viewed={viewed}
        formatMessage={formatMessage}
      />
    )
  }];
};

const ACTIVITY_TYPE = 1;

const Statuses = ({
  actions,
  flags,
  routProps: {
    history,
    location,
  },
  matchParams,
  comments,
  formatMessage
}) => {
  const { params: { candidateId } } = matchPath(
    location.pathname,
    {
      path: `${routs.PROJECT()}/candidates${routs.CANDIDATE()}`,
    }
  );
  useEffect(() => {
    if (candidateId) {
      actions.getCandidateActivities({ stateId: candidateId, type: ACTIVITY_TYPE });
    }
  }, [actions, candidateId]);

  const onSubmit = useCallback((comment, callBack, file) => {
    actions.postComment({
      comment,
      stateId: candidateId,
      callBack,
      type: ACTIVITY_TYPE,
      file,
    });
  }, [candidateId, actions]);

  const columns = useMemo(() => getColumns({
    history,
    formatMessage,
    params: matchParams,
  }), [history, formatMessage, matchParams]);

  return (
    <div className="Projects__grid align-start Statuses">
      <Grid
        rows={comments}
        columns={columns}
        cardMode="status"
        overflowYOffset="50px"
        loading={flags.comments}
      />
      <CommentForm
        onSubmit={onSubmit}
        uploading={flags.uploadAttachments}
        loading={flags.commentPost}
      />
    </div>
  );
};

export default Statuses;
