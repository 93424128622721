import React, { useCallback } from 'react';
import cx from 'classnames';
import InputMask from 'react-input-mask';
import emojiStrip from 'emoji-strip';
import TextareaAutosize from 'react-autosize-textarea';
import { Dropdown } from '../Dropdown';
import { useIntl } from 'react-intl';
import './styles.sass';

const TEXT_FIELD_MAX = 1500;

const PHONE_MASK = '+38 (999) 999 99 99';
const cutByMaxLength = (str, max) => {
  if (str.length > max) {
    return str.substr(0, max);
  }

  return str;
};


const TextField = ({
  type,
  id,
  size,
  meta,
  placeholder,
  required,
  maxRows,
  inputMask,
  label,
  minRows,
  disabled,
  replacedComponent,
  maxLength,
  options,
  unit,
  hint,
  isLoading,
  input: {onChange, value, onBlur}
}) => {
  const isTextarea = type === 'textarea';
  const isTel = type === 'tel';
  const hasError = Boolean(meta.error);
  const { formatMessage } = useIntl();

  const handleChange = useCallback((e) => {
    const { value } = e.target;
    const strippedValue = emojiStrip(value);
    const cutValue = cutByMaxLength(strippedValue, maxLength);

    onChange(cutValue);
  }, [onChange]);

  return (
    <>
      <div
        className={cx('TextField', {
          'is-textarea': isTextarea,
          'has-error': hasError,
          'has-unit': unit,
          [`is-${size}`]: true,
        })}
      >
        {
          Boolean(label || hint)  && (
            <label htmlFor={id} className="TextField__label">
              {label}&nbsp;
              {
                required && (<span className="TextField__required">*</span>)
              }
              {
                Boolean(hint && value) && <span className="TextField__hint">&nbsp;({hint})</span>
              }
            </label>
          )
        }
        <div className="TextField__field">
          {
            (maxRows === 1 && !isTel && !inputMask && type !== 'select') && (
              <input
                className="form-control"
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                type={type}
                onBlur={onBlur}
                disabled={disabled}
                id={id}
              />
            )
          }
          {
            (isTel && !replacedComponent) && (
              <InputMask
                placeholder={PHONE_MASK}
                mask={PHONE_MASK}
                className="form-control"
                value={value}
                required={required}
                type="text"
                onBlur={onBlur}
                onChange={handleChange}
                disabled={disabled}
              />
            )
          }

          {
            (inputMask && !replacedComponent) && (
              <InputMask
                placeholder={inputMask}
                mask={inputMask}
                className="form-control"
                value={value}
                required={required}
                onBlur={onBlur}
                onChange={handleChange}
                disabled={disabled}
              />
            )
          }
          {
            (!isTel && maxRows > 1 && !replacedComponent) && (
              <TextareaAutosize
                value={value}
                className="form-control"
                onChange={handleChange}
                placeholder={placeholder}
                type={type}
                maxRows={maxRows}
                onBlur={onBlur}
                id={id}
                rows={minRows}
                disabled={disabled}
              />
            )
          }
          {
            type === 'select' && (
              <Dropdown {...{
                input: { value, onChange },
                id,
                meta,
                placeholder,
                label,
                options,
                isLoading,
                isDisabled: disabled
              }} />
            )
          }
          {
            unit && (
              <div className="TextField__unit">{unit}</div>
            )
          }
        </div>
      </div>
      {
        hasError && <div className="TextField__error">
          {meta.formatted ? meta.error : formatMessage({ id: `ERRORS.${meta.error}` })}
        </div>
      }
    </>
  )
}

TextField.defaultProps = {
  type: 'text',
  size: 'md',
  meta: {},
  maxRows: 1,
  minRows: 1,
  maxLength: TEXT_FIELD_MAX,
};

export default TextField;
