import React from 'react';

import {Path} from './Path';

const DEFAULT_SIZE: number = 25;

export const Spinner = ({size = DEFAULT_SIZE, color = '#009EB9', className}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    style={{width: size}}
    viewBox="0 0 80 80"
    className={className}
  >
    <Path color={color}>
      {() => (
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 40 40"
          to="360 40 40"
          dur="0.6s"
          repeatCount="indefinite"
        />
      )}
    </Path>
  </svg>
);
