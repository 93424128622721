import mc from 'mirror-creator';
import routs from './../router/routes';

export default mc([
  'INIT',
  'DESTROY',

  'FINISH_PROJECT_REQUEST',
  'FINISH_PROJECT_SUCCESS',
  'FINISH_PROJECT_ERROR',

  'POST_REVIEW_REQUEST',
  'POST_REVIEW_SUCCESS',
  'POST_REVIEW_ERROR',

  'GET_PROJECT_STATUSES_REQUEST',
  'GET_PROJECT_STATUSES_SUCCESS',
  'GET_PROJECT_STATUSES_ERROR',

  'POST_PROJECT_COMMENT_REQUEST',
  'POST_PROJECT_COMMENT_SUCCESS',
  'POST_PROJECT_COMMENT_ERROR',

  'UPLOAD_ATTACHMENTS_REQUEST',
  'UPLOAD_ATTACHMENTS_SUCCESS',
  'UPLOAD_ATTACHMENTS_ERROR',

  'GET_FULL_COMMENT_REQUEST',
  'GET_FULL_COMMENT_SUCCESS',
  'GET_FULL_COMMENT_ERROR',

  'UPLOAD_FILE_REQUEST',
  'UPLOAD_FILE_SUCCESS',
  'UPLOAD_FILE_ERROR',

  'GET_CANDIDATE_REQUEST',
  'GET_CANDIDATE_SUCCESS',
  'GET_CANDIDATE_ERROR',

  'DELETE_CANDIDATE_REQUEST',
  'DELETE_CANDIDATE_SUCCESS',
  'DELETE_CANDIDATE_ERROR',

  'UPDATE_CANDIDATE_REQUEST',
  'UPDATE_CANDIDATE_SUCCESS',
  'UPDATE_CANDIDATE_ERROR',

  'DELETE_PROJECT_REQUEST',
  'DELETE_PROJECT_SUCCESS',
  'DELETE_PROJECT_ERROR',

  'GET_PROJECTS_REQUEST',
  'GET_PROJECTS_SUCCESS',
  'GET_PROJECTS_ERROR',

  'GET_PROJECT_REQUEST',
  'GET_PROJECT_SUCCESS',
  'GET_PROJECT_ERROR',

  'GET_STATUSES_REQUEST',
  'GET_STATUSES_SUCCESS',
  'GET_STATUSES_ERROR',

  'GET_FIELDS_REQUEST',
  'GET_FIELDS_SUCCESS',
  'GET_FIELDS_ERROR',

  'GET_CANDIDATE_ACTIVITIES_REQUEST',
  'GET_CANDIDATE_ACTIVITIES_SUCCESS',
  'GET_CANDIDATE_ACTIVITIES_ERROR',

  'ADD_FIELD_REQUEST',
  'ADD_FIELD_SUCCESS',
  'ADD_FIELD_ERROR',

  'UPDATE_FIELD_REQUEST',
  'UPDATE_FIELD_SUCCESS',
  'UPDATE_FIELD_ERROR',

  'REMOVE_FIELD_REQUEST',
  'REMOVE_FIELD_SUCCESS',
  'REMOVE_FIELD_ERROR',

  'ACTIVATE_PROJECT_REQUEST',
  'ACTIVATE_PROJECT_SUCCESS',
  'ACTIVATE_PROJECT_ERROR',

  'GET_CANDIDATES_REQUEST',
  'GET_CANDIDATES_SUCCESS',
  'GET_CANDIDATES_ERROR',

  'GET_STOP_LIST_REQUEST',
  'GET_STOP_LIST_SUCCESS',
  'GET_STOP_LIST_ERROR',

  'GET_RECRUITERS_REQUEST',
  'GET_RECRUITERS_SUCCESS',
  'GET_RECRUITERS_ERROR',

  'GET_CANDIDATE_FIELDS_REQUEST',
  'GET_CANDIDATE_FIELDS_SUCCESS',
  'GET_CANDIDATE_FIELDS_ERROR',

  'ADD_CANDIDATE_FIELD_REQUEST',
  'ADD_CANDIDATE_FIELD_SUCCESS',
  'ADD_CANDIDATE_FIELD_ERROR',

  'REMOVE_CANDIDATE_FIELD_REQUEST',
  'REMOVE_CANDIDATE_FIELD_SUCCESS',
  'REMOVE_CANDIDATE_FIELD_ERROR',

  'UPDATE_CANDIDATE_FIELD_REQUEST',
  'UPDATE_CANDIDATE_FIELD_SUCCESS',
  'UPDATE_CANDIDATE_FIELD_ERROR',

  'GET_INBOX_REQUEST',
  'GET_INBOX_SUCCESS',
  'GET_INBOX_ERROR',

  'DO_REQUEST_PROJECT_REQUEST',
  'DO_REQUEST_PROJECT_SUCCESS',
  'DO_REQUEST_PROJECT_ERROR',

  'GET_CANDIDATE_STATUSES_REQUEST',
  'GET_CANDIDATE_STATUSES_SUCCESS',
  'GET_CANDIDATE_STATUSES_ERROR',

  'ACCEPT_RECRUITER_REQUEST',
  'ACCEPT_RECRUITER_SUCCESS',
  'ACCEPT_RECRUITER_ERROR',

  'CHANGE_PROJECT_ST_REQUEST',
  'CHANGE_PROJECT_ST_SUCCESS',
  'CHANGE_PROJECT_ST_ERROR',

  'ASK_PROJECT_PAUSE_REQUEST',
  'ASK_PROJECT_PAUSE_SUCCESS',
  'ASK_PROJECT_PAUSE_ERROR',

  'DO_PROJECT_PAUSE_REQUEST',
  'DO_PROJECT_PAUSE_SUCCESS',
  'DO_PROJECT_PAUSE_ERROR',

  'POST_COMMENT_REQUEST',
  'POST_COMMENT_SUCCESS',
  'POST_COMMENT_ERROR',

  'CHANGE_RECRUITER_REQUEST',
  'CHANGE_RECRUITER_SUCCESS',
  'CHANGE_RECRUITER_ERROR',

  'GET_RECRUITER_COMMENTS_REQUEST',
  'GET_RECRUITER_COMMENTS_SUCCESS',
  'GET_RECRUITER_COMMENTS_ERROR',

  'SEND_RECRUITER_COMMENT_REQUEST',
  'SEND_RECRUITER_COMMENT_SUCCESS',
  'SEND_RECRUITER_COMMENT_ERROR',

  'GET_RECRUITER_PROPOSAL_REQUEST',
  'GET_RECRUITER_PROPOSAL_SUCCESS',
  'GET_RECRUITER_PROPOSAL_ERROR',
], {
  prefix: 'PROJECT:',
});

export const ACTIVITY_COMMENT_LENGTH = 1000;

export const DATE_FORMAT = 'DD MMM, YYYY';
export const DATE_CHAT_FORMAT = 'DD MMM - HH:MM';

export const TABS = {
  active: {
    text: { id: 'PROJECTS.TABS.ACTIVE' },
    value: '1,4',
    link: routs.PROJECTS('active'),
  },
  draft: {
    text: { id: 'PROJECTS.TABS.DRAFT' },
    value: 2,
    link: routs.PROJECTS('draft'),
  },
  archiv: {
    text: { id: 'PROJECTS.TABS.ARCHIVE' },
    value: 3,
    link: routs.PROJECTS('archiv'),
  },
};

export const PROJECT_STATUSES = {
  1: {
    label: 'PROJECTS.STATUSES.IN_WORK',
    value: 1,
    color: '#FFD98D'
  },
  2: {
    label: 'PROJECTS.STATUSES.DRAFT',
    value: 2,
    color: '#BBE8F4'
  },
  3: {
    label: 'PROJECTS.STATUSES.FINISHED',
    value: 3,
    color: '#B9FAC7'
  },
  4: {
    label: 'PROJECTS.STATUSES.PAUSED',
    value: 4,
    color: '#EAE9E9'
  },
};

export const STATUS_ACTIONS = {
  CHOOSE_RECRUITER: {
    key: 'CHOOSE_RECRUITER',
    title: 'PROJECTS.CHANGE_RECRUITER_CHOOSE_REPLACEMENT'
  },
  CHANGE_RECRUITER: {
    key: 'CHANGE_RECRUITER',
    title: 'PROJECTS.CHANGE_RECRUITER_DIALOG_TITLE'
  },
  DELETE: {
    key: 'DELETE',
    title: 'PROJECTS.ACTIONS.TO_DELETE'
  },
  FORCE_DELETE: {
    key: 'FORCE_DELETE',
    title: 'PROJECTS.ACTIONS.TO_DELETE'
  },
  ACTIVATE: {
    key: 'ACTIVATE',
    title: 'PROJECTS.ACTIONS.TO_ACTIVATE'
  },
  PAUSE: {
    key: 'PAUSE',
    title: 'PROJECTS.ACTIONS.TO_PAUSE'
  },
  PAUSE_CONFIRM: {
    key: 'PAUSE_CONFIRM',
    title: 'PROJECTS.ACTIONS.TO_PAUSE'
  },
  FINISH: {
    key: 'FINISH',
    title: 'PROJECTS.ACTIONS.TO_FINISH'
  },
  RESTORE: {
    key: 'RESTORE',
    title: 'PROJECTS.ACTIONS.TO_RESUME'
  }
};

export const CANDIDATE_GROUPS = {
  NEW: {
    color: '#BBE8F4',
  },
  IN_WORK: {
    label: 'CANDIDATES.GROUPS.IN_WORK',
    color: '#EAC44A',
    options: [],
  },
  DEFLECTED: {
    label: 'CANDIDATES.GROUPS.REFUSED',
    color: '#B7B7B7',
    options: [],
    title: 'CANDIDATES.STATUS_TITLES.REJECT_CANDIDATE',
  },
  HIRED: {
    label: 'CANDIDATES.GROUPS.HIRED',
    color: '#79DC8E',
    options: [],
  }
};

export const CANDIDATE_STATUSES = {
  0: {
    key: 'STATUS_CANDIDATE_NEW',
    label: 'CANDIDATES.STATUSES.STATUS_CANDIDATE_NEW',
    group: 'NEW',
  },
  1: {
    key: 'STATUS_THE_CANDIDATE_QUALIFIED',
    label: 'CANDIDATES.STATUSES.STATUS_THE_CANDIDATE_QUALIFIED',
    group: 'IN_WORK',
    title: 'CANDIDATES.STATUS_TITLES.STATUS_THE_CANDIDATE_QUALIFIED',
  },
  2: {
    key: 'STATUS_ASSIGN_AN_INTERVIEW',
    label: 'CANDIDATES.STATUSES.STATUS_ASSIGN_AN_INTERVIEW',
    group: 'IN_WORK',
    title: 'CANDIDATES.STATUS_TITLES.STATUS_ASSIGN_AN_INTERVIEW',
  },
  3: {
    key: 'STATUS_WAIT',
    label: 'CANDIDATES.STATUSES.STATUS_WAIT',
    group: 'IN_WORK',
    title: 'CANDIDATES.STATUS_TITLES.STATUS_WAIT',
  },
  4: {
    key: 'STATUS_SHORT_LIST',
    label: 'CANDIDATES.STATUSES.STATUS_SHORT_LIST',
    group: 'IN_WORK',
    title: 'CANDIDATES.STATUS_TITLES.STATUS_SHORT_LIST',
  },
  6: {
    key: 'STATUS_SEND_OFFER',
    label: 'CANDIDATES.STATUSES.STATUS_SEND_OFFER',
    group: 'IN_WORK',
    title: 'CANDIDATES.STATUS_TITLES.STATUS_SEND_OFFER',
  },
  7: {
    key: 'STATUS_STOP_LIST',
    label: 'CANDIDATES.STATUSES.STATUS_STOP_LIST',
    group: 'DEFLECTED',
  },
  8: {
    key: 'STATUS_NOT_A_CANDIDATE',
    label: 'CANDIDATES.STATUSES.STATUS_NOT_A_CANDIDATE',
    group: 'DEFLECTED',
  },
  9: {
    key: 'STATUS_NO_CONTACT',
    label: 'CANDIDATES.STATUSES.STATUS_NO_CONTACT',
    group: 'DEFLECTED',
  },
  10: {
    key: 'STATUS_REFUSAL_OF_THE_CANDIDATE',
    label: 'CANDIDATES.STATUSES.STATUS_REFUSAL_OF_THE_CANDIDATE',
    group: 'DEFLECTED',
  },
  11: {
    key: 'STATUS_THE_CANDIDATE_REFUSED',
    label: 'CANDIDATES.STATUSES.STATUS_THE_CANDIDATE_REFUSED',
    group: 'DEFLECTED',
  },
  12: {
    key: 'STATUS_THE_CANDIDATE_IS_HIRED',
    label: 'CANDIDATES.STATUSES.STATUS_THE_CANDIDATE_IS_HIRED',
    group: 'HIRED',
    title: 'CANDIDATES.STATUS_TITLES.STATUS_THE_CANDIDATE_IS_HIRED',
  },
};

export const CANDIDATE_PERMISSIONS = {
  recruiter: [
    'STATUS_THE_CANDIDATE_QUALIFIED',
    'STATUS_NOT_A_CANDIDATE',
    'STATUS_THE_CANDIDATE_REFUSED',
    'STATUS_NO_CONTACT',
    'STATUS_REFUSAL_OF_THE_CANDIDATE'
  ],
  admin: [],
  client: [
    'STATUS_THE_CANDIDATE_REFUSED',
    'STATUS_THE_CANDIDATE_IS_HIRED',
    'STATUS_REFUSAL_OF_THE_CANDIDATE',

    'STATUS_SEND_OFFER',
    'STATUS_WAIT',
    'STATUS_SHORT_LIST',
    'STATUS_ASSIGN_AN_INTERVIEW',
  ],
};

export const SORT_VARIANTS = mc([
  'NONE',
  'BY_STATUS',
  'BY_ORDER_ASK',
  'BY_ORDER_DESK',
  'BY_LAST_NAME_ASK',
  'BY_LAST_NAME_ASK',
]);

export const SORT_OPTIONS = [{
  label: 'COMMON.SORT.NONE',
  value: SORT_VARIANTS.NONE,
  sort: ''
}, {
  label: 'COMMON.SORT.BY_STATUS',
  value: SORT_VARIANTS.BY_STATUS,
  sort: 'status'
}, {
  label: 'COMMON.SORT.BY_ORDER_ASC',
  value: SORT_VARIANTS.BY_ORDER_ASK,
  sort: 'id'
}, {
  label: 'COMMON.SORT.BY_ORDER_DESC',
  value: SORT_VARIANTS.BY_ORDER_DESK,
  sort: '-id'
}, {
  label: 'COMMON.SORT.BY_NAME_ASC',
  value: SORT_VARIANTS.BY_LAST_NAME_ASK,
  sort: 'surname'
}, {
  label: 'COMMON.SORT.BY_NAME_DESC',
  value: SORT_VARIANTS.BY_LAST_NAME_DESK,
  sort: '-surname'
}];
