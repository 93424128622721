import mc from 'mirror-creator';
//import routs from './../router/routes';

export default mc([
  'INIT',
  'DESTROY',

  'GET_USERS_REQUEST',
  'GET_USERS_SUCCESS',
  'GET_USERS_ERROR',

  'GET_STATUSES_REQUEST',
  'GET_STATUSES_SUCCESS',
  'GET_STATUSES_ERROR',

  'ACTIVATE_USER_REQUEST',
  'ACTIVATE_USER_SUCCESS',
  'ACTIVATE_USER_ERROR',

  'GET_USER_REQUEST',
  'GET_USER_SUCCESS',
  'GET_USER_ERROR',

  'UPLOAD_COMPANY_LOGO_REQUEST',
  'UPLOAD_COMPANY_LOGO_SUCCESS',
  'UPLOAD_COMPANY_LOGO_ERROR',

  'UPLOAD_USER_LOGO_REQUEST',
  'UPLOAD_USER_LOGO_SUCCESS',
  'UPLOAD_USER_LOGO_ERROR',

], {
  prefix: 'USERS:',
});
