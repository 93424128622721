import React from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import routs from '../../../../modules/router/routes';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { injectIntl } from 'react-intl';
import Company from '../../../Company/Profile'
import ClientProfile from '../../../Customers/Customer'

const Client = ({ match: { url, params }, intl: { formatMessage } }) => (
  <>
    <BreadcrumbsItem to={url}>Клиент</BreadcrumbsItem>
    <ClientProfile
      style={{ paddingTop: 0 }}
      match={{
        params,
        url,
      }}

    />
  </>
);

export default injectIntl(Client);
