import React from 'react';
import { ResetEmail } from '../ResetEmail';
import { ResetPass } from '../ResetPass';
import {Page} from '../../../components/ui-kit';

export const Settings = () => (
  <div className="Profile__settings">
    <Page>
      <ResetEmail/>
    </Page>
    <Page>
      <ResetPass/>
    </Page>
  </div>
);
