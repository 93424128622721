import React, {useCallback, useEffect} from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import {
  TextField,
  Button,
  FormUploader,
  Avatar,
  Page,
  Rating,
} from './../../../components/ui-kit';
import { BASE_FILE_URL } from '../../../app/API';
import { ProfileActions, ProfileSelectors } from './../../../modules/profile';
import { USER_TYPES } from '../../../modules/profile/constants';
import { useForm, Controller } from 'react-hook-form';

const SCHEME = {
  [USER_TYPES.ADMIN]: ['position'],
  [USER_TYPES.CLIENT]: ['position'],
  [USER_TYPES.RECRUITER]: ['industries', 'specialization', 'experience', 'description', 'cv', 'linkedin'],
};

function parseUrl(url) {
  const partials = url.split('/');

  return partials[partials.length - 1];
}

export const Account = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl()
  const flags = useSelector(ProfileSelectors.selectFlags);
  const profile = useSelector(ProfileSelectors.selectProfile);
  const fields = profile.role ? SCHEME[profile.role] : [];
  const form = useForm({
    defaultValues: profile,
    mode: 'onChange',
  })

  const handleSubmit = useCallback(params => {
    dispatch(ProfileActions.updateProfile(params));
  }, []);

  const handleUploadAvatar = useCallback(params => {
    dispatch(ProfileActions.uploadAvatar(params));
  }, []);

  const handleUploadFile = useCallback(data => {
    dispatch(ProfileActions.uploadFile(data));
  }, []);

  const handleReset = useCallback(() => {
    form.reset(profile);
  }, [profile]);

  useEffect(() => {
    if (profile.role) {
      form.reset(profile);
    }
  }, [profile])

  return (
    <Page>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        {profile.role && <h1>{profile.role}</h1>}
        <div className="Profile__wrapper">
          <div className="Profile__aside">
            <div className="Profile__avatar">
              <Controller
                name="logo"
                control={form.control}
                render={field => (
                  <FormUploader
                    input={field}
                    label={formatMessage({ id: 'FORM.UPLOAD_PICTURE' })}
                    accepts={['image/*']}
                    onUpload={handleUploadAvatar}
                    id="logo"
                    meta={{
                      error: form.errors.logo ? form.errors.logo.message : ''
                    }}
                    align="center"
                    btnColor="primary"
                    deleteLabel={formatMessage({ id: 'FORM.DELETE_PICTURE' })}
                    fileRenderer={(value, Remove) => (
                      <>
                        <Avatar
                          loading={flags.uploadAvatar}
                          src={value}
                          label={`${profile.first_name} ${profile.last_name}`}
                        />
                        {Remove}
                      </>
                    )}
                  />
                )}
              />
            </div>
          </div>
          <div className="Profile__content">
            <div className="Profile__row">
              <div className="Profile__col-6">
                <Controller
                  control={form.control}
                  name="first_name"
                  render={field => <TextField
                    input={field}
                    label={formatMessage({ id: 'FORM.FIRST_NAME' })}
                    meta={{
                      error: form.errors.first_name ? form.errors.first_name.message : ''
                    }}
                  />}
                />
              </div>
              <div className="Profile__col-6">
                <Controller
                  control={form.control}
                  name="last_name"
                  render={field => <TextField
                    input={field}
                    meta={{
                      error: form.errors.last_name ? form.errors.last_name.message : ''
                    }}
                    label={formatMessage({ id: 'FORM.LAST_NAME' })}
                  />}
                />
              </div>
              {fields.includes('position') && (
                <div className="Profile__col-6">
                  <Controller
                    control={form.control}
                    name="position"
                    render={field => <TextField
                      input={field}
                      label={formatMessage({ id: 'FORM.POSITION' })}
                      meta={{
                        error: form.errors.position ? form.errors.position.message : ''
                      }}
                    />}
                  />
                </div>
              )}
              {
                fields.includes('industries') && (
                  <div className="Profile__col-6">
                    <Controller
                      name="industries"
                      control={form.control}
                      render={field => (
                        <TextField
                          input={field}
                          meta={{
                            error: form.errors.industries ? form.errors.industries.message : ''
                          }}
                          placeholder={formatMessage({ id: 'FORM.HINTS.INDUSTRIES' })}
                          label={formatMessage({ id: 'FORM.INDUSTRIES' })}
                        />
                      )}
                    />
                  </div>
                )
              }
              {
                fields.includes('specialization') && (
                  <div className="Profile__col-6">
                    <Controller
                      name="specialization"
                      control={form.control}
                      render={field => (
                        <TextField
                          meta={{
                            error: form.errors.specialization ? form.errors.specialization.message : ''
                          }}
                          input={field}
                          placeholder={formatMessage({ id: 'FORM.HINTS.SPECIALIZATION' })}
                          label={formatMessage({ id: 'FORM.SPECIALIZATION' })}
                        />
                      )}
                    />
                  </div>
                )
              }
              {
                fields.includes('experience') && (
                  <div className="Profile__col-6">
                    <Controller
                      name="experience"
                      control={form.control}
                      render={field => <TextField
                        input={field}
                        label={formatMessage({ id: 'FORM.EXPERIENCE' })}
                        meta={{
                          error: form.errors.experience ? form.errors.experience.message : ''
                        }}
                      />}
                    />
                  </div>
                )
              }
              {profile.role === 'recruiter' && (
                <div className="Profile__rating Profile__col-6">
                  <div className="Profile__reviews">
                    <span>{formatMessage({ id: 'COMMON.REVIEWS' })}:</span>
                    {profile.reviews_count}
                  </div>
                  <Rating
                    isDisable
                    value={profile.rating}
                    size={16}
                    displayValue
                  />
                </div>
              )}
            </div>
            {fields.includes('description') && (
              <div className="Profile__row">
                <div className="Profile__col-12">
                  <Controller
                    name="description"
                    control={form.control}
                    render={field => (
                      <TextField
                        input={field}
                        type="textarea"
                        meta={{
                          error: form.errors.description ? form.errors.description.message : ''
                        }}
                        maxRows={5}
                        label={formatMessage({ id: 'FORM.SHORT_DESCRIPTION' })}
                      />
                    )}
                  />
                </div>
              </div>
            )}
            <div className="Profile__row">
              <div className="Profile__col-6">
                <Controller
                  name="phone"
                  control={form.control}
                  render={field => (
                    <TextField
                      input={field}
                      type="tel"
                      meta={{
                        error: form.errors.phone ? form.errors.phone.message : ''
                      }}
                      label={formatMessage({ id: 'FORM.PHONE_NUMBER' })}
                      autoComplete="off"
                    />
                  )}
                />
              </div>
            </div>
            {fields.includes('cv') && (
              <div className="Profile__row">
                <div className="Profile__col-12">
                  <Controller
                    control={form.control}
                    name="cv"
                    render={field => (
                      <FormUploader
                        input={field}
                        meta={{
                          error: form.errors.cv ? form.errors.cv.message : ''
                        }}
                        label={formatMessage({ id: 'FORM.UPLOAD_CV' })}
                        accepts={['.pdf']}
                        onUpload={handleUploadFile}
                        fileRenderer={(value, Remove) => value && (
                          <div className="Profile__file-preview">
                            <a
                              href={`${BASE_FILE_URL}${value}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="Profile__link"
                            >
                              {parseUrl(value)}
                            </a>
                            {Remove}
                          </div>
                        )}
                      />
                    )}
                  />
                </div>
              </div>
            )}
            {fields.includes('linkedin') && (
              <div className="Profile__row">
                <div className="Profile__col-6">
                  <Controller
                    control={form.control}
                    name="linkedin"
                    render={field => (
                      <TextField
                        meta={{
                          error: form.errors.linkedin ? form.errors.linkedin.message : ''
                        }}
                        input={field}
                        label={formatMessage({ id: 'FORM.LINKEDIN_URL' })}
                      />
                    )}
                  />
                </div>
              </div>
            )}
            <div className="form-footer lg-static">
              <div className="form-footer__cell">
                <Button
                  onClick={handleReset}
                  color="secondary"
                  rounded
                  prefix="icon-close2"
                  label={formatMessage({ id: 'COMMON.CANCEL' })}
                />
              </div>
              <div className="form-footer__cell">
                <Button
                  type="submit"
                  rounded
                  prefix="icon-check"
                  label={formatMessage({ id: 'COMMON.SAVE' })}
                  loading={flags.update}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </Page>
  );
};

