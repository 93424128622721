import { createFormAction } from 'redux-form-saga';
import { ProjectTypes } from './index';
import actionCreator from '../../utils/actionCreator';

export const uploadAttachment = (params) => actionCreator(ProjectTypes.UPLOAD_ATTACHMENTS_REQUEST, params);
export const updateProject = createFormAction('UPDATE_PROJECT');
export const addProject = createFormAction('ADD_PROJECT');
export const addCandidate = createFormAction('ADD_CANDIDATE');
export const updateCandidate = createFormAction('UPDATE_CANDIDATE');
export const changeCandidateStatus = createFormAction('CHANGE_CANDIDATE_STATUS');

export const init = () => actionCreator(ProjectTypes.INIT);

export const getInbox = (params) => actionCreator(ProjectTypes.GET_INBOX_REQUEST, params);
export const doRequestProject = (params) => actionCreator(ProjectTypes.DO_REQUEST_PROJECT_REQUEST, params);
export const getCandidate = (params) => actionCreator(ProjectTypes.GET_CANDIDATE_REQUEST, params);
export const deleteCandidate = (params) => actionCreator(ProjectTypes.DELETE_CANDIDATE_REQUEST, params);
export const getProjects = (params) => actionCreator(ProjectTypes.GET_PROJECTS_REQUEST, params);
export const getFields = (params) => actionCreator(ProjectTypes.GET_FIELDS_REQUEST, params);
export const getStatuses = (params) => actionCreator(ProjectTypes.GET_STATUSES_REQUEST, params);

export const getProject = (params) => actionCreator(ProjectTypes.GET_PROJECT_REQUEST, params);
export const uploadFile = (params) => actionCreator(ProjectTypes.UPLOAD_FILE_REQUEST, params);
export const deleteProject = (params) => actionCreator(ProjectTypes.DELETE_PROJECT_REQUEST, params);

export const addField = (params) => actionCreator(ProjectTypes.ADD_FIELD_REQUEST, params);
export const deleteField = (params) => actionCreator(ProjectTypes.REMOVE_FIELD_REQUEST, params);
export const updateField = (params) => actionCreator(ProjectTypes.UPDATE_FIELD_REQUEST, params);
export const activateProject = (params) => actionCreator(ProjectTypes.ACTIVATE_PROJECT_REQUEST, params);
export const getCandidates = (params) => actionCreator(ProjectTypes.GET_CANDIDATES_REQUEST, params);
export const getRecruiters = (params) => actionCreator(ProjectTypes.GET_RECRUITERS_REQUEST, params);
export const getStopList = (params) => actionCreator(ProjectTypes.GET_STOP_LIST_REQUEST, params);


export const getCandidateFields = (params) => actionCreator(ProjectTypes.GET_CANDIDATE_FIELDS_REQUEST, params);
export const addCandidateField = (params) => actionCreator(ProjectTypes.ADD_CANDIDATE_FIELD_REQUEST, params);
export const removeCandidateField = (params) => actionCreator(ProjectTypes.REMOVE_CANDIDATE_FIELD_REQUEST, params);
export const updateCandidateField = (params) => actionCreator(ProjectTypes.UPDATE_CANDIDATE_FIELD_REQUEST, params);

export const getCandidateStatuses = (params) => actionCreator(ProjectTypes.GET_CANDIDATE_STATUSES_REQUEST, params);
export const acceptRecruiter = (params) => actionCreator(ProjectTypes.ACCEPT_RECRUITER_REQUEST, params);
export const getCandidateActivities = (params) => actionCreator(ProjectTypes.GET_CANDIDATE_ACTIVITIES_REQUEST, params);
export const getFullComment = (params) => actionCreator(ProjectTypes.GET_FULL_COMMENT_REQUEST, params);
export const postComment = (params) => actionCreator(ProjectTypes.POST_COMMENT_REQUEST, params);
export const sendRecruiterComment = (params) => actionCreator(ProjectTypes.SEND_RECRUITER_COMMENT_REQUEST, params);
export const getRecruiterComments = (params) => actionCreator(ProjectTypes.GET_RECRUITER_COMMENTS_REQUEST, params);
export const getRecruiterProposal = (params) => actionCreator(ProjectTypes.GET_RECRUITER_PROPOSAL_REQUEST, params);
export const postReview = (params) => actionCreator(ProjectTypes.POST_REVIEW_REQUEST, params);
export const finishProject = (params) => actionCreator(ProjectTypes.FINISH_PROJECT_REQUEST, params);


export const getProjectStatuses = (params) => actionCreator(ProjectTypes.GET_PROJECT_STATUSES_REQUEST, params);
export const postProjectComment = (params) => actionCreator(ProjectTypes.POST_PROJECT_COMMENT_REQUEST, params);

export const askProjectPause = createFormAction('ASK_PROJECT_PAUSE');
export const changeRecruiter = createFormAction('CHANGE_RECRUITER');
export const changeProjectStatus = createFormAction('CHANGE_PROJECT_ST');
