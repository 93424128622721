import React, { useEffect } from 'react';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Grid, Button, Avatar } from './../../../components/ui-kit';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { UsersSelectors, UsersActions } from './../../../modules/users';
import routs from '../../../modules/router/routes';
import { PAGES } from '../Customer';

const getColumns = ({ actions, flags, formatMessage }) => {
  return [{
    name: '№',
    selector: 'index',
    width: '50px',
    center: true,
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.COMPANY' }),
    selector: 'company',
    cell: ({ company, id }) => {
      if (!company) {
        return null;
      }

      return (
        <Link to={routs.CUSTOMER_PAGE({ userId: id, mode: 'edit' })} className="Users__avatar">
          <Avatar src={company.logo} label={company.name} size="sm" />
          <div className="Users__avatar-name">{company.name}</div>
        </Link>
      );
    },
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.MANAGER' }),
    selector: 'logo',
    cell: ({ logo, first_name, last_name, id  }) => (
      <Link to={routs.CUSTOMER_PAGE({ userId: id, mode: 'edit' }) + PAGES.account.link} className="Users__avatar">
        <Avatar src={logo} label={`${first_name} ${last_name}`} size="sm" />
        <div className="Users__avatar-name">{first_name} {last_name}</div>
      </Link>
    ),
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.PHONE' }),
    selector: 'phone',
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.EMAIL' }),
    selector: 'email',
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.REG_DATE' }),
    selector: 'registration_date',
    cell: ({ registration_date }) => moment.unix(registration_date).format('DD MMM, YYYY')
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.NUM_OF_PROJECTS' }),
    selector: 'number_of_projects',
    center: true,
  }, {
    name: '',
    selector: 'actions',
    cell: ({ id, status_confirm }) => {
      if (status_confirm === 5) {
        return (
          <Button
            label={formatMessage({ id: 'COMMON.ACTIVATE' })}
            onClick={() => actions.activateUser({ id })}
            color="slim"
            prefix="icon-check"
            loading={flags.activatingId === id}
          />
        );
      }

      return null;
    },
  }];
};

const Customers = ({ users, flags, actions }) => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    actions.init();
    actions.getUsers({ role: 'client', statusConfirm: 10 });
  }, [actions]);

  return (
    <div className="Users">
      <Grid
        rows={users.map((item, i) => ({ ...item, i }))}
        columns={getColumns({ actions, flags, formatMessage })}
        loading={flags.users}
        cardMode="adminClient"
      />
    </div>
  )
};

export default connect(
  state => ({
    users: UsersSelectors.selectUsers(state),
    flags: UsersSelectors.selectFlags(state),
  }),
  dispatch => ({
    actions: bindActionCreators(UsersActions, dispatch),
  }),
)(Customers);
