import { createSelector } from 'reselect';

const getProjectsState = state => state.projects;


export const selectFlags = createSelector(getProjectsState, state => state.flags);

export const selectProjects = createSelector(getProjectsState, state => state.projects);

export const selectProject = createSelector(getProjectsState, state => state.project);

export const selectProjectFields = createSelector(getProjectsState, state => state.fields);

export const selectRecruiters = createSelector(getProjectsState, state => state.recruiters);
export const selectCandidates = createSelector(getProjectsState, state => state.candidates);
export const selectCandidatesSortParams = createSelector(getProjectsState, state => state.candidateSort);
export const selectStopList = createSelector(getProjectsState, state => state.stopList);
export const selectStatuses = createSelector(getProjectsState, state => state.statuses);
export const selectCandidateStatuses = createSelector(getProjectsState, state => state.candidateStatuses);
export const selectCandidate = createSelector(getProjectsState, state => state.candidate);
export const selectCandidateFields = createSelector(getProjectsState, state => state.candidateFields);
export const selectInbox = createSelector(getProjectsState, state => state.inbox);
export const selectFullComment = createSelector(getProjectsState, state => state.fullComment);
export const selectProjectStatuses = createSelector(getProjectsState, state => state.projectStatuses);
export const selectRecruiterComments = createSelector(getProjectsState, state => state.recruiterComments);
export const selectRecruiterProposal = createSelector(getProjectsState, state => state.recruiterProposal);

export const selectComments = createSelector(getProjectsState, state => state.comments);

