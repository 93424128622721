import React from 'react';
import Spinner from '../Spinner';
import {
  Card,
  ProjectCard,
  RecruiterCard,
  CandidateCard,
  InboxCard,
  StatusCard,
  AdminRecruiter,
  AdminClient,
} from './Cards'

const CARD_MAPPER = {
  project: ProjectCard,
  recruiter: RecruiterCard,
  candidate: CandidateCard,
  inbox: InboxCard,
  status: StatusCard,
  adminRecruiter: AdminRecruiter,
  adminClient: AdminClient,
}


export const Stack = ({
  columns,
  rows,
  cardMode,
  extractCardColor,
  appendHeader,
  prependHeader,
  loading,
  makeRef = () => false,
  sort: {
    options = [],
    callBack = () => false,
    enabled = false,
    defaultValue = '',
    forceReset = () => false,
    placeholder
  } = {},

}) => {
  const View = CARD_MAPPER[cardMode];

  return (
    <div className="Stack">
      <div className="Stack__fab">
        {appendHeader}
        {prependHeader}
      </div>
      {Boolean(!loading && rows.length === 0) && <div className="Stack__placeholder">There are no records to display</div>}
      {loading && <Spinner size={30}/>}
      {
        Boolean(!loading && rows.length > 0) && rows.map(row => (
          <Card
            backgroundColor={extractCardColor ? extractCardColor(row) : 'transparent'}
            data={row}
            key={row.id}
            columns={columns}
            cardMode={cardMode}
          >
            {(data) => View ? <View data={data} /> : null}
          </Card>
        ))
      }
    </div>
  )
}
