import * as yup from "yup";

export const PASSWORD_MAX = 20;
export const PASSWORD_MIN = 6;
const AT_LEAST_ONE_LETTER = new RegExp(/[A-Z]{1}/i);
const PASSWORD_REGEXP = new RegExp(/^(?=.)\D*\d/);

export function email() {
  return yup.string().email('EMAIL_INCORRECT').required('REQUIRED');
}

export function repeatPassword(refField) {
  return yup.string()
    .required('ERRORS.REQUIRED')
    .oneOf([yup.ref(refField)], 'ERRORS.PASSWORD2_NOT_MATCHED')
}

export function password() {
  return yup.string()
    .required('ERRORS.REQUIRED')
    .meta({ min: PASSWORD_MIN, max: PASSWORD_MAX })
    .matches(AT_LEAST_ONE_LETTER, 'ERRORS.PASSWORD_ALPHA')
    .matches(PASSWORD_REGEXP, 'ERRORS.PASSWORD_DIGIT')
    .min(PASSWORD_MIN, 'ERRORS.PASSWORD_RANGE')
    .max(PASSWORD_MAX, 'ERRORS.PASSWORD_RANGE')
}

export const Yup = yup;