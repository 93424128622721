// @flow

import React from 'react';
import { UncontrolledTooltip, Tooltip as CoreTooltip } from 'reactstrap';
import cx from 'classnames';
//import tongDownSvg from '../../../assets/images/tong_down.svg';

type Props = {
  children: any,
  controlled?: boolean,
  placement?: string,
  isOpen?: boolean,
  target: any,
  onToggle?: () => void,
}

const Tooltip = ({
  controlled,
  children,
  isOpen,
  placement,
  target,
  onToggle,
}: Props) => {
  const caret = (
    <span className={cx('Tooltip__caret', { [`is-${placement}`]: true })}>
      <img src="" alt="tong" />
    </span>
  );

  if (controlled) {
    return (
      <CoreTooltip
        hideArrow
        className="Tooltip"
        placement={placement}
        isOpen={isOpen}
        target={target}
        toggle={onToggle}
      >
        {caret}
        {children}
      </CoreTooltip>
    );
  }

  return (
    <UncontrolledTooltip
      hideArrow
      placement={placement}
      className="Tooltip"
      target={target}
    >
      {caret}
      {children}
    </UncontrolledTooltip>
  );
};

Tooltip.defaultProps = {
  placement: 'top-start',
  isOpen: false,
  onToggle: () => false,
  controlled: false,
};

export default Tooltip;
