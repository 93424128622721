import React from 'react';
import { Field, reduxForm } from 'redux-form';

import { BASE_FILE_URL } from '../../../../app/API';
import {
  TextField,
  Button,
  Avatar,
  FormUploader, Page,
} from './../../../../components/ui-kit';
import { ACTIVITY_COMMENT_LENGTH } from '../../../../modules/projects/constants';

const Info = ({
  handleSubmit,
  reset,
  submitAction,
  isEdit,
  actions,
  pristine,
  ifView,
  initialValues,
  routProps,
  flags,
  profile = {},
  uploadingAvatar,
  candidate,
  ifAdd,
  formatMessage,
  role,
}) => {
  const isShownPersonalData = role !== 'client'
    || (role === 'client' && candidate && profile.id === candidate.creator.id);

  return (
    <Page>
      <form onSubmit={handleSubmit(submitAction)} className="Profile__section">
        <div className="Profile__wrapper">
          <div className="Profile__aside">
            <div className="Profile__avatar">
              {
                ifView && (
                  <Avatar
                    loading={uploadingAvatar}
                    src={initialValues.logo}
                    label={`${initialValues.first_name} ${initialValues.last_name}`}
                  />
                )
              }
              {
                !ifView && (
                  <Field
                    name="logo"
                    disabled={ifView}
                    component={FormUploader}
                    label={formatMessage({ id: 'FORM.UPLOAD_PICTURE' })}
                    accepts={['image/*']}
                    onUpload={actions.uploadAvatar}
                    id="logo"
                    align="center"
                    deleteLabel={formatMessage({ id: 'FORM.DELETE_PICTURE' })}
                    fileRenderer={(value, Remove) => (
                      <>
                        <Avatar
                          loading={uploadingAvatar}
                          src={value}
                          label={`${initialValues.first_name} ${initialValues.last_name}`}
                        />
                        {Remove}
                      </>
                    )}
                  />
                )
              }
            </div>
          </div>
          <div className="Profile__content">
            <div className="row">
              <div className="form-group col-xs-12 col-md-6">
                <Field
                  type="text"
                  name="surname"
                  disabled={ifView}
                  id="surname"
                  required
                  component={TextField}
                  label={formatMessage({ id: 'FORM.LAST_NAME' })}
                />
              </div>
              <div className="form-group col-xs-12 col-md-6">
                <Field
                  type="text"
                  name="name"
                  id="name"
                  required
                  disabled={ifView}
                  component={TextField}
                  label={formatMessage({ id: 'FORM.FIRST_NAME' })}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-xs-12 col-md-6">
                <Field
                  type="text"
                  name="current_position"
                  disabled={ifView}
                  id="current_position"
                  required
                  component={TextField}
                  label={formatMessage({ id: 'FORM.POSITION' })}
                />
              </div>
              <div className="form-group col-xs-12 col-md-6">
                <Field
                  type="text"
                  name="current_company"
                  disabled={ifView}
                  required
                  id="current_company"
                  component={TextField}
                  label={formatMessage({ id: 'FORM.COMPANY.INDEX' })}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-xs-12 col-md-6">
                <Field
                  type="text"
                  required
                  name="salary_expectations"
                  id="salary_expectations"
                  component={TextField}
                  disabled={ifView}
                  label={formatMessage({ id: 'FORM.SALARY_EXPECTATION' })}
                />
              </div>
              <div className="form-group col-xs-12 col-md-6">
                <br/>
                <br/>
                {
                  !ifView && (
                    <div className="form-group">
                      <Field
                        name="cv"
                        required
                        component={FormUploader}
                        label={formatMessage({ id: 'FORM.UPLOAD_CV' })}
                        accepts={['.pdf']}
                        loading={flags.uploading}
                        onUpload={actions.uploadFile}
                        fileRenderer={(value, Remove) => value && (
                          <div className="Profile__file-preview">
                            <a
                              href={`${BASE_FILE_URL}${value}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="Profile__link"
                            >
                              {formatMessage({ id: 'COMMON.CV' })}
                            </a>
                            {Remove}
                          </div>
                        )}
                        id="cv"
                      />
                    </div>
                  )
                }
                {
                  ifView && (
                    <div className="Profile__file-preview">
                      <a
                        href={`${BASE_FILE_URL}${initialValues.cv}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="Profile__link"
                      >
                        {formatMessage({ id: 'COMMON.CV' })}
                      </a>
                    </div>
                  )
                }
              </div>
            </div>
            <div className="form-group">
              <Field
                type="textarea"
                name="comments"
                required
                minRows={3}
                maxRows={10}
                disabled={ifView}
                maxLength={ACTIVITY_COMMENT_LENGTH}
                id="comments"
                component={TextField}
                label={formatMessage({ id: 'FORM.RECRUITER_COMMENT' })}
              />
            </div>
          </div>
        </div>
        <div>
          {
            (isShownPersonalData || ifAdd)  && (
              <div className="row">
                <div className="col-xs-12 col-md-7">
                  <div className="form-group">
                    <Field
                      type="email"
                      name="email"
                      id="email"
                      disabled={ifView}
                      component={TextField}
                      label={formatMessage({ id: 'FORM.E_MAIL' })}
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      type="tel"
                      name="phone_number"
                      disabled={ifView}
                      id="phone_number"
                      component={TextField}
                      label={formatMessage({ id: 'FORM.PHONE_NUMBER' })}
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      type="text"
                      disabled={ifView}
                      name="linkedin"
                      id="linkedin"
                      component={TextField}
                      label={formatMessage({ id: 'FORM.LINKEDIN_URL' })}
                    />
                  </div>
                </div>
                <div className="col-xs-12 col-md-5">
                  <div className="Projects__inform">
                    <div className="Projects__inform-inner">
                      <span className="icon-info" />
                      <p>{formatMessage({ id: 'CANDIDATES.SECURITY_FIELDS_HINT' })}</p>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          {
            !ifView && (
              <div className="form-footer lg-static">
                <div className="form-footer__cell">
                  <Button
                    disabled={pristine}
                    label={formatMessage({ id: 'COMMON.CANCEL' })}
                    onClick={reset}
                    prefix="icon-close2"
                    color="secondary"
                  />
                </div>
                <div className="form-footer__cell">
                  {
                    isEdit && (
                      <Button
                        type="submit"
                        prefix="icon-trash"
                        label={formatMessage({ id: 'COMMON.DELETE' })}
                        onClick={() => actions.deleteCandidate(routProps)}
                        loading={flags.deletingCandidate}
                      />
                    )
                  }
                  <Button
                    type="submit"
                    prefix="icon-save"
                    label={formatMessage({ id: 'COMMON.SAVE' })}
                    loading={flags.candidateUpd}
                    disabled={flags.uploading || pristine}
                  />
                </div>
              </div>
            )
          }
        </div>
      </form>
    </Page>
  );
};

export default reduxForm({
  form: 'add-candidate',
  enableReinitialize: true,
  destroyOnUnmount: false,
})(Info)
