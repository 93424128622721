import axios from 'axios';
import {type, isEmpty, isNil} from 'ramda';

export const BASE_API_URL = process.env.REACT_APP_API_HOST;
export const BASE_FILE_URL = process.env.REACT_APP_FILE_URL;

export const METHOD = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
  DELETE: 'delete',
  PATCH: 'patch',
};

export default class API {
  static AUTH = {
    RESET: {
      REQUEST: '/v1/users/recovery/request',
      CHECK: (token = '') => `/v1/users/recovery/check/${token}`,
      CONFIRM: '/v1/users/recovery/confirm',
    },

    SIGN_IN: '/v1/users/login',

    SIGN_UP: {
      REQUEST: (userType = '') => `/v1/users/${userType}/signup`,
      CONFIRM: (token = '') => `/v1/users/confirm/${token}`,
    },

    SIGN_OUT: '/v1/users/logout',

    CHECK_ACCESS: '/v1/users/check',
  };

  static PROFILE = {
    ROOT: '/v1/users/profile',
    USER_INFO: (id) => `/v1/users/${id}`,
    USERS: '/v1/users',
    EMAIL: '/v1/users/change/email',
    PASSWORD: '/v1/users/change/password',
    COMPANY: (companyId) => `/v1/companies/${companyId}`,
  };

  static UPLOAD_FILE = '/v1/upload/file';

  static PROJECTS = {
    LEAVE_REVIEW: '/v1/reviews',
    GET_RECRUITER_PROPOSAL: ({ projectId, recruiterId }) => {
      const recruiter = !!recruiterId ? `/${recruiterId}` : '';

      return `/v1/projects/recruiters/proposal/${projectId}${recruiter}`
    },
    GET_RECRUITER_COMMENTS: (recruiterId) => `/v1/projects/recruiters/comment/${recruiterId}`,
    SEND_COMMENT: (projectId, recruiterId) => `/v1/projects/recruiters/comment/${projectId}/${recruiterId}`,

    COMMENTS: {
      RECRUITER: (projectId, recruiterId) => `/v1/projects/recruiters/comment/${projectId}/${recruiterId}`,
    },

    PROJECT_STATUSES: {
      LIST: '/v1/projects-status',
      POST: '/v1/projects/comment',
      VISIBLE: projectId => `/v1/projects-status/${projectId}`,
    },

    CORE: '/v1/projects',
    PROJECT: (projectId) => `/v1/projects/${projectId}`,
    CLOSE_PROJECT: (projectId) => `/v1/projects/close/${projectId}`,

    STATUSES: '/v1/projects/statuses',
    FIELD: (fieldId) => `/v1/projects-fields${fieldId ? `/${fieldId}` : ''}`,
    CANDIDATES: '/v1/candidates',
    RECRUITERS: (projectId) => `/v1/projects/recruiters/${projectId}`,
    CANDIDATE: (userId) => `/v1/candidates${userId ? `/${userId}` : ''}`,
    RECRUITER: (userId) => `/v1/recruiters${userId ? `/${userId}` : ''}`,
    CANDIDATES_STATUSES: '/v1/candidates/statuses',
    CANDIDATES_STATUS: (candidateId) => `/v1/candidates/status/${candidateId}`,
    CANDIDATE_FIELDS: {
      LIST: '/v1/candidate-questions',
      FIELD: (id) => `/v1/candidate-questions${id ? `/${id}` : ''}`,
      FULL_LIST: (candidateId) => `/v1/candidate-questions/lists/${candidateId}`,
    },
    CANDIDATE_FEED: '/v1/comments',
    CONFIRM: {
      RECRUITER: ({ projectId, recruiterId }) => `/v1/projects/recruiters/${projectId}/${recruiterId}`,
    },
    PAUSE_REQUEST: (projectId) => `/v1/projects/pause/${projectId}`,

    CHANGE_RECRUITER: (projectId) => `/v1/projects/recruiters/change/${projectId}`,

    CHOSE_RECRUITER: (projectId, userId) => `/v1/projects/recruiters/change/${projectId}/${userId}`
  };


  static UPLOAD_IMAGE = '/v1/upload/image';

  static UPLOAD_ATTACHMENT = '/v1/upload/attachment';

  static USERS = {
    USER: (userId) => `/v1/users/${userId}`,
    GET: '/v1/users',
    STATUSES: '/v1/users/statuses',
    ACTIVATE: (userId) => `/v1/users/activate/${userId}`,
  }
}

function normalizeError(errors) {
  const newErrors = {};

  if (!isEmpty(errors) && !isNil(errors) && type(errors) === 'Array') {
    errors.forEach(({ field, message }) => {
      if (message === 'INCORRECT_EMAIL_OR_PASSWORD'
        || message === 'STATUS_INCORRECT'
        || message === 'NOT_CONFIRMED_REGISTRATION'
      ) {
        newErrors._error = 'ERRORS.' + message;
      } else if (message === 'STATUS_INCORRECT') {
        newErrors._error = 'ERRORS.' +  'YOU_CANNOT_APLY_THIS_STATUS';
      } else {
        newErrors[field] = message;
      }
    });
  }

  return newErrors;
}

export function fetchResource({
  url = '',
  data = {},
  params = {},
  method,
  headers = {},
  token,
  contentType = 'application/json',
  baseURL = BASE_API_URL,
}) {
  if (token) {
    axios.defaults.headers.common.Authorization = token;
  }

  return axios({
    baseURL,
    headers: {
      'Content-Type': contentType,
      ...headers,
    },
    data,
    method,
    params,
    url,
  })
    .then(function (res) {
      if (res.status >= 200 && res.status <= 300) {
        return {
          data: res.data || {},
          errors: null,
        };
      }
      return {
        errors: normalizeError(res.data),
        data: null,
      };
    })
    .catch(function (error) {
      return {
        errors: normalizeError(error.response ? error.response.data : {}),
        data: null,
      }
    });
}

export function withToken(params) {
  const token = localStorage.getItem('token');

  if (token) {
    return fetchResource({ ...params, token });
  }
  return {};
}

export function upload({ FormData, url }) {
  return fetchResource({
    url,
    data: FormData,
    method: METHOD.PUT,
    token: localStorage.getItem('token'),
    contentType: 'multipart/form-data',
  });
}
