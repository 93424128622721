import React from 'react';
import RatingCore from 'react-rating';
import './styles.sass';

const Rating = ({
  size = 13,
  onChange,
  isDisable,
  value,
  displayValue,
  count,
}) => (
  <div className="Rating" style={{ fontSize: size }}>
    <RatingCore
      readonly={isDisable}
      placeholderRating={value}
      stop={5}
      onChange={onChange}
      emptySymbol={<i className="icon-star" />}
      placeholderSymbol={<i className="icon-star is-selected" />}
      fullSymbol={<i className="icon-star is-full" />}
    />
    {displayValue && (
      <div className="Rating__value" style={{ marginLeft: size }}>
        {value}
      </div>
    )}
    {Boolean(count === 0 || !!count) && (
      <div className="Rating__value is-pale"> / {count}</div>
    )}
  </div>
);


export default Rating;
