import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  TextField,
  Button,
  Avatar,
  FormUploader
} from './../../../components/ui-kit';
import { UsersActions } from './../../../modules/users';

const Company = ({
  handleSubmit,
  loading,
  error,
  flags,
  initialValues,
  actions,
  readOnly,
  formatMessage
}) => (
  <form onSubmit={handleSubmit(UsersActions.updateUser)}>
    <div className="Profile__wrapper">
      <div className="Profile__aside">
        <div className="Profile__avatar">
          {
            readOnly && (
              <Avatar
                loading={flags.uploadAvatar}
                src={initialValues.logo}
                label={initialValues.name}
              />
            )
          }
          {
            !readOnly && (
              <Field
                name="logo"
                disabled={readOnly}
                component={FormUploader}
                label={formatMessage({ id: 'FORM.COMPANY.UPLOAD_PICTURE' })}
                accepts={['image/*']}
                onUpload={actions.uploadCompanyLogo}
                id="logo"
                align="center"
                deleteLabel={formatMessage({ id: 'FORM.COMPANY.DELETE_PICTURE' })}
                fileRenderer={(value, Remove) => (
                  <>
                    <Avatar
                      loading={flags.uploadAvatar}
                      src={value}
                      label={initialValues.name}
                    />
                    {Remove}
                  </>
                )}
              />
            )
          }
        </div>
      </div>
      <div className="Profile__content">
        <div className="form-group">
          <Field
            name="name"
            id="name"
            required
            disabled={readOnly}
            component={TextField}
            label={formatMessage({ id: 'FORM.COMPANY.NAME' })}
          />
        </div>
        <div className="form-group">
          <Field
            name="website"
            id="website"
            required
            component={TextField}
            disabled={readOnly}
            label={formatMessage({ id: 'FORM.COMPANY.WEBSITE' })}
          />
        </div>
        <div className="form-group">
          <Field
            name="industry"
            id="industry"
            required
            disabled={readOnly}
            component={TextField}
            label={formatMessage({ id: 'FORM.COMPANY.INDUSTRY' })}
          />
        </div>
        <div className="form-group">
          <Field
            type="textarea"
            name="description"
            id="description"
            disabled={readOnly}
            component={TextField}
            label={formatMessage({ id: 'FORM.COMPANY.DESCRIPTION' })}
            minRows={3}
            maxRows={10}
          />
        </div>
        <div className="form-group">
          <Field
            type="textarea"
            name="competitors"
            id="competitors"
            disabled={readOnly}
            component={TextField}
            label={formatMessage({ id: 'FORM.COMPANY.COMPETITORS' })}
            minRows={3}
            maxRows={10}
          />
        </div>
        {
          !readOnly && (
            <div className="form-group">
              <Button
                type="submit"
                label={formatMessage({ id: 'COMMON.SAVE' })}
                loading={loading}
                color="primary"
                rounded
              />
            </div>
          )
        }
      </div>
    </div>
    {
      error && (
        <div className="Auth__error">{formatMessage({ id: error })}</div>
      )
    }
  </form>
);

export default reduxForm({
  form: 'update-customer-company',
  destroyOnUnmount: false,
  enableReinitialize: true,
})(Company);
