import React, { useEffect, useMemo } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Nav, Spinner } from './../../../../components/ui-kit';

import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import routs from '../../../../modules/router/routes';
import { ProjectActions, ProjectSelectors } from './../../../../modules/projects';
import { ProfileSelectors } from './../../../../modules/profile';
import Description from './Description';
import Questionnaire from './Questionnaire';
import cx from 'classnames';
import StopList from './StopList';
import Statuses from './Statuses';
import StopCandidate from '../StopCandidate';
import CandidatesRoot from './CandidatesRoot';
import RecruitersRoot from './RecruitersRoot';
import Client from './Client';
import { useIntl } from 'react-intl';
import mapNav from '../../../../utils/mapNav';
import {useAuthContext} from '../../../../hooks';

export const TABS = {
  description: {
    link: '/description',
    text: { id: 'NAV.PROJECT.DESCRIPTION' },
  },
  questionnaire: {
    link: '/questionnaire',
    text: { id: 'NAV.PROJECT.QUEST' },
  },
  stopList: {
    link: '/stop-list',
    text: { id: 'NAV.PROJECT.STOP_LIST' },
  },
  recruiters: {
    link: '/recruiters',
    text: { id: 'NAV.PROJECT.RECRUITERS' },
    onlyEdit: true,
  },
  candidates: {
    link: '/candidates',
    text: { id: 'NAV.PROJECT.CANDIDATES' },
    onlyEdit: true,
  },
  status: {
    link: '/status',
    text: { id: 'NAV.PROJECT.STATUS' },
    onlyEdit: true,
  },
};

const hideUnavailableRouts = (src, {isRecruiter, ifAdd, project }) => {
  return src.filter(({ key }) => {
    const recruiterCondition = (isRecruiter && key === 'recruiters');
    const activationCondition = (!project || project.status === 2) && (key === 'recruiters' || key === 'candidates');

    const resultCondition = recruiterCondition || (ifAdd && key !== 'description') || activationCondition;

    return !resultCondition;
  });
};

function getDescriptionDefaultValue(project, ifAdd, periods, format) {
  const staticValues = {
    payment_term: project && project.payment_term
      ? project.payment_term
      : format({id: 'FORM.PROJECT.PAYMENT_TERMS_PLACEHOLDER'}),
  }

  if (project && !ifAdd) {
    return {
      ...project,
      ...staticValues,
      guaranteed_period: periods.find(item => +item.value === +project.guaranteed_period)
    };
  } else {
    return staticValues;
  }
}

const Edit = ({
  flags,
  location,
  match: {
    params,
    url,
  },
  project,
  actions,
  fields,
  history,
  candidates,
  statuses,
  profile,
  profileFlags,
  comments,
}) => {
  const {profile: {role}} = useAuthContext();
  const isEdit = params && params.mode && params.mode === 'edit';
  const hasId = params && params.projectId;
  const isRecruiter = role === 'recruiter';
  const isClient = role === 'client';
  const ifView = (hasId && !isEdit);
  const ifEdit = (hasId && isEdit);
  const ifAdd = (!hasId && !isEdit);
  const isUserNotConfirmed = !profile || profile.status_confirm === 0;
  const isActive = project && project.status !== 2;
  const isVisibleUsers = (!ifAdd && isActive);
  const { formatMessage } = useIntl();


  const periods = useMemo(() => {
    return Object.entries(statuses.guaranteed_period).map(([value, label]) => ({
      value,
      label: label.replace(/\D/g,'')
    }))
  }, [statuses.guaranteed_period]);

  useEffect(() => {
    actions.init();
    if (params.projectId) {
      actions.getProject({ projectId: params.projectId });
    }
  }, [params.projectId]);

  const enhancedTabs = useMemo(() => {
    return mapNav(Object.entries(TABS), url, formatMessage);
  }, [formatMessage, url]);

  if (flags.projectGet || profileFlags.profile) {
    return (
      <Spinner size={30} />
    )
  }

  return (
    <div
      className={cx('Projects', {
        'hide-nav': location.pathname.match('client'),
        'has-bottom-offset': location.pathname.match('/description') && isClient
      })}
    >
      <BreadcrumbsItem to={url}>
        {Boolean((ifEdit || ifView) && project) && project.position}
        {ifAdd && formatMessage({ id: 'PROJECTS.CREATE_PROJECT' })}
      </BreadcrumbsItem>
      {
        (!location.pathname.match('candidates/')
          && !location.pathname.match('add-candidate')
          && !location.pathname.match('client')
        ) && (
          <Nav list={hideUnavailableRouts(enhancedTabs, { isRecruiter, ifAdd, project })}/>
        )
      }
      <Switch>
        <Route
          path={`${url}${TABS.description.link}`}
          render={() => (
            <>
            <BreadcrumbsItem to={`${url}${TABS.description.link}`}>
              {formatMessage(TABS.description.text)}
            </BreadcrumbsItem>
            <Description
              history={history}
              fields={fields}
              project={project}
              projectId={params.projectId}
              actions={actions}
              flags={flags}
              readOnly={ifView || isRecruiter || (project && project.status === 3)}
              periods={periods}
              isActive={isVisibleUsers}
              rootLink={url}
              statuses={statuses}
              initialValues={getDescriptionDefaultValue(project, ifAdd, periods, formatMessage)}
              isEdit={isEdit}
              submitAction={(project && isEdit) ? ProjectActions.updateProject : ProjectActions.addProject}
              formatMessage={formatMessage}
            />
            </>
          )}
        />
        {
          !ifAdd && (
            <Route
              path={`${url}${TABS.questionnaire.link}`}
              render={() => (
                <>
                <BreadcrumbsItem to={`${url}${TABS.questionnaire.link}`}>
                  {formatMessage(TABS.questionnaire.text)}
                </BreadcrumbsItem>
                <Questionnaire
                  fields={fields}
                  project={project}
                  projectId={params.projectId}
                  actions={actions}
                  isUserNotConfirmed={isUserNotConfirmed}
                  flags={flags}
                  isRecruiter={isRecruiter}
                  isActive={isVisibleUsers}
                  rootLink={url}
                  readOnly={ifView || isRecruiter || (project && project.status === 3)}
                  history={history}
                  isEdit={isEdit}
                  formatMessage={formatMessage}
                />
                </>
              )}
            />
          )
        }
        {
          !ifAdd && (
            <Route
              path={`${url}${TABS.stopList.link}`}
              exact
              render={() => (
                <>
                <BreadcrumbsItem to={`${url}${TABS.stopList.link}`}>
                  {formatMessage(TABS.stopList.text)}
                </BreadcrumbsItem>
                <StopList
                  project={project}
                  projectId={params.projectId}
                  actions={actions}
                  isActive={isVisibleUsers}
                  isUserNotConfirmed={isUserNotConfirmed}
                  flags={flags}
                  rootLink={url}
                  readOnly={ifView || (project && project.status === 3)}
                  history={history}
                  isEdit={isEdit}
                  isRecruiter={isRecruiter}
                  candidates={candidates}
                  formatMessage={formatMessage}
                />
                </>
              )}
            />
          )
        }
        <Route path={`${routs.PROJECT()}${routs.PROJECT_CLIENT()}`} component={Client}/>
        <Route path={`${routs.PROJECT()}${TABS.candidates.link}`} component={CandidatesRoot}/>
        <Route path={`${routs.PROJECT()}${TABS.recruiters.link}`} component={RecruitersRoot} />
        <Route
          path={`${routs.PROJECT()}${TABS.status.link}`}
          render={(routProps) => (
            <Statuses
              actions={actions}
              formatMessage={formatMessage}
              comments={comments}
              matchParams={params}
              flags={flags}
              readOnly={ifView}
              routProps={routProps}
            />
          )}
        />

        <Route path={`${routs.PROJECT()}${routs.ADD_TO_STOP_LIST}`} component={StopCandidate} />
        <Route path={`${routs.PROJECT()}${routs.STOP_LIST_CANDIDATE()}`} component={StopCandidate} />

        <Redirect from={routs.PROJECT()} to={`${url}${TABS.description.link}`} exact />
        <Redirect from={routs.ADD_PROJECT} to={`${url}${TABS.description.link}`} exact />
      </Switch>
    </div>
  )
};

export default withRouter(
  connect(
    state => ({
      project: ProjectSelectors.selectProject(state),
      fields: ProjectSelectors.selectProjectFields(state),
      flags: ProjectSelectors.selectFlags(state),
      candidates: ProjectSelectors.selectCandidates(state),
      comments: ProjectSelectors.selectComments(state),

      recruiters: ProjectSelectors.selectRecruiters(state),
      statuses: ProjectSelectors.selectStatuses(state),
      profile: ProfileSelectors.selectProfile(state),
      profileFlags: ProfileSelectors.selectFlags(state),
      candidateStatuses: ProjectSelectors.selectCandidateStatuses(state),
      fullComment: ProjectSelectors.selectFullComment(state),
    }),
    dispatch => ({
      actions: bindActionCreators(ProjectActions, dispatch),
    }),
  )(Edit),
);
