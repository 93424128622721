import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Grid, Button, Avatar } from './../../../components/ui-kit';
import { connect } from 'react-redux';
import moment from 'moment';
import routs from '../../../modules/router/routes';
import { UsersSelectors, UsersActions } from './../../../modules/users';
import { useIntl } from 'react-intl';
import {useAuthContext} from '../../../hooks';

const getColumns = ({ flags, actions, role, formatMessage }) => {
  return [{
    name: '№',
    selector: 'index',
    width: '50px',
    center: true,
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.RECRUITER' }),
    selector: 'logo',
    cell: ({ logo, first_name, last_name, id  }) => (
      <Link to={routs.RECRUITER_PAGE({ userId: id, mode: 'edit' })} className="Users__avatar">
        <Avatar src={logo} label={`${first_name} ${last_name}`} size="sm" />
        <div className="Users__avatar-name">{first_name} {last_name}</div>
      </Link>
    ),
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.PHONE' }),
    selector: 'phone',
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.EMAIL' }),
    selector: 'email',
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.REG_DATE' }),
    selector: 'registration_date',
    format: ({ registration_date }) => moment.unix(registration_date).format('DD MMM, YYYY')
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.NUM_OF_PROJECTS' }),
    selector: 'number_of_projects',
  }, {
    name: '',
    selector: 'actions',
    cell: ({ id, status_confirm }) => {
      if (status_confirm === 5) {
        return (
          <Button
            label={formatMessage({ id: 'COMMON.ACTIVATE' })}
            onClick={() => actions.activateUser({ id })}
            color="slim"
            prefix="icon-check"
            loading={flags.activatingId === id}
          />
        )
      }

      return null;
    },
  }];
};

const List = ({
  users,
  flags,
  actions,
}) => {
  const {profile: {role}} = useAuthContext();
  useEffect(() => {
    actions.init();
    actions.getUsers({
      role: 'recruiter',
      statusConfirm: 10,
    });
  }, []);
  const { formatMessage } = useIntl();

  return (
    <div className="Users">
      <Grid
        rows={users}
        columns={getColumns({ actions, flags, role, formatMessage })}
        loading={flags.users}
        cardMode="adminRecruiter"
      />
    </div>
  )
};

export default connect(
  state => ({
    users: UsersSelectors.selectUsers(state),
    flags: UsersSelectors.selectFlags(state),
  }),
  dispatch => ({
    actions: bindActionCreators(UsersActions, dispatch),
  }),
)(List);
