import { put, call, select } from 'redux-saga/effects';
import { UsersActions, UsersTypes, UsersSelectors } from '../index';
import API, { METHOD, withToken } from '../../../app/API';
import actionCreator from '../../../utils/actionCreator';

export function* init() {
  yield put(UsersActions.getStatuses());
}

export function* getUsers({ payload: { role } }) {
  try {
    const { data } = yield call(withToken, {
      url: API.USERS.GET,
      method: METHOD.GET,
      params: {
        role,
      }
    });

    yield put(actionCreator(UsersTypes.GET_USERS_SUCCESS, { data }));
  } catch (e) {
    yield put(actionCreator(UsersTypes.GET_USERS_ERROR, { e }));
  }
}

export function* getStatuses() {
  try {
    const { data } = yield call(withToken, {
      url: API.USERS.STATUSES,
      method: METHOD.GET
    });

    yield put(actionCreator(UsersTypes.GET_STATUSES_SUCCESS, { data }));
  } catch (e) {
    yield put(actionCreator())
  }
}

export function* activateUser({ payload: { id } }) {
  try {
    const users = yield select(UsersSelectors.selectUsers);

    yield call(withToken, {
      url: API.USERS.ACTIVATE(id),
      method: METHOD.PUT,
    });

    yield put(actionCreator(UsersTypes.ACTIVATE_USER_SUCCESS, {
      data: users.map(user => {
        if (user.id === id) {
          return {
            ...user,
            status_confirm: 10,
          };
        }

        return user;
      })
    }));
  } catch(e) {
    yield put(actionCreator(UsersTypes.ACTIVATE_USER_ERROR, { e }));
  }
}

export function* getUser({ payload: { userId } }) {
  try {
    const { data } = yield call(withToken, {
      url: API.USERS.USER(userId),
      method: METHOD.GET,
    });

    yield put(actionCreator(UsersTypes.GET_USER_SUCCESS, { data }));
  } catch(e) {
    yield put(actionCreator(UsersTypes.GET_USER_ERROR, { e }));
  }
}


export function* uploadUserLogo({ payload: { FormData, callBack } }) {
  try {
    const { data } = yield call(withToken, {
      url: API.UPLOAD_IMAGE,
      data: FormData,
      method: METHOD.POST,
    });

    yield put(actionCreator(UsersTypes.UPLOAD_USER_LOGO_SUCCESS, data));
    if (callBack) {
      callBack(data);
    }
  } catch (e) {
    yield put(actionCreator(UsersTypes.UPLOAD_USER_LOGO_ERROR));
  }
}

export function* uploadCompanyLogo({ payload: { FormData, callBack } }) {
  try {
    const { data } = yield call(withToken, {
      url: API.UPLOAD_IMAGE,
      data: FormData,
      method: METHOD.POST,
    });

    yield put(actionCreator(UsersTypes.UPLOAD_COMPANY_LOGO_SUCCESS, data));
    if (callBack) {
      callBack(data);
    }
  } catch (e) {
    yield put(actionCreator(UsersTypes.UPLOAD_COMPANY_LOGO_ERROR));
  }
}
