import React, { useEffect } from 'react';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { connect } from 'react-redux';
import { Nav, Page } from './../../../components/ui-kit';
import routs from '../../../modules/router/routes';
import withBreadCrumb from './../../../HOC/withBreadCrumb';
import { UsersSelectors, UsersActions } from './../../../modules/users';
import Company from './../Company';
import mapNav from '../../../utils/mapNav';
import Account from './../Account';
import { useIntl } from 'react-intl';

export const PAGES = {
  company: {
    page: Company,
    link: '/company',
    text: { id: 'NAV.COMPANY.INDEX' },
  },
  account: {
    page: Account,
    link: '/account',
    text: { id: 'NAV.COMPANY.MANAGER' },
  },
};


const User = ({
  match: {
    params: {
      userId,
    },
    url,
  },
  actions,
  user,
  flags,
}) => {
  const { formatMessage } = useIntl();
  const companyPath = `${url}${PAGES.company.link}`;
  const accountPath = `${url}${PAGES.account.link}`;

  useEffect(() => {
    actions.getUser({ userId });
  }, [userId]);

  if (!user) {
    return null
  }

  return (
    <div className="Users has-nav">
      <BreadcrumbsItem to={url}>
        {user.first_name} {user.last_name}
        {(!user.first_name && !user.last_name) && `#${userId}`}
      </BreadcrumbsItem>
      <Nav list={mapNav(Object.entries(PAGES), url, formatMessage)} />
      <div className="Users__scroll-view">
        <Page>
          {
            flags.userGet && (
              <div>{formatMessage({ id: 'COMMON.LOADING' })}</div>
            )
          }
          <Switch>
            {
              (!flags.user && user.company) && (
                <>
                <Route
                  path={companyPath}
                  component={withBreadCrumb(Company, companyPath, {id: 'NAV.COMPANY.INDEX'}, {
                    initialValues: user.company,
                    flags,
                    actions,
                    readOnly: true,
                    formatMessage
                  })}
                />
                <Route
                  path={accountPath}
                  component={withBreadCrumb(Account, accountPath, {id: 'NAV.COMPANY.ACCOUNT'}, {
                    initialValues: {
                      ...user,
                      firstName: user.first_name,
                      lastName: user.last_name,
                    },
                    readOnly: true,
                    flags,
                    actions,
                    formatMessage
                  })}
                />
                </>
              )
            }
            <Redirect from={routs.CUSTOMER_PAGE()} to={url + PAGES.company.link} />
          </Switch>
        </Page>
      </div>
    </div>
  )
};

export default withRouter(
  connect(
    state => ({
      user: UsersSelectors.selectUser(state),
      flags: UsersSelectors.selectFlags(state),
    }),
    dispatch => ({
      actions: bindActionCreators(UsersActions, dispatch),
    }),
  )(User),
);
