import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useIntl } from 'react-intl';

export default function withBreadCrumb(WrappedComponent, link, crumb, contextProps) {
  return (props) => {
    const { formatMessage } = useIntl();
    const title = crumb && crumb.id ? formatMessage(crumb) : crumb

    return (
      <>
        <BreadcrumbsItem to={link}>{title}</BreadcrumbsItem>
        <WrappedComponent {...contextProps} {...props}  />
      </>
    )
  }
}