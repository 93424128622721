import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { Breadcrumbs } from 'react-breadcrumbs-dynamic';
import { NavLink, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {useDispatch, useSelector} from "react-redux";
import Sidebar from "react-sidebar";
import { Button, Avatar, Dropdown, Logo } from './../../../components/ui-kit';
import routs from '../../../modules/router/routes';
import './style.sass';
import { useAuthContext, useResizeContext } from '../../../hooks';
import {IntlActions, IntlSelectors} from "../../../modules/intl";
import {localeOptions} from "../../../modules/intl/i18n";
import {ProfileSelectors} from '../../../modules/profile';

const mql = window.matchMedia(`(min-width: 768px)`);

export const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const profile = useSelector(ProfileSelectors.selectProfile);
  const localeValue = useSelector(IntlSelectors.selectCurrentLocale);
  const locale = useMemo(() => {
    return localeOptions.find(({ value }) => localeValue === value)
  }, [localeValue]);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const auth = useAuthContext();
  const {pathname} = useLocation();
  const {width, bp} = useResizeContext();
  const [isOpenSidebar, setIsOpenSidebar] = useState(true);
  const [isDocked, setIsDocked] = useState(mql.matches);

  const mediaQueryChanged = useCallback(() => {
    setIsDocked(mql.matches);
    setIsOpenSidebar(false);
  }, []);

  const handleChangeLocale = useCallback((value) => {
    dispatch(IntlActions.changeLocale(value));
  }, []);


  useEffect(() => {
    mql.addListener(mediaQueryChanged);

    return () => {
      mql.removeListener(mediaQueryChanged);
    }
  }, []);

  const handleLogOut = useCallback(async () => {
    setIsLoggingOut(true);
    await auth.signOut();
    setIsLoggingOut(false);
  }, []);

  useEffect(() => {
    setIsOpenSidebar(false);
  }, [pathname])

  const langSwitcher = useMemo(() => (
    <div className="Layout__langs">
      <Dropdown
        options={localeOptions.filter((currLoc) => currLoc !== locale)}
        input={{
          value: locale,
          onChange: handleChangeLocale
        }}
      />
    </div>
  ), [locale, handleChangeLocale]);

  return (
    <div className="Layout">
      <Sidebar
        docked={isDocked}
        sidebar={(
          <aside className="Layout__aside">
            <header>
              <div className="Layout__logo">
                {
                  !mql.matches && (
                    <div
                      className="Layout__burger"
                      onClick={() => setIsOpenSidebar(false)}
                    >
                      <div className="icon-burger" />
                    </div>
                  )
                }
                <NavLink to={routs.HOME}>
                  <Logo size={mql.matches ? 130 : 110}/>
                </NavLink>
              </div>
            </header>
            <div className="Layout__aside-wrapper">
              <nav className="Layout__nav">
                <ul>
                  {
                    getAsideNav(profile.role).map(({ key, text, path }) => (
                      <li key={key}>
                        <NavLink to={path} activeClassName="is-active">
                          {formatMessage({ id: text })}
                        </NavLink>
                      </li>
                    ))
                  }
                </ul>
                {width < 1280 && (
                  <div className="Layout__aside-lang">{langSwitcher}</div>
                )}
              </nav>
              <footer className="Layout__aside-footer">
                <Button
                  onClick={handleLogOut}
                  label={formatMessage({ id: 'COMMON.LOG_OUT' })}
                  rounded
                  block
                  prefix="icon-exit"
                  color="secondary"
                  isJustify
                  loading={isLoggingOut}
                />
              </footer>
            </div>
          </aside>
        )}
        open={isOpenSidebar}
        onSetOpen={() => setIsOpenSidebar(false)}
        styles={{
          sidebar: {zIndex: 6}
        }}
      >
        <div className="Layout__wrapper">
          {
            !mql.matches && (
              <header className="Layout__header">
                <div
                  className="Layout__burger"
                  onClick={() => setIsOpenSidebar(true)}
                >
                  <div className="icon-burger" />
                </div>
              </header>
            )
          }
          {
            width > bp.LG - 1 && (
              <header className="Layout__header">
                <div className="Layout__crumbs">
                  <Breadcrumbs
                    container="nav"
                    separator={<span>></span>}
                    item={NavLink}
                    finalItem="b"
                  />
                </div>
                <div className="Layout__actions">
                  {langSwitcher}
                  {
                    !!profile && (
                      <div className="Layout__avatar">
                        <Avatar
                          src={profile.logo}
                          label={`${profile.first_name} ${profile.last_name}`}
                        />
                      </div>
                    )
                  }
                </div>
              </header>
            )
          }
          <main className="Layout__main">
            {children}
          </main>
        </div>
      </Sidebar>
    </div>
  )
}

function getAsideNav(role) {
  const COMMON_PAGES = [{
    text: 'NAV.PROJECTS',
    path: routs.PROJECTS__ROOT,
    key: 'PROJECTS',
  }];

  if (role === 'client') {
    COMMON_PAGES.push({
      text: 'NAV.COMPANY.INDEX',
      path: routs.COMPANY,
      key: 'COMPANY',
    })
  }

  COMMON_PAGES.push({
    text: 'NAV.ACCOUNT.INDEX',
    path: routs.PROFILE,
    key: 'PROFILE',
  })

  if (role === 'admin') {
    COMMON_PAGES.push({
      text: 'NAV.RECRUITERS',
      path: routs.RECRUITERS,
      key: "USER_REQ",
    }, {
      text: 'NAV.CUSTOMERS',
      path: routs.CUSTOMERS,
      key: 'USERS_CLIENTS',
    });
  }
  if (role === 'recruiter') {
    COMMON_PAGES.push({
      text: 'NAV.CUSTOMERS',
      path: routs.INBOX,
      key: 'CUSTOMERS',
    });
  }

  return COMMON_PAGES;
}