import React from 'react';
import { bindActionCreators } from 'redux';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { ProjectActions, ProjectSelectors } from './../../../../modules/projects';
import { ProfileSelectors } from './../../../../modules/profile';
import routs from '../../../../modules/router/routes';
import Candidates from './Candidates';
import { useIntl } from 'react-intl';
import Candidate from '../Candidate';
import {useAuthContext} from '../../../../hooks';

import { TABS } from './index'

const CandidatesRoot = ({
  actions,
  flags,
  candidates,
  history,
  fullComment,
  match: {
    params,
    url
  },
  project,
  candidateSort
}) => {
  const {profile: {role}} = useAuthContext();
  const { formatMessage } = useIntl();
  const isEdit = params && params.mode && params.mode === 'edit';
  const hasId = params && params.projectId;
  const isClient = role === 'client';
  const isRecruiter = role === 'recruiter';
  const ifView = (hasId && !isEdit);
  //const ifEdit = (hasId && isEdit);
  const ifAdd = (!hasId && !isEdit);
  const isActive = project && project.status !== 2;
  const isVisibleUsers = (!ifAdd && isActive);

  return (
    <>
    <BreadcrumbsItem to={url}>
      {formatMessage({ id: 'NAV.PROJECT.CANDIDATES' })}
    </BreadcrumbsItem>
    <Switch>
      <Route
        path={routs.PROJECT() + TABS.candidates.link}
        render={() => (
          <Candidates
            project={project}
            projectId={params.projectId}
            actions={actions}
            flags={flags}
            rootLink={url}
            isEdit={isEdit}
            isRecruiter={isRecruiter}
            isActive={isVisibleUsers}
            role={role}
            isArchieved={(project && project.status === 3)}
            readOnly={ifView}
            candidates={candidates}
            history={history}
            isClient={isClient}
            candidateSort={candidateSort}
            fullComment={fullComment}
          />
        )}
        exact
      />
      <Route path={routs.PROJECT() + TABS.candidates.link + routs.ADD_CANDIDATE} component={Candidate} />
      <Route path={routs.PROJECT() + TABS.candidates.link + routs.CANDIDATE()} component={Candidate} />
    </Switch>
    </>
  );
};

export default withRouter(
  connect(
    state => ({
      project: ProjectSelectors.selectProject(state),
      fields: ProjectSelectors.selectProjectFields(state),
      flags: ProjectSelectors.selectFlags(state),
      candidates: ProjectSelectors.selectCandidates(state),
      recruiters: ProjectSelectors.selectRecruiters(state),
      statuses: ProjectSelectors.selectStatuses(state),
      profile: ProfileSelectors.selectProfile(state),
      profileFlags: ProfileSelectors.selectFlags(state),
      candidateStatuses: ProjectSelectors.selectCandidateStatuses(state),
      fullComment: ProjectSelectors.selectFullComment(state),
      candidateSort: ProjectSelectors.selectCandidatesSortParams(state),
    }),
    dispatch => ({
      actions: bindActionCreators(ProjectActions, dispatch),
    }),
  )(CandidatesRoot),
);
