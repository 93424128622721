import React, {useCallback, useEffect, useState} from 'react';

import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {Grid, Button, Avatar} from './../../../components/ui-kit';
import { connect } from 'react-redux';
import moment from 'moment';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import routs from '../../../modules/router/routes';
import { ProjectSelectors, ProjectActions } from './../../../modules/projects';
import { useIntl } from 'react-intl';

import {RecruiterChannel} from './RecruiterChannel'

const getColumns = ({ selectRow, flags, formatMessage }) => {
  return [{
    name: '№',
    selector: 'index',
    width: '50px',
    center: true
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.COMPANY' }),
    selector: 'сompany',
    cell: (row) => {
      if (!row || !row.id || !row.manager) {
        return null
      }

      const { id: projectId, manager: { id: userId, company: { name, logo } } = {} } = row;

      return (
        <Link to={routs.PROJECT({ projectId, mode: 'view' }) + routs.PROJECT_CLIENT({ userId }) + '/company'}>
          <div className="Users__avatar is-interact">
            <Avatar src={logo} label={name} size="sm" />
            <div className="Users__avatar-name">{name}</div>
          </div>
        </Link>
      );
    },
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.PROJECT' }),
    selector: 'position',
    cell: ({ position, id }) => {
      const path = `${routs.PROJECT({ projectId: id, mode: 'view' })}/description`;

      return <Link to={path}>{position}</Link>;
    }
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.SALARY' }),
    selector: 'salary',
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.PUBLISH_DATE' }),
    selector: 'registration_date',
    format: ({ created_date }) => moment.unix(created_date).format('DD MMM, YYYY')
  }, {
    name: '',
    selector: 'actions',
    grow: 1,
    cell: row => (
      <Button
        label={!row.requested ? formatMessage({ id: 'COMMON.RESPOND' }) : formatMessage({ id: 'COMMON.CHANGE_REQUEST' })}
        rounded
        loading={flags.acceptingRecruiterId === row.id}
        color="slim"
        onClick={() => selectRow(row)}
      />
    ),
  }];
};

const Inbox = ({ flags, actions, inbox }) => {
  const { formatMessage } = useIntl();
  const [targetRow, selectRow] = useState(null);

  const handleToggleModal = useCallback(() => {
    selectRow(null);
  }, []);

  useEffect(() => {
    actions.getInbox();
  }, []);

  return (
    <div className="Users">
      {
        targetRow && (
          <RecruiterChannel
            isOpen={Boolean(targetRow)}
            onToggle={handleToggleModal}
            data={targetRow}
          />
        )
      }
      <BreadcrumbsItem to={routs.HOME}>
        {formatMessage({ id: 'NAV.CUSTOMERS' })}
      </BreadcrumbsItem>
      <Grid
        cardMode="inbox"
        rows={inbox}
        columns={getColumns({ selectRow, flags, formatMessage })}
        loading={flags.inbox}
      />
    </div>
  )
};

export default connect(
  state => ({
    inbox: ProjectSelectors.selectInbox(state),
    flags: ProjectSelectors.selectFlags(state),
  }),
  dispatch => ({
    actions: bindActionCreators(ProjectActions, dispatch),
  }),
)(Inbox);
