import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { UncontrolledTooltip } from 'reactstrap';
import { useAuthContext } from '../../../hooks';
import {
  Nav,
  Grid,
  Button,
  Avatar,
  Dropdown,
  Dialog,
  CommentCount,
} from './../../../components/ui-kit';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectSelectors, ProjectActions } from './../../../modules/projects';
import { UsersActions } from './../../../modules/users';
import ProjectStForm from './ProjectStForm';
import { useIntl } from 'react-intl';
import {
  TABS,
  PROJECT_STATUSES,
  STATUS_ACTIONS
} from '../../../modules/projects/constants';
import routs from '../../../modules/router/routes';
import {ReviewRecruiter} from "../Project/ReviewRecruiter";

function filterStatusesByRole(filterType, {
  pause,
  status,
  number_of_hired_people,
  number_of_people,
  recruiter,
  change
}, role) {
  const isActive = filterType === 'active';
  const isClient = role === 'client';
  const isAdmin = role === 'admin';
  const isDraft = filterType === 'draft';
  const isOnPause = status === 4;

  if (isAdmin) {
    const OPTIONS = [{
      value: STATUS_ACTIONS.PAUSE_CONFIRM.key,
      label: { id: 'COMMON.PAUSE' }
    }, {
      value: STATUS_ACTIONS.CHOOSE_RECRUITER.key,
      isDisabled: !change,
      label: { id: 'PROJECTS.CHANGE_RECRUITER' }
    }]

    return [
      ...isActive && !isOnPause ? OPTIONS : [],
      {
        value: STATUS_ACTIONS.FORCE_DELETE.key,
        label: { id: 'COMMON.FORCE_DELETE' }
      }
    ]
  }

  if (isDraft && isClient) {
    return [{
      value: STATUS_ACTIONS.DELETE.key,
      label: { id: 'COMMON.DELETE' }
    }, {
      value: STATUS_ACTIONS.ACTIVATE.key,
      label: { id: 'COMMON.ACTIVATE' }
    }]
  }

  if (isActive && isClient) {
    const actions = [];

    if (isOnPause) {
      actions.push({
        value: STATUS_ACTIONS.RESTORE.key,
        label: { id: 'COMMON.RESUME' }
      });
    } else {
      actions.push({
        value: STATUS_ACTIONS.PAUSE.key,
        isDisabled: pause,
        label: { id: 'COMMON.PAUSE' }
      });
    }
    if (+number_of_hired_people > 0) {
      actions.push({
        value: STATUS_ACTIONS.FINISH.key,
        label: { id: 'COMMON.FINISH' }
      });
    }

    if (recruiter && +number_of_hired_people === 0 && !change) {
      actions.push({
        value: STATUS_ACTIONS.CHANGE_RECRUITER.key,
        label: { id: 'PROJECTS.CHANGE_RECRUITER' }
      });
    }
    return actions;
  }

  return []
}

const getColumns = ({ role, changeStatus, filterType, formatMessage, push }) => {
  const isRecruiter = role === 'recruiter';
  const isAdmin = role === 'admin';
  const isClient = role === 'client';
  const columns = [{
    name: '№',
    selector: 'index',
    width: '50px',
    center: true,
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.JOBS' }),
    selector: 'position',
    cell({ position, id }) {
      const pageMode = isRecruiter ? 'view' : 'edit';
      const path = `${routs.PROJECT({ projectId: id, mode: pageMode })}/description`;

      return <Link to={path}>{position}</Link>;
    }
  }, {
    name: formatMessage({ id: 'PROJECTS.NUMBER_OF_RESUME' }),
    center: true,
    selector: 'number_of_resume',
  }, {
    name: formatMessage({ id: 'NAV.PROJECT.STATUS' }),
    center: true,
    selector: 'number_of_status',
    format(row) {
      return (
        <CommentCount
          isViewed={true}
          unreadCount={row.viewed === 0 ? 1 : 0}
          count={row.number_of_status}
          onClick={() => push(`/projects/${row.id}/edit/status`)}
        />
      )
    }
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.STATUS' }),
    selector: 'status',
    allowOverflow: true,
    width: '160px',
    cell(row) {
      const value = PROJECT_STATUSES[row.status];

      return (
        <div className="Projects__grid-dropdown" style={{ background: value.color }}>
          <Dropdown
            input={{
              value: {
                ...value,
                label: formatMessage({ id: value.label })
              },
              onChange: changeStatus(row),
            }}
            // menuIsOpen
            id={row.id}
            isDisabled={
              role === 'recruiter'
              || filterType === 'archiv'
            }
            options={filterStatusesByRole(filterType, row, role).map(option => ({
              ...option,
              label: formatMessage(option.label)
            }))}
          />
          {!!row.pause && (
            <div className="Projects__tooltip">
              <span id="pauseStatusTip">i</span>
              <UncontrolledTooltip placement="top" target="pauseStatusTip">
                {formatMessage({ id: 'PROJECTS.PAUSED_PROJECT_WAITS_ADMIN_CONFIRMATION' })}
              </UncontrolledTooltip>
            </div>
          )}
          {!!row.change && (
            <div className="Projects__tooltip">
              <span id="changeStatusTip">i</span>
              <UncontrolledTooltip placement="top" target="changeStatusTip">
                {formatMessage({ id: 'PROJECTS.CHANGE_RECRUITER_PENDING_HINT' })}
              </UncontrolledTooltip>
            </div>
          )}
        </div>
      );
    }
  }];

  const recruiterCell = {
    name: formatMessage({ id: 'GRID.COLUMNS.RECRUITER' }),
    selector: 'recruiter',
    cell: ({ recruiter, id }) => {
      if (recruiter) {
        const path = routs.PROJECT_RECRUITER({ userId: recruiter.id, projectId: id });

        return (
          <Link to={path} className="Users__avatar is-interact">
            <Avatar
              src={recruiter.logo}
              label={`${recruiter.first_name} ${recruiter.last_name}`}
              size="sm"
            />
            <div className="Users__avatar-name">
              {recruiter.first_name} {recruiter.last_name}
            </div>
          </Link>
        );
      }

      return (
        <div className="Projects__no-recruiter">
          {formatMessage({ id: 'COMMON.NOT_CHOSEN' })}
        </div>
      );
    },
  };


  if (isAdmin) {
    return [...columns, recruiterCell, {
      name: formatMessage({ id: 'GRID.COLUMNS.COMPANY' }),
      selector: 'company',
      cell: ({ manager }) => manager && manager.company ? manager.company.name : '-',
    }];
  }

  if (isRecruiter) {
    return [...columns,
      {
        name: formatMessage({ id: 'GRID.COLUMNS.MANAGER' }),
        selector: 'manager',
        cell: ({ id: projectId, manager }) => {
          if (!manager) {
            return null
          }

          return (
            <Link
              to={`${routs.PROJECT({ projectId, mode: 'view' })}${routs.PROJECT_CLIENT({ userId: manager.id })}/account`}
              className="Users__avatar is-interact"
            >
              <Avatar src={manager.logo} label={`${manager.first_name} ${manager.last_name}`} size="sm" />
              <div className="Users__avatar-name">{manager.first_name} {manager.last_name}</div>
            </Link>
          )
        },
      }];
  }

  if (isClient) {
    return [...columns, recruiterCell]
  }
};

const hideUnavailableRouts = (source, role, formatMessage) => {
  const isRecruiter = role === 'recruiter';

  return source.reduce((result, [key, target]) => {
    const isVisible = !isRecruiter || (isRecruiter && key !== 'draft');

    if (isVisible) {
      return [...result, {
        key,
        ...target,
        text: formatMessage(target.text)
      }]
    }

    return result;
  }, []);
};

const List = () => {
  const { filterType } = useParams();
  const filter = TABS[filterType];
  const [status, changeStatus] = useState('');
  const [targetProject, chooseProject] = useState({});
  const [isOpenStDialog, openStDialog] = useState(false);
  const { push } = useHistory();
  const { formatMessage } = useIntl();
  const { profile: { role } } = useAuthContext();
  const dispatch = useDispatch();
  const projects = useSelector(ProjectSelectors.selectProjects);
  const flags = useSelector(ProjectSelectors.selectFlags);

  const closeDialog = useCallback(() => {
    openStDialog(false);
    changeStatus('');
    chooseProject({});
  }, []);

  useEffect(() => {
    dispatch(ProjectActions.init());
    if (filter) {
      dispatch(ProjectActions.getProjects({ status: filter.value }));
    }
  }, [filterType]);

  const handleChangeStatus = useCallback((row) => ({ value }) => {
    console.log(row, value, 'CGHANGE');
    if (value === 'CHOOSE_RECRUITER') {
      dispatch(UsersActions.getUsers({ role: 'recruiter' }));
    }
    openStDialog(true);
    chooseProject(row);
    changeStatus(value);
  }, []);

  return (
    <div className="Projects">
      {filter && (
        <BreadcrumbsItem to={routs.PROJECTS()}>{formatMessage(filter.text)}</BreadcrumbsItem>
      )}
      <ReviewRecruiter
        isOpen={status === STATUS_ACTIONS.FINISH.key}
        onOpen={() => {
          closeDialog();
          changeStatus('');
        }}
        onPreSubmit={(callBack) => {
          dispatch(ProjectActions.finishProject({
            project: targetProject,
            filter,
            callBack,
          }));
        }}
        targetUser={targetProject.recruiter}
        targetProjectId={targetProject.id}
      />
      {status !== STATUS_ACTIONS.FINISH.key && (
        <Dialog
          isOpen={Boolean(isOpenStDialog)}
          title={STATUS_ACTIONS[status] ? formatMessage({ id: STATUS_ACTIONS[status].title }) : ''}
          onToggle={closeDialog}
          wrapClassName="Projects__st-dialog"
        >
          {!!status && (
            <ProjectStForm
              onCancel={closeDialog}
              status={status}
              initialValues={{
                project: targetProject,
                status,
                filter,
                recruiter: {},
                role,
              }}
              loading={flags.askPause || flags.projectUpd || flags.deletingProjectId || flags.activation}
            />
          )}
        </Dialog>
      )}
      <Nav list={hideUnavailableRouts(Object.entries(TABS), role, formatMessage)}/>
      <Grid
        rows={projects}
        cardMode="project"
        columns={getColumns({ role, filterType, changeStatus: handleChangeStatus, formatMessage, push })}
        loading={flags.projects}
        prependHeader={role === 'client' && (
          <Button
            label={formatMessage({ id: 'PROJECTS.NEW_PROJECT' })}
            color="slim"
            tag={Link}
            prefix="icon-plus"
            to={routs.ADD_PROJECT + '/description'}
          />
        )}
      />
    </div>
  )
};

export default List;
