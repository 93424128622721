import React, { useMemo } from 'react';
import './Cards.sass';
import cx from 'classnames';
import {useIntl} from 'react-intl';


export const Card = ({columns, data, children, cardMode, backgroundColor = 'transparent'}) => {
  const cardData = useMemo(() => {
    return columns.reduce((result, column) => {
      const targetKey = column.selector;
      const targetCell = data[targetKey];

      if (column.format) {
        return {
          ...result,
          [targetKey]: column.format(data),
        };
      }
      if (column.cell) {
        return {
          ...result,
          [targetKey]: column.cell(data),
        };
      }

      return {
        ...result,
        [targetKey]: targetCell,
      };
    }, {meta: data})
  }, [columns, data])

  return (
    <div className={cx('Card', `is-${cardMode}`)}>
      <div className="Card__wrapper">
        {children(cardData)}
      </div>
    </div>
  );
}

export function ProjectCard({ data }) {
  return (
    <div className="ProjectCard">
      <div className="Card__title">{data.position}</div>
      {data.recruiter}

      <div className="Card__row">
        {data.status}
        <div className="Card__row Card__buttons">
          <div className="Card__icon Card__row">
            <div className="icon-user-g-outlined"/>
            <div className="Card__counter-label">{data.meta.number_of_resume}</div>
          </div>
          <div className="Card__icon">
            {
              data.number_of_resume
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export function RecruiterCard({ data }) {
  const {formatMessage} = useIntl();
  console.log(data)
  return (
    <div className="UserCard">
      <div className="Card__row UserCard__header">
        {data.name}
        {data.comment}
      </div>
      <div className="Card__accent">{formatMessage({ id: 'GRID.COLUMNS.COUNT_OF_CLOSED_PROJECTS' })}: {data.number_of_closed_projects}</div>
      <div className="Card__row">
        {data.status_recruiter}
        {data.actions}
      </div>
      <div className="Card__row Card__rating">
        {data.rating}
      </div>
    </div>
  )
}

export function CandidateCard({ data }) {
  return (
    <div className="UserCard">
      <div className="Card__row UserCard__header">
        {data.name}
        {data.status_count}
      </div>
      <div className="Card__accent">{data.status_candidate}</div>
      {data.status}
    </div>
  )
}

export function InboxCard({ data }) {
  return (
    <div className="ProjectCard">
      <div className="Card__row align-start">
        <div>
          <div className="Card__date">{data.registration_date}</div>
          <div className="Card__title">{data.position}</div>
        </div>
        <div className="ProjectCard__salary">
          {data.salary}
        </div>
      </div>
      <div className="Card__row">
        <div className="ProjectCard__company">{data.сompany}</div>
        {data.actions}
      </div>
    </div>
  )
}


export function StatusCard({ data }) {
  return (
    <div className="UserCard">
      <div className="Card__row UserCard__header">
        {data.manager}
        <div className="UserCard__date">{data.date}</div>
      </div>
      <div className="UserCard__message">
        {data.message}
      </div>
    </div>
  )
}

export function AdminRecruiter({ data }) {
  return (
    <div className="UserCard" style={{minHeight: 170}}>
      <div className="Card__row" style={{marginBottom: 15}}>
        {data.logo}
        <div className="UserCard__date">{data.registration_date}</div>
      </div>
      <div className="Card__accent">{data.email}</div>
      <div className="Card__accent">{data.phone}</div>
      {data.actions}
    </div>
  )
}

export function AdminClient({ data }) {
  return (
    <div className="UserCard" style={{minHeight: 170}}>
      <div className="Card__row" style={{marginBottom: 15}}>
        {data.logo}
        <div className="UserCard__date">{data.registration_date}</div>
      </div>
      <div className="UserCard__company">{data.company}</div>
      <div className="Card__accent">{data.email}</div>
      <div className="Card__accent">{data.phone}</div>
      {data.actions}
    </div>
  )
}