import { createFormAction } from 'redux-form-saga';
import { ProfileTypes } from './index';
import actionCreator from '../../utils/actionCreator';

export const updateCompany = createFormAction('UPDATE_COMPANY');

export const resetEmail = (params) => actionCreator(ProfileTypes.RESET_EMAIL_REQUEST, params);
export const resetPassword = (params) => actionCreator(ProfileTypes.RESET_PASSWORD_REQUEST, params);
export const destroy = () => actionCreator(ProfileTypes.DESTROY);

export const getProfile = () => actionCreator(ProfileTypes.GET_PROFILE_REQUEST);

export const updateProfile = (params) => actionCreator(ProfileTypes.UPDATE_PROFILE_REQUEST, params);

export const getAccountData = (params) => actionCreator(ProfileTypes.GET_ACCOUNT_REQUEST, params);

export const uploadAvatar = (params) => actionCreator(ProfileTypes.UPLOAD_IMAGE_REQUEST, params);

export const uploadFile = (params) => actionCreator(ProfileTypes.UPLOAD_FILE_REQUEST, params);

export const getCompany = (params) => actionCreator(ProfileTypes.GET_COMPANY_REQUEST, params);


