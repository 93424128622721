import { takeLatest } from 'redux-saga/effects';
import { ProfileTypes, ProfileActions } from '../index';
import * as workers from './workers';

export default function* () {
  yield takeLatest(ProfileTypes.GET_PROFILE_REQUEST, workers.getProfile);
  yield takeLatest(ProfileTypes.UPDATE_PROFILE_REQUEST, workers.updateProfile);
  yield takeLatest(ProfileTypes.RESET_PASSWORD_REQUEST, workers.resetPassword);
  yield takeLatest(ProfileTypes.RESET_EMAIL_REQUEST, workers.resetEmail);
  yield takeLatest(ProfileTypes.UPLOAD_FILE_REQUEST, workers.uploadFile);
  yield takeLatest(ProfileTypes.UPLOAD_IMAGE_REQUEST, workers.uploadAvatar);
  yield takeLatest(ProfileActions.updateCompany.REQUEST, workers.updateCompany);
  yield takeLatest(ProfileTypes.GET_COMPANY_REQUEST, workers.getCompany);
}
