import React from 'react';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import './styles.sass';

type Props = {
  list: any,
}

const Nav = ({ list, isHidden }: Props) => (
  <div className="Nav">
    <ul>
      {
        list && list.map(({
          key,
          link,
          text,
          onClick,
          isActive,
          onlyEdit,
        }) => (
          <li key={key}>
            {
              onClick && (
                <div className={cx('Nav__a', { 'is-active': isActive })}>
                  {text}
                </div>
              )
            }
            {
              link && (
                <NavLink to={link} activeClassName="is-active">{text}</NavLink>
              )
            }
          </li>
        ))
      }
    </ul>
  </div>
);

Nav.defaultProps = {
  list: [],
};

export default Nav;
