import React, { useEffect } from 'react';
import type { FormProps } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

import { ProjectActions, ProjectSelectors } from './../../../../modules/projects';
import Info from './Info';

type Props = {
  isEdit: boolean,
  submitAction: () => any,
} & FormProps

const StopCandidate = ({
  match: {
    params,
    url,
  },
  candidate,
  actions,
  ...props
}: Props) => {
  const isEdit = params && params.candidateMode && params.candidateMode === 'edit';
  const hasId = params && params.candidateId;
  const ifView = (hasId && !isEdit);
  const ifEdit = (hasId && isEdit);
  const ifAdd = (!hasId && !isEdit);
  const { formatMessage } = useIntl()
  const readOnly = props.project && props.project.status === 3;

  useEffect(() => {
    if (params && params.candidateId) {
      actions.getCandidate(params);
    }
  }, [actions]);

  return (
    <>
      <BreadcrumbsItem to={url}>
        {
          Boolean((ifEdit || ifView) && candidate) && `${candidate.surname} ${candidate.name}`
        }
        {ifAdd && formatMessage({ id: 'CANDIDATES.NEW_CANDIDATE' })}
      </BreadcrumbsItem>
      <Info
        {...props}
        ifView={ifView || readOnly}
        url={url}
        actions={actions}
        submitAction={ifAdd ? ProjectActions.addCandidate : ProjectActions.updateCandidate}
        routProps={params}
        initialValues={(candidate && (isEdit || ifView)) ? candidate : {
          status_candidate: 7,
        }}
        formatMessage={formatMessage}
      />
    </>
  );
};

export default withRouter(
  connect(
    state => ({
      project: ProjectSelectors.selectProject(state),
      flags: ProjectSelectors.selectFlags(state),
      candidate: ProjectSelectors.selectCandidate(state),
    }),
    dispatch => ({
      actions: bindActionCreators(ProjectActions, dispatch),
    }),
  )(StopCandidate),
);

