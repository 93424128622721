import {useWindowWidth} from '@react-hook/window-size/throttled';

export const BREAKPOINTS = {
  XS: 320,
  S: 450,
  SM: 768,
  EXM: 800,
  M: 1024,
  LG: 1279,
  SLG: 1440,
};

const MEDIA_PAIRS = Object.entries(BREAKPOINTS);
const MEDIA_PAIRS_LENGTH = MEDIA_PAIRS.length;

const MAX_WINDOW_WIDTH = 2560;
const DEBOUNCE_DELAY = 100;
const DEFAULTS = {
  XS: false,
  S: false,
  SM: false,
  M: false,
  EXM: false,
  LG: false,
  SLG: false,
};

function testWidth(width) {
  return MEDIA_PAIRS.reduce((result, [bp, value], index) => {
    const low = index === 0 ? 0 : MEDIA_PAIRS[index - 1][1];
    const high = index === MEDIA_PAIRS_LENGTH - 1 ? MAX_WINDOW_WIDTH : value;

    return {
      ...result,
      [bp]: low < width && width < high,
    };
  }, DEFAULTS);
}

export function useResize(delay = DEBOUNCE_DELAY) {
  const width = useWindowWidth({wait: delay});

  return {
    is: testWidth(width),
    width,
    bp: BREAKPOINTS,
  };
}
