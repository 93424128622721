import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  TextField,
  Button,
  Avatar,
  FormUploader
} from './../../../components/ui-kit';
import { ProfileActions } from '../../../modules/profile';

const Profile = ({
  handleSubmit,
  flags,
  initialValues,
  actions,
  readOnly,
  pristine,
  reset,
  formatMessage,
}) => (
  <form onSubmit={handleSubmit(ProfileActions.updateCompany)}>
    <div className="Profile__wrapper">
      <div className="Profile__aside">
        <div className="Profile__avatar">
          {
            readOnly && (
              <Avatar
                loading={flags.uploadAvatar}
                src={initialValues.logo}
                label={initialValues.name}
              />
            )
          }
          {
            !readOnly && (
              <Field
                name="logo"
                disabled={readOnly}
                component={FormUploader}
                label={formatMessage({ id: 'FORM.UPLOAD_PICTURE' })}
                accepts={['image/*']}
                onUpload={actions.uploadAvatar}
                id="logo"
                align="center"
                deleteLabel={formatMessage({ id: 'FORM.DELETE_PICTURE' })}
                fileRenderer={(value, Remove) => (
                  <>
                    <Avatar
                      loading={flags.uploadAvatar}
                      src={value}
                      label={initialValues.name}
                    />
                    {Remove}
                  </>
                )}
              />
            )
          }
        </div>
      </div>
      <div className="Profile__content">
        <Field
          name="id"
          id="id"
          type="hidden"
          disabled={readOnly}
          component="input"
        />
        <div className="form-group">
          <Field
            name="name"
            id="name"
            required
            disabled={readOnly}
            component={TextField}
            label={formatMessage({ id: 'FORM.COMPANY.NAME' })}
          />
        </div>
        <div className="form-group">
          <Field
            name="website"
            id="website"
            required
            component={TextField}
            disabled={readOnly}
            label={formatMessage({ id: 'FORM.COMPANY.WEBSITE' })}
          />
        </div>
        <div className="form-group">
          <Field
            name="industry"
            id="industry"
            required
            disabled={readOnly}
            component={TextField}
            label={formatMessage({ id: 'FORM.COMPANY.INDUSTRY' })}
          />
        </div>
        <div className="form-group">
          <Field
            type="textarea"
            name="description"
            id="description"
            disabled={readOnly}
            component={TextField}
            minRows={3}
            maxRows={10}
            label={formatMessage({ id: 'FORM.COMPANY.DESCRIPTION' })}
          />
        </div>
        <div className="form-group">
          <Field
            type="textarea"
            name="competitors"
            id="competitors"
            disabled={readOnly}
            component={TextField}
            label={formatMessage({ id: 'FORM.COMPANY.COMPETITORS' })}
            minRows={3}
            maxRows={10}
          />
        </div>
        <div className="form-group">
          <Field
            type="textarea"
            name="benefits"
            id="benefits"
            disabled={readOnly}
            component={TextField}
            label={formatMessage({ id: 'FORM.COMPANY.BENEFITS' })}
            minRows={3}
            maxRows={10}
          />
        </div>
        <div className="form-footer lg-static">
          <div className="form-footer__cell">
            <Button
              disabled={pristine}
              prefix="icon-close2"
              onClick={reset}
              color="secondary"
              label={formatMessage({ id: 'COMMON.CANCEL' })}
            />
          </div>
          <div className="form-footer__cell">
            <Button
              disabled={pristine}
              type="submit"
              prefix="icon-save"
              label={formatMessage({ id: 'COMMON.SAVE' })}
              loading={flags.companyUpd}
              color="primary"
            />
          </div>
        </div>
      </div>
    </div>
  </form>
);

export default reduxForm({
  form: 'update-company',
  destroyOnUnmount: false,
  enableReinitialize: true,
})(Profile);
