import React from 'react';
import * as Components from './Components';
import cx from 'classnames';
import CoreSelect from 'react-select';
import './styles.sass';

const customComponents = {
  Option: Components.Option,
  IndicatorSeparator: Components.Null,
  Group: Components.Group,
  GroupHeading: Components.Null,
  DropdownIndicator: Components.DropdownIndicator,
};

export const Dropdown = ({
  input: {
    value,
    onChange,
    name,
  },
  isDefaultClass,
  ...props
}) => {
  return (
    <CoreSelect
      {...props}
      isSearchable={false}
      className={cx('Dropdown__core', { 'is-default': isDefaultClass })}
      classNamePrefix="Dropdown"
      value={value}
      name={name}
      // menuIsOpen
      onChange={onChange}
      components={customComponents}
    />
  );
};
