import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { CheckAccess } from '../modules/router/CheckAccess';
import ThroughProvider from 'react-through/lib/ThroughProvider';
import LanguageProvider from '../modules/intl/containers/LanguageProvider';
import { Provider as AuthProvider } from '../modules/auth';
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';

export default function({ store, history }) {
  useEffect(() => {
    ReactGA.initialize('GTM-5M3BQXQ', { debug: true });
    hotjar.initialize('2061041');
  }, [])

  return (
    <ThroughProvider>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <LanguageProvider>
            <AuthProvider>
              <CheckAccess />
            </AuthProvider>
          </LanguageProvider>
        </ConnectedRouter>
      </Provider>
    </ThroughProvider>
  );
}
