import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Spinner, Button } from '../../components/ui-kit';
import routs from '../../modules/router/routes';
import { useApi, useAuthContext } from '../../hooks';


export const ConfirmSignUp = () => {
  const { formatMessage } = useIntl();
  const auth = useAuthContext();
  const params = useParams();
  // const queryParams = useMemo(() => params, [params]);
  const {fetching, error} = useApi(auth.confirmSignUp, params, !params || !params.key);

  const message = useMemo(() => {
    if (error && error.token) {
      return formatMessage({ id: `ERRORS.${error.token}` });
    }

    return '';
  }, [error])

  return (
    <div className="Auth__form">
      <form action="">
        {fetching && (
          <>
            <div>{formatMessage({ id: 'AUTH.RESTORE.CONFIRMING' })}</div>
            <br/>
            <Spinner />
          </>
        )}
        {!!message && (
          <div className="Auth__confirm-message">
            {message}
            <br/>
            <Button
              label={formatMessage({ id: 'COMMON.LOGIN' })}
              tag={Link}
              to={routs.LOGIN}
              color="link"
            />
          </div>
        )}
      </form>
    </div>
  )
};
