import React, { useEffect, useCallback } from 'react';
import { Grid, Avatar } from '../../../../components/ui-kit';
import routs from '../../../../modules/router/routes';
import { DATE_CHAT_FORMAT } from '../../../../modules/projects/constants';
import { matchPath } from 'react-router';
import {useSelector} from 'react-redux';
import moment from 'moment';
import Comment from '../Comment';
import CommentForm from '../CommentForm';
import {ProjectSelectors} from '../../../../modules/projects';

const getColumns = ({ params, formatMessage, history: { push } }) => {
  function handleRedirect(id, role) {
    const projectPath = routs.PROJECT(params);
    const clientPath = routs.PROJECT_CLIENT({ userId: id, projectId: params.projectId }) + '/account';
    const recruiterPath = routs.PROJECT_RECRUITER({ userId: id, projectId: params.projectId });

    push(role === 'client' ? projectPath + clientPath : recruiterPath);
  }

  return [{
    name: '№',
    selector: 'index',
    width: '50px',
    center: true,
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.DATE' }),
    selector: 'date',
    width: '120px',
    format: ({ date }) => moment.unix(date).format(DATE_CHAT_FORMAT)
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.AUTHOR' }),
    selector: 'manager',
    width: '200px',
    cell: ({ user: { last_name, first_name, logo, id, role }}) => (
      <div
        className="Users__avatar is-interact"
        onClick={() => handleRedirect(id, role)}
      >
        <Avatar src={logo} label={first_name + last_name} size="sm" />
        <div className="Users__avatar-name">{first_name} {last_name}</div>
      </div>
    )
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.COMMENT' }),
    allowOverflow: true,
    selector: 'message',
    format: ({ message, viewed, file }) => (
      <Comment
        file={file}
        text={message}
        viewed={viewed}
        formatMessage={formatMessage}
      />
    )
  }];
};

const Statuses = ({
  actions,
  flags,
  routProps: {
    history,
    location,
  },
  matchParams,
  formatMessage
}) => {
  const { params: { projectId } } = matchPath(location.pathname, { path: routs.PROJECT() });

  const projectStatuses = useSelector(ProjectSelectors.selectProjectStatuses);

  useEffect(() => {
    if (projectId) {
      actions.getProjectStatuses({ projectId });
    }
  }, [actions, projectId]);

  const onSubmit = useCallback((message, callBack, file) => {
    actions.postProjectComment({
      message,
      projectId,
      file,
      callBack,
    });

  }, [actions, projectId])

  return (
    <div className="Projects__grid align-start Statuses">
      <Grid
        cardMode="status"
        rows={projectStatuses}
        columns={getColumns({ history, formatMessage, params: matchParams })}
        overflowYOffset="50px"
        loading={flags.projectStatuses}
      />
      <CommentForm
        onSubmit={onSubmit}
        uploading={flags.uploadAttachments}
        loading={flags.projectComment}
      />
    </div>
  );
};

export default Statuses;
