import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { Spinner } from 'reactstrap';
import { TextField } from './../../../../components/ui-kit';
import './styles.sass';

type Props = {
  value: string,
  id: string | number,
  index: number,
  onDelete?: () => void,
}

const DynamicField = ({
  value,
  id,
  index,
  onDelete,
  title,
  readOnly,
  loading,
  defaultEditable,
  hideDeleteBtn,
  onConfirm,
  placeholder,
}: Props) => {
  const [inputValue, handleChange] = useState(value);
  const [isEditable, setEditMode] = useState(defaultEditable);
  const handleConfirm = () => {
    onConfirm({
      name: inputValue,
      callBack: () => setEditMode(false),
      index,
      id,
    });
  };

  const handleReject = () => {
    handleChange(value);
    setEditMode(false);
  };

  useEffect(() => {
    handleChange(value);
  }, [value]);

  return (
    <div className={cx('DynamicField', { 'is-editable': isEditable })}>
      {
        title && <div className="DynamicField__title">{title}</div>
      }
      <div className="DynamicField__wrapper">
        {
          (loading) && (
            <div className="DynamicField__loader">
              <Spinner type="grow" />
            </div>
          )
        }
        {
          !isEditable && (
            <div className="DynamicField__row">
              <div className={cx('DynamicField__value', {'is-empty': !inputValue})}>
                {inputValue || placeholder}
              </div>
              {
                !readOnly && (
                  <>
                    <div
                      className="DynamicField__btn is-edit visible-on-hover"
                      onClick={() => setEditMode(true)}
                    >
                      <i className="icon-edit" />
                    </div>
                    {
                      !hideDeleteBtn && (
                        <div
                          className="DynamicField__btn is-reject visible-on-hover"
                          onClick={() => onDelete({ id, index })}
                        >
                          <i className="icon-close" />
                        </div>
                      )
                    }
                  </>
                )
              }
            </div>
          )
        }
        {
          (isEditable && !readOnly) && (
            <>
              <div className="DynamicField__row">
                <TextField
                  input={{
                    onChange: handleChange,
                    value: inputValue,
                  }}
                  id={id}
                  minRows={1}
                  type="textarea"
                  maxRows={10}
                />
              </div>
              <div className="DynamicField__actions">
                <div
                  className="DynamicField__btn is-confirm"
                  onClick={handleConfirm}
                >
                  <i className="icon-ok" />
                </div>
                <div
                  className="DynamicField__btn"
                  onClick={handleReject}
                >
                  <i className="icon-close" />
                </div>
              </div>
            </>
          )
        }
      </div>
    </div>
  );
};

DynamicField.defaultProps = {
  defaultEditable: false,
  value: '',
};

export default DynamicField;
