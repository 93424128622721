import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DynamicField from '../DynamicField';
import { Spinner } from 'reactstrap';
import { Button } from '../../../../components/ui-kit';
import { TABS } from './index';

type Props = {
  isEdit: boolean,
  projectId: string,
  fields: any,
  actions: any,
}

const Questionnaire = ({
  actions,
  projectId,
  fields,
  flags,
  readOnly,
  isActive,
  rootLink,
  project,
  formatMessage
}: Props) => {
  const [newFields, changeFields] = useState([]);
  const addEmptyField = () => changeFields([...newFields, { name: '' }]);
  const removeField = ({ index, id }) => {
    if (id) {
      actions.deleteField({ id });
    } else {
      changeFields(newFields.filter((oldField, i) => i !== index))
    }
  };
  const addField = ({ name, index }) => {
    actions.addField({
      projectId,
      name,
      callBack: () => {
        changeFields(newFields.filter((oldField, i) => i !== index));
      }
    })
  };

  useEffect(() => {
    //console.log(projectId, 'quest')
    actions.getFields({ projectId });
  }, []);


  return (
    <div className="Projects__scroll-view">
      {
        flags.fields && (
          <div className="Projects__spinner">
            {formatMessage({ id: 'COMMON.LOADING' })} <Spinner type="grow" />
          </div>
        )
      }

      {
        !flags.fields && (
          <>
          {
            fields.map(({ id, name, order }, i) => (
              <div className="form-group" key={id}>
                <DynamicField
                  value={name}
                  id={id}
                  readOnly={readOnly}
                  index={i}
                  onConfirm={(params) => actions.updateField({ ...params, order })}
                  onDelete={actions.deleteField}
                  loading={flags.fieldRmId === id || flags.fieldUpdId === id}
                />
              </div>
            ))
          }
          {
            !readOnly && newFields.map(({ name }, i) => (
              <div className="form-group" key={i}>
                <DynamicField
                  value={name}
                  projectId={projectId}
                  index={i}
                  onConfirm={addField}
                  onDelete={removeField}
                  defaultEditable
                  loading={flags.fieldAddId}
                />
              </div>
            ))
          }
          {
            !readOnly && (
              <div className="text-left">
                <Button
                  label={formatMessage({ id: 'COMMON.ADD_FIELD' })}
                  prefix="icon-plus"
                  onClick={addEmptyField}
                  color="link"
                />
              </div>
            )
          }
          </>
        )
      }
    </div>
  )
};

export default Questionnaire;
