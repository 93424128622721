import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Avatar,
  Grid,
} from '../../../../components/ui-kit';
import { TABS } from './index';
import routs from '../../../../modules/router/routes';

type Props = {
  isEdit: boolean,
  submitAction: () => any,
}

const getColumns = ({ formatMessage, rootLink, history: { push } }) => {
  const redirectToPage = (id) => () => {
    push(rootLink + routs.STOP_LIST_CANDIDATE({ candidateId: id, mode: 'edit' }))
  };

  return [{
    name: '№',
    selector: 'index',
    width: '50px',
    center: true,
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.CANDIDATE' }),
    selector: 'name',
    cell: ({ name, surname, logo, id  }) => (
      <div
        className="Users__avatar is-interact"
        onClick={redirectToPage(id)}
      >
        <Avatar src={logo} label={surname + name} size="sm" />
        <div className="Users__avatar-name">{surname} {name}</div>
      </div>
    ),
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.CURRENT_COMPANY' }),
    selector: 'current_company',
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.CURRENT_POSITION' }),
    selector: 'current_position',
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.CURRENT_STATUS' }),
    selector: 'status',
  }];
};

const StopList = ({
  project,
  rootLink,
  actions,
  projectId,
  flags,
  candidates,
  history,
  readOnly,
  formatMessage
}: Props) => {
  useEffect(() => {
    actions.getCandidates({ projectId, status: 7 });
  }, [projectId, actions]);

  return (
    <Grid
      rows={candidates}
      columns={getColumns({ rootLink, history, formatMessage })}
      loading={flags.candidates}
      cardMode="candidate"
      prependHeader={!readOnly && (
        <Button
          label={formatMessage({ id: 'CANDIDATES.ADD_CANDIDATE' })}
          color="slim"
          tag={Link}
          prefix="icon-plus"
          to={rootLink + routs.ADD_TO_STOP_LIST}
        />
      )}
    />
  );
};

export default StopList;
