// @flow

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Dialog from '../Dialog';
//import './styles.scss';

type Props = {
  message: string,
  onConfirm: () => void,
  onReject: () => void,
  callback: () => void
}

type State = {
  hidden: boolean,
}

export function getUserConfirmation(message, callback) {
  const modal = document.createElement('div');

  modal.setAttribute('id', 'Prompt');
  modal.classList.add('Prompt__wrapper');

  document.body.appendChild(modal);

  ReactDOM.render(<PromptMsg message={message} callback={callback} />, modal);
}

class PromptMsg extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hidden: false,
    };
  }

  //componentWillReceiveProps() {
  //  this.setState({
  //    hidden: false,
  //  });
  //}

  toggleVisibility = () => {
    this.setState({
      hidden: true,
    });
  }

  handleConfirm = () => {
    const { onConfirm, callback } = this.props;

    if (onConfirm && typeof onConfirm === 'function') {
      onConfirm(() => {
        callback(true);
        this.toggleVisibility();
      });
    } else {
      callback(true);
      this.toggleVisibility();
    }
  };

  handleReject = () => {
    const { onReject, callback } = this.props;

    if (onReject && typeof onReject === 'function') {
      onReject(() => {
        callback(false);
        this.toggleVisibility();
      });
    } else {
      callback(false);
      this.toggleVisibility();
    }
  };

  parseMessage = (message) => {
    if (message.match('||')) {
      const [title, parsedMsg] = message.split('||');

      return {
        title,
        body: parsedMsg,
      };
    }

    return { body: message };
  };

  render() {
    const { message } = this.props;
    const { hidden } = this.state;
    const { title, body } = this.parseMessage(message);

    return (
      <Dialog
        isOpen={!hidden}
        onClose={this.toggleVisibility}
        title={title}
        width={400}
        actions={[{
          size: 'md',
          key: 'CANCEL',
          color: 'link',
          label: 'Cancel',
          onClick: this.handleReject,
        }, {
          key: 'CONFIRM',
          size: 'md',
          color: 'primary',
          label: 'Discard',
          onClick: this.handleConfirm,
        }]}
        className="Prompt"
        wrapClassName="Prompt__backdrop"
      >
        <div className="Prompt__message">{body}</div>
      </Dialog>
    );
  }
}

PromptMsg.defaultProps = {};

export default PromptMsg;
