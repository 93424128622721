import { useContext } from 'react';
import { Context } from '../modules/auth';

export function useAuthContext() {
  const authContext = useContext(Context)

  if (!authContext) {
    throw new Error('useAuthContext should be using inside AuthProvider context')
  }

  return authContext
}
