import { takeLatest } from 'redux-saga/effects';
import { ProjectActions, ProjectTypes } from '../index';
import * as workers from './workers';

export default function* () {
  yield takeLatest(ProjectTypes.INIT, workers.init);
  yield takeLatest(ProjectTypes.POST_REVIEW_REQUEST, workers.postReview);
  yield takeLatest(ProjectTypes.GET_STATUSES_REQUEST, workers.getStatuses);
  yield takeLatest(ProjectTypes.GET_FIELDS_REQUEST, workers.getFields);
  yield takeLatest(ProjectTypes.ADD_FIELD_REQUEST, workers.addField);
  yield takeLatest(ProjectTypes.UPDATE_FIELD_REQUEST, workers.updateField);
  yield takeLatest(ProjectTypes.REMOVE_FIELD_REQUEST, workers.deleteField);
  yield takeLatest(ProjectTypes.GET_PROJECTS_REQUEST, workers.getProjects);
  yield takeLatest(ProjectTypes.GET_PROJECT_REQUEST, workers.getProject);
  yield takeLatest(ProjectTypes.GET_CANDIDATES_REQUEST, workers.getCandidates);
  yield takeLatest(ProjectTypes.GET_RECRUITERS_REQUEST, workers.getRecruiters);
  yield takeLatest(ProjectTypes.ACTIVATE_PROJECT_REQUEST, workers.activateProject);
  yield takeLatest(ProjectTypes.DELETE_PROJECT_REQUEST, workers.deleteProject);
  yield takeLatest(ProjectTypes.UPLOAD_FILE_REQUEST, workers.uploadFile);
  yield takeLatest(ProjectTypes.GET_CANDIDATE_REQUEST, workers.getCandidate);
  yield takeLatest(ProjectTypes.DELETE_CANDIDATE_REQUEST, workers.deleteCandidate);
  yield takeLatest(ProjectTypes.GET_CANDIDATE_FIELDS_REQUEST, workers.getCandidateFields);
  yield takeLatest(ProjectTypes.ADD_CANDIDATE_FIELD_REQUEST, workers.addCandidateField);
  yield takeLatest(ProjectTypes.UPDATE_CANDIDATE_FIELD_REQUEST, workers.updateCandidateField);
  yield takeLatest(ProjectTypes.REMOVE_CANDIDATE_FIELD_REQUEST, workers.removeCandidateField);
  yield takeLatest(ProjectTypes.GET_INBOX_REQUEST, workers.getInbox);
  yield takeLatest(ProjectTypes.DO_REQUEST_PROJECT_REQUEST, workers.doRequestProject);
  yield takeLatest(ProjectTypes.GET_CANDIDATE_STATUSES_REQUEST, workers.getCandidateStatuses);
  yield takeLatest(ProjectTypes.ACCEPT_RECRUITER_REQUEST, workers.acceptRecruiter);
  yield takeLatest(ProjectTypes.GET_CANDIDATE_ACTIVITIES_REQUEST, workers.getCandidateActivities);
  yield takeLatest(ProjectTypes.GET_FULL_COMMENT_REQUEST, workers.getFullComment);
  yield takeLatest(ProjectTypes.POST_COMMENT_REQUEST, workers.postComment);
  yield takeLatest(ProjectTypes.UPLOAD_ATTACHMENTS_REQUEST, workers.uploadAttachments);
  yield takeLatest(ProjectActions.changeCandidateStatus.REQUEST, workers.changeCandidateStatus);
  yield takeLatest(ProjectActions.updateProject.REQUEST, workers.updateProject);
  yield takeLatest(ProjectActions.addProject.REQUEST, workers.addProject);
  yield takeLatest(ProjectActions.addCandidate.REQUEST, workers.addCandidate);
  yield takeLatest(ProjectActions.updateCandidate.REQUEST, workers.updateCandidate);
  yield takeLatest(ProjectActions.askProjectPause.REQUEST, workers.askProjectPause);
  yield takeLatest(ProjectActions.changeRecruiter.REQUEST, workers.changeRecruiter);
  yield takeLatest(ProjectActions.changeProjectStatus.REQUEST, workers.changeProjectStatus);

  yield takeLatest(ProjectTypes.GET_PROJECT_STATUSES_REQUEST, workers.getProjectStatuses);
  yield takeLatest(ProjectTypes.POST_PROJECT_COMMENT_REQUEST, workers.postProjectComment);
  yield takeLatest(ProjectTypes.GET_RECRUITER_COMMENTS_REQUEST, workers.getRecruiterComments);
  yield takeLatest(ProjectTypes.SEND_RECRUITER_COMMENT_REQUEST, workers.sendRecruiterComment);
  yield takeLatest(ProjectTypes.GET_RECRUITER_PROPOSAL_REQUEST, workers.getRecruiterProposal);
  yield takeLatest(ProjectTypes.FINISH_PROJECT_REQUEST, workers.finishProject);

  yield takeLatest([
    ProjectActions.changeCandidateStatus.FAILURE,
    ProjectTypes.ACTIVATE_PROJECT_ERROR,
  ], workers.showErrorNotify);
}
