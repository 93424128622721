import React from 'react';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import { Nav, Page } from './../../components/ui-kit';
import routs from '../../modules/router/routes';
import Profile from './Profile';
import './styles.sass';
import mapNav from '../../utils/mapNav';
import { injectIntl } from 'react-intl';
import withBreadCrumb from '../../HOC/withBreadCrumb';

export const PAGES = {
  company: {
    page: Profile,
    link: '/profile',
    text: { id: 'NAV.COMPANY.INDEX' },
  },
};


const Company = ({ match, intl: { formatMessage } }) => (
  <div className="Users has-nav">
    <Nav list={mapNav(Object.entries(PAGES), match.url, formatMessage)} />
    <div className="Users__scroll-view">
      <Page>
        <Switch>
          <Route path={routs.COMPANY_PROFILE} component={Profile} />
          <Redirect to={routs.COMPANY_PROFILE} />
        </Switch>
      </Page>
    </div>
  </div>
);

export default withRouter(
  withBreadCrumb(injectIntl(Company), routs.COMPANY, { id: 'NAV.COMPANY.INDEX' })
);
