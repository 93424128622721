import React, { useState, useCallback } from 'react';
import {useDispatch} from 'react-redux';
import { Button, TextField } from '../../../../components/ui-kit';
import { useIntl } from 'react-intl';
import './styles.sass';
import cx from 'classnames';
import { ACTIVITY_COMMENT_LENGTH } from '../../../../modules/projects/constants';
import { ProjectActions } from '../../../../modules/projects'
import Upload from '../../../../components/ui-kit/Upload';
type Props = {
  onSubmit: () => void,
  loading: boolean,
}

export const ACCEPT_FOR_ATTACHMENTS = [
  'image/*',
  '.doc',
  '.docx',
  '.ppt',
  '.pptx',
  '.pdf',
];

const CommentForm = ({ onSubmit, loading, uploading }: Props) => {
  const { formatMessage } = useIntl();
  const [attachment, setAttachments] = useState('');
  const dispatch = useDispatch();

  const [comment, typeComment] = useState('');

  const handleClick = useCallback(() => {
    onSubmit(comment, () => {
      typeComment('');
      setAttachments('');
    }, attachment);
  }, [comment, typeComment])

  const handleUpload = useCallback(({ FormData }) => {
    dispatch(ProjectActions.uploadAttachment({
      FormData,
      callBack: ({ data: { name } }) => {
        setAttachments(name);
      }
    }));
  }, [dispatch, comment, typeComment]);

  const handleRemoveAttach = useCallback(() => {
    setAttachments('');
  }, []);

  return (
    <div className="CommentForm">
      <div className="CommentForm__row">
        <div className={cx('CommentForm__field', {'has-attach': attachment})}>
          <TextField
            input={{
              value: comment,
              onChange: typeComment
            }}
            type="textarea"
            maxRows={5}
            maxLength={ACTIVITY_COMMENT_LENGTH}
            placeholder={formatMessage({ id: 'FORM.MESSAGE_TEXT' })}
            id="commentInput"
          />

        </div>
        <div className="CommentForm__btn">
          {
            attachment ? (
              <div className="CommentForm__trash">
                <Button
                  color="slim"
                  prefix="icon-trash-o"
                  onClick={handleRemoveAttach}
                />
              </div>
            ) : (
              <div className="CommentForm__picture-btn">
                <Upload onChange={handleUpload} accepts={ACCEPT_FOR_ATTACHMENTS}>
                  <Button
                    prefix="icon-upload"
                    invertedLoader
                    loading={uploading}
                  />
                </Upload>
              </div>
            )
          }
          <Button
            color="slim"
            loading={loading}
            disabled={!comment}
            invertedLoader
            prefix="icon-check"
            onClick={handleClick}
            label={formatMessage({ id: 'COMMON.SEND_COMMENT' })}
          />
        </div>
      </div>
    </div>
  )
}

export default CommentForm;
