import React, { useEffect } from 'react';
import { Spinner } from 'reactstrap';
import DynamicField from '../DynamicField';

type Props = {
  isEdit: boolean,
  projectId: string,
  fields: any,
  actions: any,
}

const Questionnaire = ({
  actions,
  candidateFields,
  flags,
  routProps,
  readOnly,
}: Props) => {
  const handleChangeField = (questionnaire, fieldId) => (data) => {
    if (questionnaire) {
      actions.updateCandidateField({ ...data, questionnaire, fieldId });
    } else {
      actions.addCandidateField({ ...data, routProps, fieldId })
    }
  };

  useEffect(() => {
    actions.getCandidateFields(routProps);
  }, [actions]);

  if (flags.candidateFields) {
    return (
      <div className="Projects__spinner">
        Loading... <Spinner type="grow" />
      </div>
    )
  }

  return (
    <div className="Projects__scroll-view">
      {
        (!candidateFields || !candidateFields.length) && <div className="form-group">No questions yet</div>
      }
      {
        candidateFields && candidateFields.map(({ id, name, order, questionnaire }, i) => (
          <div className="form-group" key={id}>
            <DynamicField
              value={questionnaire ? questionnaire.text : ''}
              title={name}
              id={id}
              index={i}
              hideDeleteBtn={!questionnaire}
              readOnly={readOnly}
              placeholder="Leave your comment"
              onConfirm={handleChangeField(questionnaire, id)}
              onDelete={() => actions.removeCandidateField({ questionnaire, id })}
              loading={flags.candidateFieldId === id}
            />
          </div>
        ))
      }
    </div>
  );
};

export default Questionnaire;
