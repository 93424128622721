import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { TextField, Button } from './../../../../components/ui-kit';

const Info = ({
  handleSubmit,
  reset,
  submitAction,
  pristine,
  ifView,
  flags,
  formatMessage
}) => (
  <form onSubmit={handleSubmit(submitAction)} className="Projects__scroll-view">
    <div className="form-section">
      <div className="row">
        <div className="form-group col-xs-12 col-md-6">
          <Field
            component="input"
            id="status_candidate"
            name="status_candidate"
            type="hidden"
          />
          <Field
            type="text"
            name="surname"
            disabled={ifView}
            id="surname"
            component={TextField}
            label={formatMessage({ id: 'FORM.LAST_NAME' })}
          />
        </div>
        <div className="form-group col-xs-12 col-md-6">
          <Field
            type="text"
            name="name"
            id="name"
            disabled={ifView}
            component={TextField}
            label={formatMessage({ id: 'FORM.FIRST_NAME' })}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-xs-12 col-md-6">
          <Field
            type="text"
            name="current_position"
            disabled={ifView}
            id="current_position"
            component={TextField}
            label={formatMessage({ id: 'FORM.POSITION' })}
          />
        </div>
        <div className="form-group col-xs-12 col-md-6">
          <Field
            type="text"
            name="current_company"
            disabled={ifView}
            id="current_company"
            component={TextField}
            label={formatMessage({ id: 'FORM.COMPANY.INDEX' })}
          />
        </div>
      </div>
    </div>
    {
      !ifView && (
        <div className="form-footer lg-static">
          <div className="form-footer__cell">
            <Button
              disabled={pristine}
              label={formatMessage({ id: 'COMMON.CANCEL' })}
              onClick={reset}
              prefix="icon-close2"
              color="secondary"
            />
          </div>
          <div className="form-footer__cell">
            <Button
              type="submit"
              prefix="icon-save"
              label={formatMessage({ id: 'COMMON.SAVE' })}
              loading={flags.candidateUpd}
              disabled={flags.uploading || pristine}
            />
          </div>
        </div>
      )
    }
    <br/>
    <br/>
  </form>
);

export default reduxForm({
  form: 'add-candidate',
  enableReinitialize: true,
  destroyOnUnmount: false,
})(Info)
