import React, {useCallback, useState} from 'react';
import {Field, reduxForm} from 'redux-form';
import {
  TextField,
  Button,
  Dialog,
} from '../../../../components/ui-kit';
import {useSelector} from "react-redux";
import { calcReward } from '../../../../modules/projects/utils'
import {ProfileSelectors} from "../../../../modules/profile";


const Description = ({
  handleSubmit,
  reset,
  readOnly,
  errors,
  submitAction,
  isEdit,
  flags,
  projectId,
  actions,
  periods,
  pristine,
  project,
  error,
  formatMessage,
}) => {
  const profile = useSelector(ProfileSelectors.selectProfile);
  const [isOpenDetails, toggleDetails] = useState(false);
  const values = useSelector(state => state.form['project-description'].values);
  const isClient = profile.role === 'client';

  const handleClose = useCallback((isOpen) => {
    return () => {
      toggleDetails(isOpen)
    }
  }, [toggleDetails])

  return (
    <form onSubmit={handleSubmit(submitAction)} className="Projects__scroll-view">
      <Dialog
        isOpen={isOpenDetails}
        onToggle={handleClose(false)}
        title={formatMessage({ id: 'PROMPT.REWARD_PROMPT.TITLE' })}
        wrapClassName="Reward-popup"
      >
        <ul className="Projects__popup-list">
          <li>
            <p>{formatMessage({ id: 'PROMPT.REWARD_PROMPT.LIST.ITEM1' })}</p>
          </li>
          <li>
            <p>{formatMessage({ id: 'PROMPT.REWARD_PROMPT.LIST.ITEM2' })}</p></li>
          <li>
            <p>{formatMessage({ id: 'PROMPT.REWARD_PROMPT.LIST.ITEM3' })}</p>
          </li>
        </ul>
        <p>
          {formatMessage({ id: 'PROMPT.REWARD_PROMPT.DESCRIPTION' })}
        </p>
      </Dialog>
      <div className="form-section">
        <Field
          component="input"
          id="status_candidate"
          name="status_candidate"
          type="hidden"
        />
        <div className="row">
          <div className="form-group col-xs-12 col-md-6 col-lg-8">
            <Field
              type="text"
              required
              name="position"
              id="position"
              component={TextField}
              disabled={readOnly}
              label={formatMessage({ id: 'FORM.PROJECT.POSITION' })}
              placeholder={formatMessage({ id: 'FORM.PROJECT.POSITION_PLACEHOLDER' })}
            />
          </div>
          <div className="form-group col-xs-12 col-md-6 col-lg-4">
            <Field
              type="text"
              name="salary"
              required
              id="salary"
              unit={formatMessage({ id: 'COMMON.UNITS.UAH' })}
              component={TextField}
              label={formatMessage({ id: 'FORM.PROJECT.SALARY' })}
              disabled={readOnly}
            />
          </div>
          <div className="form-group col-xs-12 col-md-6 col-lg-4">
            <Field
              required
              type="select"
              name="guaranteed_period"
              id="guaranteed_period"
              component={TextField}
              disabled={readOnly}
              options={periods}
              unit={formatMessage({ id: 'COMMON.UNITS.MONTH' })}
              isLoading={flags.statuses}
              label={formatMessage({ id: 'FORM.PROJECT.GUARANTEE_PERIOD' })}
            />
          </div>
          <div className="form-group col-xs-12 col-md-6 col-lg-4">
            <Field
              type="text"
              name="reward"
              required
              id="reward"
              component={TextField}
              label={formatMessage({ id: 'FORM.PROJECT.REWARD' })}
              placeholder={formatMessage({ id: 'FORM.PROJECT.REWARD_PLACEHOLDER_1' })}
              unit={formatMessage({ id: 'COMMON.UNITS.UAH' })}
              disabled={readOnly}
            />
          </div>

          <div className="form-group col-xs-12 col-md-6 col-lg-4">
            <TextField
              type="text"
              name="reward2"
              id="reward2"
              input={{
                value: calcReward(values.salary, values.reward),
                onChange: () => false
              }}
              unit={(
                <span style={{ fontSize: 16 }}>%</span>
              )}
              placeholder={formatMessage({ id: 'FORM.PROJECT.REWARD_PLACEHOLDER_2' })}
              label={' '}
              disabled
            />
          </div>
          <div className="form-group col-xs-12 col-md-6 col-lg-6">
            <Field
              type="text"
              name="payment_term"
              id="payment_term"
              required
              component={TextField}
              label={formatMessage({ id: 'FORM.PROJECT.PAYMENT_TERM' })}
              placeholder={formatMessage({ id: 'FORM.PROJECT.PAYMENT_TERMS_PLACEHOLDER' })}
              disabled={readOnly}
            />
          </div>
          <div className="form-group col-xs-12 col-md-6 col-lg-6">
            <div className="form-link text-right">
              <span onClick={handleClose(true)}>
                {formatMessage({ id: 'FORM.PROJECT.REWARD_INDICATION_LINK' })}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="form-section">
        <div className="row">
          <div className="form-group col-12">
            <Field
              type="textarea"
              name="responsibilities"
              id="responsibilities"
              required
              component={TextField}
              label={formatMessage({ id: 'FORM.PROJECT.RESPONSIBILITIES' })}
              disabled={readOnly}
              minRows={1}
              maxRows={10}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-12">
            <Field
              name="requirements"
              id="requirements"
              required
              component={TextField}
              disabled={readOnly}
              label={formatMessage({ id: 'FORM.PROJECT.REQUIREMENTS' })}
              minRows={1}
              type="textarea"
              maxRows={10}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-12">
            <Field
              type="textarea"
              required
              maxRows={10}
              minRows={1}
              disabled={readOnly}
              name="conditions"
              id="conditions"
              component={TextField}
              label={formatMessage({ id: 'FORM.PROJECT.CONDITIONS' })}
            />
          </div>
        </div>
        {
          errors && (
            <div className="Projects__errors">{formatMessage({ id: error })}</div>
          )
        }
      </div>
      <div className="form-section">
        <div className="row">
          <div className="form-group col-12">
            <Field
              name="subordination"
              type="textarea"
              id="subordination"
              maxRows={10}
              minRows={1}
              component={TextField}
              disabled={readOnly}
              label={formatMessage({ id: 'FORM.PROJECT.SUBORDINATION' })}
              placeholder={formatMessage({ id: 'FORM.PROJECT.SUBORDINATION_PLACEHOLDER' })}
            />
          </div>
          <div className="form-group col-12">
            <Field
              name="additional_description"
              type="textarea"
              id="additional_description"
              maxRows={10}
              minRows={1}
              component={TextField}
              disabled={readOnly}
              label={formatMessage({ id: 'FORM.PROJECT.ADDITIONAL_DESCRIPTION' })}
              placeholder={formatMessage({ id: 'FORM.PROJECT.ADDITIONAL_DESCRIPTION_PLACEHOLDER' })}
            />
          </div>
          <div className="form-group col-12">
            <Field
              name="company_description"
              type="textarea"
              id="company_description"
              maxRows={10}
              minRows={1}
              component={TextField}
              disabled={readOnly}
              label={formatMessage({ id: 'FORM.PROJECT.COMPANY_DESCRIPTION' })}
              placeholder={formatMessage({ id: 'FORM.PROJECT.COMPANY_DESCRIPTION_PLACEHOLDER' })}
            />
          </div>
        </div>
      </div>
      {
        isClient && (
          <div className="form-footer">
            <div className="form-footer__cell">
              <Button
                disabled={pristine}
                label={formatMessage({ id: 'COMMON.CANCEL' })}
                onClick={reset}
                color="secondary"
                prefix="icon-close2"
                rounded
              />
            </div>
            <div className="form-footer__cell">
              <Button
                label={formatMessage({ id: 'COMMON.DELETE' })}
                loading={flags.deletingProjectId !== null}
                rounded
                isWhite
                prefix="icon-trash"
                onClick={() => actions.deleteProject({ projectId })}
                disabled={!project || project.status !== 2}
              />
              <Button
                type="submit"
                rounded
                isWhite
                prefix="icon-save"
                label={formatMessage({id: 'FORM.PROJECT.SAVE_AS_DRAFT'})}
                loading={flags.projectUpd}
              />
              <Button
                disabled={!isEdit}
                rounded
                label={formatMessage({ id: 'COMMON.ACTIVATE' })}
                onClick={() => actions.activateProject({ projectId })}
                color="primary"
                prefix="icon-jet"
                loading={flags.activation}
              />
            </div>
          </div>
        )
      }
    </form>
  )
};


export default reduxForm({
  form: 'project-description',
  enableReinitialize: true,
  destroyOnUnmount: true,
})(Description)
