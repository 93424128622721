import React, {useCallback, useMemo} from 'react';
import { Spinner } from '../../components/ui-kit';
import { Button, TextField } from '../../components/ui-kit';
import { useIntl } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';
import { useAuthContext, useApi } from '../../hooks';
import {Link, useParams, useHistory} from 'react-router-dom';
import { Yup, password, PASSWORD_MAX, PASSWORD_MIN } from '../../utils/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import routs from "../../modules/router/routes";

const schema = Yup.object().shape({
  password: password(),
});

export function injectMetaIntoError(errorCode, format) {
  if (errorCode === 'ERRORS.PASSWORD_RANGE') {
    return format({ id: errorCode }, { min: PASSWORD_MIN, max: PASSWORD_MAX });
  }

  return format({ id: errorCode });
}

export const ConfirmRestoring = () => {
  const auth = useAuthContext();
  const params = useParams();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const form = useForm({
    defaultValues: {
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const queryParams = useMemo(() => ({key: params.key}), [params.key]);
  const { fetching } = useApi(auth.confirmRestorePass, queryParams);

  const handleSubmit = useCallback(async ({ password }) => {
    const errors = await auth.sendNewPassword({
      password,
      token: params.key,
    });

    if (errors) {
      form.setError("password", {
        type: "manual",
        message: `ERRORS.${errors.token}`,
      });
    } else {
      form.clearErrors("password");
      // setIsConfirmSent(true);
      const email = params.email;

      await auth.signIn({password, email});
      history.push(routs.LOGIN);
    }
  }, [params]);


  return (
    <div className="Auth__form">
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <div className="Auth__title">
          <h1>{formatMessage({ id: 'AUTH.RESTORE.H1' })}</h1>
          <p>{formatMessage({ id: 'AUTH.RESTORE.H2' })}</p>
        </div>
        {
          fetching ? (
            <>
              <span className="Auth__confirming">{formatMessage({ id: 'AUTH.RESTORE.CONFIRMING' })}</span>
              <br/>
              <Spinner />
            </>
          ) : (
            <>
              <div className="Auth__form-group">
                <Controller
                  name="password"
                  control={form.control}
                  render={input => (
                    <TextField
                      maxRows={1}
                      input={input}
                      label={formatMessage({ id: 'FORM.NEW_PASSWORD' })}
                      type="password"
                      meta={{
                        formatted: true,
                        error: form.errors.password
                          ? injectMetaIntoError(form.errors.password.message, formatMessage)
                          : ''
                      }}
                    />
                  )}
                />
              </div>
              <br/>
              <div className="Auth__form-footer">
                <Button
                  rounded
                  type="submit"
                  label={formatMessage({ id: 'COMMON.CONFIRM' })}
                  color="primary"
                  loading={form.formState.isSubmitting}
                />
              </div>
              <div className="Auth__center">
                <Button
                  to={routs.LOGIN}
                  tag={Link}
                  label={formatMessage({ id: 'COMMON.LOGIN' })}
                  size="sm"
                  color="link"
                />
                &nbsp;&nbsp;<p>{formatMessage({ id: 'COMMON.OR' })}</p>&nbsp;&nbsp;
                <Button
                  to={routs.SIGN_UP}
                  tag={Link}
                  style={{ textTransform: 'capitalize' }}
                  label={formatMessage({ id: 'COMMON.SIGN_UP' })}
                  size="sm"
                  color="link"
                />
              </div>
            </>
          )
        }
      </form>
    </div>
  );
};
