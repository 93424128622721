import React from 'react';
import img from './140x140.png';
import cx from 'classnames';
import Spinner from './../Spinner';
import './styles.sass';

import { BASE_FILE_URL } from '../../../app/API';

type Props = {
  src: string,
  label: string,
  loading?: boolean,
  size: string | number,
}

function parseValue(value) {
  const words = value.split(' ').filter((word, i) => i <= 1);

  if (value.match('undefined')) {
    return '-//-'
  }
  if (words.length > 1) {
    return words.map(word => word.slice(0, 1)).join('');
  }

  return value.slice(0, 1);
}

const Avatar = ({
  src,
  label,
  loading,
  size,
}: Props) => (
  <div className={cx('Avatar', { [size]: true })}>
    <div
      className="Avatar__wrapper"
      style={{
        backgroundImage: src ? `url(${BASE_FILE_URL}${src})` : '',
      }}
    >
      <img src={img} alt="" className="Avatar__dummy" />
      {
        !src && (
          <div className="Avatar__cover">
            {
              loading ? <Spinner /> : (
                <>
                  {parseValue(label)}
                </>
              )
            }
          </div>
        )
      }
    </div>
  </div>
);

Avatar.defaultProps = {
  label: '',
  size: 'auto', // 'sm', 'md', 'lg', 'auto'
};

export default Avatar;
