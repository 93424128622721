import React from 'react';
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import routs from '../../modules/router/routes';
import Recruiter from './Recruiter';
import withBreadCrumb from './../../HOC/withBreadCrumb';
import List from './List';

const Recruiters = () => (
  <Switch>
    <Route path={routs.RECRUITERS} component={List} exact />
    <Route path={routs.RECRUITER_PAGE()} component={Recruiter} />
    <Redirect to={routs.RECRUITERS} />
  </Switch>
);

export default withBreadCrumb(Recruiters, routs.RECRUITERS, {id: 'NAV.RECRUITERS'});
