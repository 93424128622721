import React, {useCallback, useMemo} from 'react';
import {
  Link,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import routs from '../../modules/router/routes';
import { Login } from './Login';
import { SignUp } from './SignUp';
import { RestorePass } from './RestorePass';
import { ConfirmSignUp } from './ConfirmSignUp';
import { ConfirmRestoring } from './ConfirmRestoring';
import {Dropdown, Logo} from '../../components/ui-kit';
import { useIntl } from 'react-intl';
import './styles.sass';
import withTracker from './../../HOC/withTracker';
import {useSelector, useDispatch} from "react-redux";
import {IntlSelectors, IntlActions} from "../../modules/intl";
import {localeOptions} from "../../modules/intl/i18n";

const PASSWORD_STEPS = ['FORM.HINTS.PASSWORD.LENGTH', 'FORM.HINTS.PASSWORD.LETTER', 'FORM.HINTS.PASSWORD.FIGURE'];

const Auth = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const locale = useSelector(IntlSelectors.selectCurrentLocale);

  const handleChangeLocale = useCallback((option) => {
    dispatch(IntlActions.changeLocale(option));
  }, []);

  const lang = useMemo(() => {
    let value = null;
    const options = localeOptions.filter((currLoc) => {
      if (currLoc.value === locale) {
        value = currLoc;
      }
      return currLoc.value !== locale
    });

    return { options, value }
  }, [locale]);

  return (
    <div className="Auth">
      <header>
        <Link to={routs.HOME} className="Auth__logo"><Logo isInverted/></Link>
        <div className="Auth__langs">
          <Dropdown
            options={localeOptions}
            input={{
              value: lang.value,
              onChange: handleChangeLocale
            }}
          />
        </div>
      </header>
      <Switch>
        <Route path={routs.LOGIN} component={withTracker(Login)} />
        <Route path={routs.SIGN_UP} component={withTracker(SignUp)} exact />
        <Route path={routs.CONFIRM_SIGN_UP()} component={withTracker(ConfirmSignUp)} />
        <Route path={routs.FORGOT_PASSWORD} component={withTracker(RestorePass)} exact />
        <Route path={routs.CONFIRM_RESTORING()} component={withTracker(ConfirmRestoring)} />
        <Redirect to={routs.LOGIN} />
      </Switch>
      <div className="Auth__support">
        <p>
          {formatMessage({ id: 'AUTH.REPORT_TEXT' })}
          {' '}
          <a href="mailto:support@resorcer.com">support@resorcer.com</a>
        </p>
      </div>
    </div>
  );
};

export function reducePasswordError(formatMessage) {
  return PASSWORD_STEPS.reduce((result, id, index) => {
    return result.concat(`${index > 0 ? ', ' : ''}${formatMessage({ id })}`)
  }, '');
}

export default Auth;
