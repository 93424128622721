import React, {useCallback, useEffect } from 'react';
import { useIntl } from "react-intl";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { ProjectActions, ProjectSelectors } from './../../../../modules/projects';
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from 'yup';


import { Button, TextField, Rating, Dialog } from "../../../../components/ui-kit";
import "./styles.sass";

const MIN_STARS = 1;

const validation = yup.object({
  level: yup.number().min(MIN_STARS),
  communication: yup.number().min(MIN_STARS),
  speed: yup.number().min(MIN_STARS),
  message: yup.string(),
});

const STAR_SIZE = 16;

const DEFAULT_VALUES = {
  message: '',
  level: 0,
  communication: 0,
  speed: 0,
};

export const ReviewRecruiter = ({
  targetUser,
  targetProjectId,
  isOpen,
  onOpen,
  onPreSubmit
}) => {
  const dispatch = useDispatch();
  const flags = useSelector(ProjectSelectors.selectFlags);
  const { formatMessage } = useIntl();
  const form = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(validation),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const handleSubmit = useCallback((values) => {
    if (!targetUser && !targetProjectId) {
      return;
    }

    const params = {
      ...values,
      recruiter_id: targetUser.id,
      project_id: targetProjectId,
    }

    const postReviewAction = ProjectActions.postReview({
      params,
      callBack: () => {
        onOpen(false)
      },
    });

    /**
     * should close a project before leave any review, will be using inside projects grid only
     */
    if (onPreSubmit) {
      onPreSubmit(() => {
        dispatch(postReviewAction);
      });
    } else {
      dispatch(postReviewAction);
    }
  }, [targetUser, targetProjectId, onPreSubmit]);

  const getErrors = () => {
    return Object.keys(form.errors).reduce((result, current) => {
      if (form.errors[current]) {
        const err = formatMessage({ id: 'ERRORS.REVIEW_VALUE_ERROR' }, { name: '', value: 1 });

        return {
          ...result,
          [current]: err,
        };
      }

      return result;
    }, {});
  };

  const errors = getErrors();

  useEffect(() => {
    if (!isOpen) {
      form.reset(DEFAULT_VALUES);
    }
  }, [isOpen]);

  return (
    <Dialog
      isOpen={isOpen}
      wrapClassName="RecruiterReview"
      onToggle={() => onOpen(false)}
    >
      <h1>
        <strong>{formatMessage({ id: 'PROJECTS.LEAVE_REVIEW_TITLE' })}</strong>
        <span className="RecruiterReview__name">{targetUser ? `${targetUser.first_name} ${targetUser.last_name}` : ''}</span>
      </h1>
      <>
        <div className="RecruiterReview__reviews-row">
          <div className="form-group">
            <div className="RecruiterReview__label">{formatMessage({ id: 'FORM.LEVEL' })}</div>
            <Controller
              name="level"
              control={form.control}
              render={input => (
                <Rating
                  value={input.value}
                  onChange={input.onChange}
                  size={STAR_SIZE}
                />
              )}
            />
            <div className="RecruiterReview__error">{errors.level}</div>
          </div>
          <div className="form-group">
            <div className="RecruiterReview__label">{formatMessage({ id: 'FORM.COMMUNICATION' })}</div>
            <Controller
              name="communication"
              control={form.control}
              render={input => (
                <Rating
                  value={input.value}
                  onChange={input.onChange}
                  size={STAR_SIZE}
                />
              )}
            />
            <div className="RecruiterReview__error">{errors.communication}</div>
          </div>
          <div className="form-group">
            <div className="RecruiterReview__label">{formatMessage({ id: 'FORM.SPEED' })}</div>
            <Controller
              name="speed"
              control={form.control}
              render={input => (
                <Rating
                  value={input.value}
                  onChange={input.onChange}
                  size={STAR_SIZE}
                />
              )}
            />
            <div className="RecruiterReview__error">{errors.speed}</div>
          </div>
        </div>
        <div className="form-group">
          <Controller
            name="message"
            control={form.control}
            render={input => (
              <TextField
                id="message"
                type="textarea"
                maxRows={10}
                minRows={4}
                input={input}
                label={formatMessage({ id: 'FORM.COMMENT' })}
                placeholder={formatMessage({ id: 'FORM.MESSAGE_TEXT' })}
              />
            )}
          />
        </div>
        <div className="RecruiterReview__footer">
          <Button
            color="secondary"
            label={formatMessage({ id: 'COMMON.CANCEL' })}
            onClick={() => onOpen(false)}
          />
          <Button
            onClick={form.handleSubmit(handleSubmit)}
            label={formatMessage({ id: 'COMMON.SEND' })}
            loading={flags.postReview}
            color="primary"
            prefix="icon-check"
          />
        </div>
      </>
    </Dialog>
  );
}
