import { createSelector } from 'reselect';

const getUserState = state => state.users;

export const selectFlags = createSelector(getUserState, state => state.flags);

export const selectUsers = createSelector(getUserState, state => state.users);

export const selectStatuses = createSelector(getUserState, state => state.statuses);

export const selectUser = createSelector(getUserState, state => state.user);


