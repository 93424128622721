import React from 'react';
import {
  CANDIDATE_GROUPS,
  CANDIDATE_PERMISSIONS,
  CANDIDATE_STATUSES
} from './constants';
//import { translationMessages } from '../intl/i18n';

function getText(key) {
  return `UPDATES.${key}`;
  //const globalLocales = translationMessages[locale];

  //if (!globalLocales) {
  //  return key;
  //}

  //if (globalLocales[fullKey]) {
  //  return globalLocales[fullKey];
  //}

  //return translationMessages.ru[fullKey]
}

/**
 * Generate a message according to the template and replaces it by I18n locales
 *
 * @param comment: string
 * @param date: string
 * @param time: string
 * @param status: Object
 * @param salary: string
 * @param candidate: string
 * @param locale: string. default - ru
 * @returns {string}
 */
export function fitCommentToPattern({
  comment,
  date,
  time,
  status = {},
  salary,
  candidate,
  locale
}) {
  /**
   * msg is a common used part which display what user wrote
   * @type {string}
   */
  const msg = comment ? `${getText('WITH_COMMENT', locale)} {{${comment}}}` : '';
  /**
   * references for status and group constants ['modules/projects/constant.js']
   */
  const rawStatus = CANDIDATE_STATUSES[status.value];

  /**
   * case 1 - Recruiter gets a qualified candidate
   */
  if (status.key === 'STATUS_THE_CANDIDATE_QUALIFIED') {
    //const statusText = translationMessages[locale][rawStatus.label];
    const statusText = rawStatus.label;

    return `${getText('CHANGED_STATUS', locale)} [${statusText}] ${msg}`;
  }

  /**
   * case 2 - basically changed status following the list bellow
   */
  if (
    status.key === 'STATUS_NOT_A_CANDIDATE'
    || status.key === 'STATUS_NO_CONTACT'
    || status.key === 'STATUS_THE_CANDIDATE_REFUSED'
    || status.key === 'STATUS_SHORT_LIST'
    || status.key === 'STATUS_SEND_OFFER'
    || status.key === 'STATUS_STOP_LIST'
    || status.key === 'STATUS_REFUSAL_OF_THE_CANDIDATE'
    || status.key === 'STATUS_ASSIGN_AN_INTERVIEW'
  ) {
    return `${getText('CHANGED_STATUS', locale)} [${rawStatus.label}] ${msg}`
  }

  /**
   * Status waiting
   */
  if (status.key === 'STATUS_WAIT') {
    return `${getText('SET_ON_HOLD', locale)} [${time}] ${msg}`;
  }

  /**
   * candidate was hired
   */
  if (status.key === 'STATUS_THE_CANDIDATE_IS_HIRED') {
    const message = comment ? ` ${getText('COMMENT', locale)} {{${comment}}}` : '';

    return `${getText('HIRED_CANDIDATE', locale)} - [${date}] ${getText('AND_SALARY', locale)} - [${salary}]. ${message}`;
  }

  /**
   * Somebody just added a candidate to the following project.
   */
  if (candidate) {
    return `${getText('ADDED_CANDIDATE', locale)} [${candidate}] ${msg}`
  }

  /**
   * Common left message.
   */
  return `${getText('LEAVE_COMMENT', locale)} {{${comment}}}`;
}



export function mapCandidateStatusesToOptions(groups, statuses, {
  role,
  value,
  formatMessage,
  hasHiredCandidate
}) {
  const { NEW, ...newOptions } = {...groups};
  const newGroups = {};

  Object.entries(statuses).forEach(([key, status]) => {
    const targetGroup = newOptions[status.group];
    /**
     * CANDIDATE_PERMISSIONS defines possibility of using each status depended of role.
     */
    if (!CANDIDATE_PERMISSIONS[role]) {
      return
    }
    const isAllowedForRole = CANDIDATE_PERMISSIONS[role].includes(status.key);


    /**
     * Return an empty array if group was not defined. Supposed to ignore NEW status.
     */
    if (!targetGroup) {
      return []
    }

    /**
     * Create an empty group if it does not exist yet.
     */
    if (!newGroups[status.group]) {
      newGroups[status.group] = {
        ...targetGroup,
        options: [],
        label: formatMessage({ id: targetGroup.label })
      };
    }

    /**
     *
     * @type {{title: *, value: *, label: *, color}}
     */
    if (isAllowedForRole && value.key !== status.key) {
      newGroups[status.group].options.push({
        ...status,
        title: status.title
          ? formatMessage({ id: status.title  })
          : formatMessage({ id: targetGroup.title }),
        value: key,
        label: formatMessage({ id: status.label }),
        color: targetGroup.color,
      })
    }
  });

  return switchCandidateStatuses({...newGroups}, {role, value, hasHiredCandidate});
}

function switchCandidateStatuses(src, { role, value, hasHiredCandidate }) {
  const { DEFLECTED, IN_WORK, HIRED } = {...src};
  const isNew = value.key === 'STATUS_CANDIDATE_NEW';
  const isInWork = value.group === 'IN_WORK';

  /**
   * * role: recruiter
   */
  if (role === 'recruiter') {
    /**
     *
     * Новий кандидат
     * "В работе
     - Квалифицирован
     Отклонить
     - Не кандидат / STATUS_NOT_A_CANDIDATE
     - Нет контактов
     "
     */
    if (isNew) {
      const filterCb = ({ key }) => key === 'STATUS_NOT_A_CANDIDATE' || key === 'STATUS_NO_CONTACT';
      return [IN_WORK, {
        ...DEFLECTED,
        options: DEFLECTED.options.filter(filterCb)
      }]
    }

    /**
     * В работе (всі підстатуси), якщо у гріді є найнятий кандидат
     * "Отклонить
     - Отказ кандидату" / STATUS_REFUSAL_OF_THE_CANDIDATE
     */
    if (isInWork && hasHiredCandidate) {
      const filterCb = ({ key }) => key === 'STATUS_REFUSAL_OF_THE_CANDIDATE';
      return [{
        ...DEFLECTED,
        options: DEFLECTED.options.filter(filterCb)
      }]
    }

    /**
     * В работе (всі підстатуси)
     * "Отклонить
       - Кандидат отказал" / STATUS_THE_CANDIDATE_REFUSED
     */
    if (isInWork) {
      const filterCb = ({ key }) => key === 'STATUS_THE_CANDIDATE_REFUSED';
      return [{
        ...DEFLECTED,
        options: DEFLECTED.options.filter(filterCb)
      }]
    }

    /**
     * * role: recruiter
     * Отклонен > Не кандидат
     *
     * "В работе
     - Квалифицирован"
     */
    if (value.key === 'STATUS_NOT_A_CANDIDATE') {
      return [IN_WORK]
    }


    /**
     * Отклонен > Нет контактов
     *
     * "В работе
     - Квалифицирован
     Отклонить
     - Не кандидат"
     */
    if (value.key === 'STATUS_NO_CONTACT') {
      const filterCb = ({ key }) => key === 'STATUS_NOT_A_CANDIDATE';
      return [IN_WORK, {
        ...DEFLECTED,
        options: DEFLECTED.options.filter(filterCb)
      }]
    }
  }


  /**
   * role client
   */
  if (role === 'client') {
    /**
     * В работе > Квалифицирован
     * "В работе
         - Интервью
         - Ожидание
       Отклонить
        - Отказ кандидату
       "
     */
    if (value.key === 'STATUS_THE_CANDIDATE_QUALIFIED') {
      const defFilterCb = ({ key }) => key === 'STATUS_REFUSAL_OF_THE_CANDIDATE';
      const inwFilterCb = ({ key }) => key === 'STATUS_WAIT' || key === 'STATUS_ASSIGN_AN_INTERVIEW';
      return [{
        ...IN_WORK,
        options: IN_WORK.options.filter(inwFilterCb)
      }, {
        ...DEFLECTED,
        options: DEFLECTED.options.filter(defFilterCb)
      }]
    }


    /**
     * В работе > Интервью
     * "В работе
       - Интервью
       - Ожидание
       - Шорт-лист
       - Оффер
       Отклонить
       - Отказ кандидату
       - Кандидат отказал
       Нанят
       - Нанять"
     */
    if (value.key === 'STATUS_ASSIGN_AN_INTERVIEW') {
      return Object.values(src)
    }


    /**
     * В работе > Ожидание
     * "В работе
       - Интервью
       - Шорт-лист
       - Оффер
       Отклонить
       - Отказ кандидату
       Нанят
       - Нанять"
     */
    if (value.key === 'STATUS_WAIT') {
      const defFilterCb = ({ key }) => key === 'STATUS_REFUSAL_OF_THE_CANDIDATE';
      return [
        IN_WORK,
        {
          ...DEFLECTED,
          options: DEFLECTED.options.filter(defFilterCb)
        },
        HIRED
      ]
    }


    /**
     * В работе > Шорт-лист
     * "В работе
       - Интервью
       - Оффер
       Отклонить
       - Отказ кандидату
       Нанят
       - Нанять"
     */
    if (value.key === 'STATUS_SHORT_LIST') {
      const defFilterCb = ({ key }) => key === 'STATUS_REFUSAL_OF_THE_CANDIDATE';
      const inwFilterCb = ({ key }) => key === 'STATUS_ASSIGN_AN_INTERVIEW' || key === 'STATUS_SEND_OFFER';
      return [
        {
          ...IN_WORK,
          options: IN_WORK.options.filter(inwFilterCb)
        },
        {
          ...DEFLECTED,
          options: DEFLECTED.options.filter(defFilterCb)
        },
        HIRED
      ]
    }

    /**
     * В работе > Оффер
     *
     * "Отклонить
       - Отказ кандидату
       Нанят
       - Нанять"
     */
    if (value.key === 'STATUS_SEND_OFFER') {
      const defFilterCb = ({ key }) => key === 'STATUS_REFUSAL_OF_THE_CANDIDATE';

      return [{
        ...DEFLECTED,
        options: DEFLECTED.options.filter(defFilterCb)
      },
        HIRED
      ]
    }
  }


  return [];
}

const MONTHS = 12;

export const calcReward = (salary, reward) => {
  if (salary && reward) {
    const rawIndex = (reward / (salary * MONTHS)) * 100;

    if (!rawIndex) {
      return 0;
    }
    return Math.floor(rawIndex * 100) / 100;
  }
}