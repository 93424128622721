import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Grid, Button, Avatar, CommentCount, Rating } from '../../../../components/ui-kit';
import routs from '../../../../modules/router/routes';
import { ProjectActions, ProjectSelectors } from './../../../../modules/projects';
import { ProfileSelectors } from './../../../../modules/profile';
import { useAuthContext } from '../../../../hooks';
import { RecruiterChannel } from '../../Inbox/RecruiterChannel';
import { ReviewRecruiter } from '../ReviewRecruiter';

export const STATUSES = {
  1: {
    hint: 'declined',
    label: 'Отклонен',
    color: '#EAE9E9',
  },
  0: {
    hint: 'new',
    label: 'Новый',
    color: '#BBE8F4',
  },
  2: {
    hint: 'hired',
    label: 'Нанят',
    color: '#B9FAC7',
  }
};

const checkIsAccepted = (array) => {
  if (!array || !array.length) {
    return false;
  }
  return array.findIndex(({ status_recruiter }) => status_recruiter === 2) !== -1
};

const getColumns = ({formatMessage, projectId, handleClickOnComment, renderActions }) => {
  return [{
    name: '№',
    selector: 'index',
    width: '50px',
    center: true,
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.RECRUITER' }),
    selector: 'name',
    grow: 1,
    cell: ({ last_name, first_name, logo, id  }) => (
      <Link
        to={routs.PROJECT_RECRUITER({ projectId, userId: id })}
        className="Users__avatar is-interact"
      >
        <Avatar src={logo} label={`${last_name} ${first_name}`} size="sm" />
        <div className="Users__avatar-name">{last_name} {first_name}</div>
      </Link>
    ),
  }, {
    name: formatMessage({ id: 'COMMON.COMMENT' }),
    center: true,
    selector: 'comment',
    format: row => (
      <CommentCount
        isViewed={row.viewed}
        onClick={handleClickOnComment(row)}
        isHiddenCounter
      />
    )
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.COUNT_OF_CLOSED_PROJECTS' }),
    selector: 'number_of_closed_projects',
    center: true,
    width: '150px'
  }, {
    name: null,
    selector: 'rating',
    cell: ({ rating, reviews_count: count }) => (
      <Rating isDisable displayValue value={rating} count={count} />
    ),
  }, {
    name: formatMessage({ id: 'GRID.COLUMNS.STATUS' }),
    selector: 'status_recruiter',
    cell: ({ status_recruiter }) => {
      const targetStatus = STATUSES[status_recruiter];

      if (targetStatus) {
        return (
          <div
            className="Projects__status"
            style={{ backgroundColor: targetStatus.color }}
          >
            {targetStatus.label}
          </div>
        )
      }

      return status_recruiter;
    },
  }, {
    name: '',
    selector: 'actions',
    cell: renderActions,
  }];
};

const Recruiters = () => {
  const dispatch = useDispatch();
  const { mode, projectId } = useParams();
  const [isOpenReview, setIsOpenReview] = useState(false);
  const [isOpenHiring, setIsOpenHiring] = useState(false);
  const recruiters = useSelector(ProjectSelectors.selectRecruiters);
  const flags = useSelector(ProjectSelectors.selectFlags);
  const project = useSelector(ProjectSelectors.selectProject);
  const isAccepted = checkIsAccepted(recruiters);
  const [targetRow, setTargetRow] = useState(null);
  const { profile: { role } } = useAuthContext();

  const handleClickOnComment = useCallback(row => {
    return () => {
      setTargetRow(row);
      setIsOpenHiring(true);
    };
  }, []);

  const { formatMessage } = useIntl();

  useEffect(() => {
    dispatch(ProjectActions.getRecruiters({
      projectId
    }));
  }, [projectId]);

  const renderActions = useCallback((row) => {
    if (row.status_recruiter === 0 && !row.isAccepted) {
      const acceptRecruiter = ProjectActions.acceptRecruiter({
        recruiterId: +row.id,
        projectId,
      });

      return (
        <Button
          color="slim"
          label={formatMessage({ id: 'COMMON.CONFIRM' })}
          loading={flags.acceptingRecruiterId === row.id}
          onClick={() => {
            dispatch(acceptRecruiter)
          }}
        />
      );
    }

    if (project && project.status === 3 && row.isAccepted && row.status_recruiter === 2) {
      return (
        <Button
          color="slim"
          label={formatMessage({ id: 'FORM.EVALUATE_RECRUITER_COMMENT' })}
          onClick={() => {
            setIsOpenReview(true);
            setTargetRow(row);
          }}
        />
      );
    }

    return null;
  }, [projectId, project]);

  const mappedRows = useMemo(() => {
    return recruiters ? recruiters.map(recruiter => ({ ...recruiter, isAccepted })) : []
  }, [recruiters, isAccepted]);

  if (role === 'recruiter') {
    return (
      <div className="Projects__grid">
        <Button
          color="link"
          tag={Link}
          to={`${routs.PROJECT({ mode, projectId })}/description`}
          label="Back to project"
        />
      </div>
    )
  }

  return (
    <div className="Projects__grid">
      <ReviewRecruiter
        isOpen={isOpenReview}
        onOpen={setIsOpenReview}
        targetUser={targetRow}
        targetProjectId={projectId}
      />
      {isOpenHiring && (
        <RecruiterChannel
          isOpen={isOpenHiring}
          onToggle={() => setIsOpenHiring(false)}
          projectId={projectId}
          data={targetRow}
        />
      )}
      <Grid
        rows={mappedRows}
        columns={getColumns({
          formatMessage,
          projectId,
          handleClickOnComment,
          renderActions,
        })}
        loading={flags.recruiters}
        cardMode="recruiter"
        extractCardColor={extractCardColor}
      />
    </div>
  );
};

function extractCardColor(row) {
  const targetStatus = STATUSES[row.status_recruiter];

  return targetStatus.color;
}

export default Recruiters;
