import React, {useCallback, useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';
import { useAuthContext } from '../../hooks';
import routs from '../../modules/router/routes';
import { Button, Dialog, TextField } from '../../components/ui-kit';
import { yupResolver } from '@hookform/resolvers/yup';
import { Yup, email } from '../../utils/yup';

const schema = Yup.object().shape({
  email: email(),
});

export const RestorePass = () => {
  const history = useHistory();
  const auth = useAuthContext();
  const [isConfirmSent, setIsConfirmSent] = useState(false);
  const { formatMessage } = useIntl();

  const form = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  })

  const handleCloseDialog = useCallback(() => {
    setIsConfirmSent(false);
    history.push(routs.LOGIN);
  }, []);


  const handleSubmit = useCallback(async (values) => {
    const errors = await auth.restorePass(values);

    if (errors) {
      form.setError("email", {
        type: "manual",
        message: errors.email,
      });
    } else {
      form.clearErrors("email");
      setIsConfirmSent(true);
    }
  }, []);

  return (
    <div className="Auth__form">
      <Dialog
        isOpen={isConfirmSent}
        onToggle={handleCloseDialog}
        actions={[{
          onClick: handleCloseDialog,
          label: formatMessage({ id: 'COMMON.CLOSE' }),
          color: 'primary',
          key: 'CLOSE',
        }]}
      >
        <div className="Auth__dialog">
          <div className="Auth__title">
            <h1>{formatMessage({ id: 'AUTH.RESTORE.DO_NOT_WORRY' })}</h1>
            <p>{formatMessage({ id: 'AUTH.RESTORE.RESTORE_PASS_HINT' })}</p>
          </div>
        </div>
      </Dialog>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <div className="Auth__title">
          <h1>{formatMessage({ id: 'COMMON.FORGOT_PASSWORD' })}</h1>
        </div>
        <div className="Auth__form-group">
          <Controller
            name="email"
            control={form.control}
            render={input => (
              <TextField
                input={input}
                maxRows={1}
                label={formatMessage({ id: 'FORM.E_MAIL' })}
                autoComplete="off"
                type="text"
                meta={{
                  error: form.errors.email ? form.errors.email.message : ''
                }}
              />
            )}
          />
        </div>
        <br/>
        <br/>
        <div className="Auth__form-footer">
          <Button
            type="submit"
            label={formatMessage({ id: 'COMMON.RESET_PASS' })}
            loading={form.formState.isSubmitting}
            color="primary"
            rounded
          />
        </div>
        <div className="Auth__center">
          <Button
            to={routs.LOGIN}
            tag={Link}
            label={formatMessage({ id: 'COMMON.LOGIN' })}
            size="sm"
            color="link"
          />
          &nbsp;&nbsp;<p>{formatMessage({ id: 'COMMON.OR' })}</p>&nbsp;&nbsp;
          <Button
            to={routs.SIGN_UP}
            tag={Link}
            label={formatMessage({ id: 'COMMON.SIGN_UP' })}
            size="sm"
            color="link"
          />
        </div>
      </form>
    </div>
  );
}

