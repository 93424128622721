import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import routs from '../../../../modules/router/routes';
import Recruiters from './Recruiters';
import Recruiter from '../../../Recruiters/Recruiter';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useIntl } from 'react-intl';
import { TABS } from './index'

const RecruitersRoot = () => {
  const { url } = useRouteMatch();
  const { formatMessage } = useIntl();

  return (
    <>
      <BreadcrumbsItem to={url}>{formatMessage(TABS.recruiters.text)}</BreadcrumbsItem>
      <Switch>
        <Route path={`${routs.PROJECT()}${TABS.recruiters.link}`} exact component={Recruiters} />
        <Route path={routs.PROJECT_RECRUITER()} component={Recruiter} />
      </Switch>
    </>
  );
};

export default RecruitersRoot;
