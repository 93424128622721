import React from 'react';
import './styles.sass';
import { Table } from './Table';
import { useResizeContext } from '../../../hooks';
import {Stack} from './Stack';

export default function(props) {
  const {width, bp} = useResizeContext();

  return width < bp.LG ? <Stack {...props}/> : <Table {...props} />
}
