import React from "react";
import { connect } from "react-redux";
import Notifications from "react-notification-system-redux";
import { useIntl } from 'react-intl';
import "./styles.scss";

const Notify = ({ notifications }) => {
  const { formatMessage } = useIntl();
  const messages = notifications.reduce((result, curent) => {
    const isString = typeof curent.message === 'string';
    const needToFormat = !!curent.message.id

    if (isString) {
      return result.concat(curent)
    }

    if (needToFormat) {
      return result.concat({
        ...curent,
        message: formatMessage(curent.message)
      });
    }

    return result
  }, []);

  return (
    <Notifications
      notifications={messages}
      style={false}
    />
  );
};

export default connect(state => ({ notifications: state.notifications }))(Notify);
