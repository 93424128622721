import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {Spinner} from '../../../components/ui-kit';
import routs from '../../../modules/router/routes'
import { ProfileSelectors, ProfileActions } from '../../../modules/profile'
import Form from './Form';
import withBreadCrumb from '../../../HOC/withBreadCrumb';
import { useIntl } from 'react-intl';

const Profile = ({ flags, actions, profile }) => {
  useEffect(() => {
    if (profile && profile.company_id) {
      actions.getCompany(profile);
    }
  }, [profile])
  const { formatMessage } = useIntl();

  return (
    <div className="Company">
      {
        flags.profile && (
          <div className="Company__spinner">
            <Spinner size={30}/>
          </div>
        )
      }
      <Form
        initialValues={Boolean(profile && profile.company) ? profile.company : {}}
        actions={actions}
        readOnly={false}
        flags={flags}
        formatMessage={formatMessage}
      />
    </div>
  );
};

export default withRouter(
  connect(
    state => ({
      profile: ProfileSelectors.selectProfile(state),
      company: ProfileSelectors.selectCompany(state),
      flags: ProfileSelectors.selectFlags(state),
    }),
    dispatch => ({
      actions: bindActionCreators(ProfileActions, dispatch),
    }),
  )(
    withBreadCrumb(Profile, routs.COMPANY_PROFILE, { id: 'NAV.COMPANY.PROFILE' })
  ),
);

