import React from 'react';
import './styles.sass';
import cx from 'classnames';
import { Parser } from 'html-to-react';
import { translationMessages } from '../../../../modules/intl/i18n';
import {ATTACHMENTS} from '../CommentForm';
import { BASE_FILE_URL } from '../../../../app/API';

type Props = {
  file?: string;
  text: string,
  viewed: boolean,
  formatMessage: (params: any) => string,
}
const htmlToReactParser = new Parser();
const KEYS = Object.keys(translationMessages.ru);

const bracketReg = /\[([^\]]+)]/g;


function format(text, formatMessage) {
  const words = text.split(' ');
  return words.map(word => {
    const pureWord = word.replace('[', '').replace(']', '');
    if (KEYS.includes(pureWord)) {
      return word.match(bracketReg) ? `[${formatMessage({ id: pureWord })}]` : formatMessage({ id: pureWord })
    }
    return word
  }).join(' ')
}

const EXTENSIONS = ['xlsx', 'ppt', 'docx', 'pdf', 'doc', 'xls', 'pptx', 'png', 'jpg'];

function parseExtension(url) {
  if (url) {
    const ext = url.split('.').reverse()[0];

    return (
      <div className="Comment__ext">
        {
          EXTENSIONS.includes(ext) ? (
            <span className={`icon-file-${ext}`}/>
          ) : (
            <span className="icon-file-text"/>
          )
        }
        <a
          href={`${BASE_FILE_URL}${url}`}
          target="_blank"
          rel="noopener noreferrer"
          className="Comment__ext-link"
        >
          Відкрити
        </a>
      </div>
    )
  }

  return null;
}

const Comment = ({ text, viewed, formatMessage, file }: Props) => {
  const start = text.match('{{');
  const end = text.match('}}');
  const isCommentExist = start && end;
  let comment = '';
  let base = text;
  if (isCommentExist) {
    const commentLength = end.index - start.index - 2;

    comment = text.substr(start.index + 2, commentLength);
    base = text.substr(0, start.index)
  }

  return (
    <span className="Comment">
      <span className={cx('Comment__base', { 'is-not-viewed': !viewed })}>
        {
          htmlToReactParser.parse(
            format(base, formatMessage).replace(/\[/g, '<strong>').replace(/]/g, '</strong>')
          )
        }
      </span>
      {
        Boolean(isCommentExist && comment) && (
          <span className="Comment__msg" dangerouslySetInnerHTML={{ __html: comment }}/>
        )
      }
      {parseExtension(file)}
    </span>
  )
};

export default Comment;
