import { call, put, select } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';
import { ProfileTypes, ProfileActions, ProfileSelectors } from '../index';
import API, { METHOD, withToken } from '../../../app/API';
import actionCreator, { successNotify } from '../../../utils/actionCreator';


export function* getProfile() {
  try {
    const { data } = yield call(withToken, {
      url: API.PROFILE.ROOT,
      method: METHOD.GET,
    });

    yield put(actionCreator(ProfileTypes.GET_PROFILE_SUCCESS, { data: data || {} }));
  } catch (e) {
    yield put(actionCreator(ProfileTypes.GET_PROFILE_ERROR, { e }));
  }
}

export function* updateProfile({ payload: { phone, ...rest } }) {
  try {
    const profile = yield select(ProfileSelectors.selectProfile);
    const { data, errors } = yield call(withToken, {
      url: API.PROFILE.ROOT,
      method: METHOD.PUT,
      data: {
        ...rest,
        phone: phone.replace(/ /g, '').replace(/\D/g, ''),
      },
    });

    if (errors) {
      return errors;
    }
    if (data) {
      yield put(actionCreator(ProfileTypes.UPDATE_PROFILE_SUCCESS, {
        data: {...profile, phone, ...rest}
      }));
      yield put(successNotify({ id: 'NOTIFICATIONS.SUCCESS.SAVED' }))
    }
  } catch(e) {
    console.log(e, 'UPDATE_PROFFILE_ERROR');
    yield put(actionCreator(ProfileTypes.UPDATE_PROFILE_ERROR));
  }
}

export function* resetEmail({ payload: { email } }) {
  try {
    const { data, errors } = yield call(withToken, {
      url: API.PROFILE.EMAIL,
      method: METHOD.PUT,
      data: {
        email,
      },
    });

    if (errors) {
      yield put(actionCreator(ProfileTypes.RESET_EMAIL_ERROR, {}));
      return errors
    }
    if (data) {
      yield put(actionCreator(ProfileTypes.RESET_EMAIL_SUCCESS, {}));
    }
  } catch (e) {
    yield put(actionCreator(ProfileTypes.RESET_EMAIL_ERROR, {}));
  }
}

export function* resetPassword({ payload: { password1, password2 } }) {
  try {
    const { data, errors } = yield call(withToken, {
      url: API.PROFILE.PASSWORD,
      method: METHOD.PUT,
      data: {
        oldPassword: password1,
        newPassword: password2,
      },
    });

    if (errors) {
      yield put(actionCreator(ProfileTypes.RESET_PASSWORD_ERROR, {}));
      return errors
    }
    if (data) {
      yield put(actionCreator(ProfileTypes.RESET_PASSWORD_SUCCESS, {}));
    }
  } catch (e) {
    yield put(actionCreator(ProfileTypes.RESET_PASSWORD_ERROR, {}));
  }
}

export function* uploadFile({ payload: { FormData, callBack } }) {
  try {
    const { data } = yield call(withToken, {
      data: FormData,
      url: API.UPLOAD_FILE,
      method: METHOD.POST,
    });

    yield put(actionCreator(ProfileTypes.UPLOAD_FILE_SUCCESS, data));
    if (callBack) {
      callBack(data);
    }
  } catch (e) {
    yield put(actionCreator(ProfileTypes.UPLOAD_FILE_ERROR));
  }
}

export function* uploadAvatar({ payload: { FormData, callBack } }) {
  try {
    const { data } = yield call(withToken, {
      url: API.UPLOAD_IMAGE,
      data: FormData,
      method: METHOD.POST,
    });

    yield put(actionCreator(ProfileTypes.UPLOAD_IMAGE_SUCCESS, data));
    if (callBack) {
      callBack(data);
    }
  } catch (e) {
    yield put(actionCreator(ProfileTypes.UPLOAD_IMAGE_ERROR));
  }
}

export function* getCompany({ payload: { company_id } }) {
  try {
    const { data } = yield call(withToken, {
      url: API.PROFILE.COMPANY(company_id),
      method: METHOD.GET,
    });

    yield put(actionCreator(ProfileTypes.GET_COMPANY_SUCCESS, { data }));
  } catch (e) {
    yield put(actionCreator(ProfileTypes.GET_COMPANY_ERROR, { e }));
  }
}

export function* updateCompany({ payload }) {
  try {
    const profile = yield select(ProfileSelectors.selectProfile);
    const { data, errors } = yield call(withToken, {
      url: API.PROFILE.COMPANY(payload.id),
      method: METHOD.PUT,
      data: payload,
    });

    if (errors) {
      throw new SubmissionError(errors)
    }
    if (data) {
      yield put(ProfileActions.updateCompany.success({
        data: {
          ...profile,
          company: data,
        }
      }));
      yield put(successNotify({ id: 'NOTIFICATIONS.SUCCESS.SAVED' }));
    }
  } catch (e) {
    yield put(ProfileActions.updateCompany.failure(e));
  }
}