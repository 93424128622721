import { useContext } from 'react';
import { Context } from '../app/ResizeProvider';

export function useResizeContext() {
  const context = useContext(Context)

  if (!context) {
    throw new Error('useResizeContext should be using inside ResizeProvider context')
  }

  return context
}
