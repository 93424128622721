import { createFormAction } from 'redux-form-saga';
import { UsersTypes } from './index';
import actionCreator from '../../utils/actionCreator';

export const init = () => actionCreator(UsersTypes.INIT);

export const getUsers = (params) => actionCreator(UsersTypes.GET_USERS_REQUEST, params);

export const getStatuses = (params) => actionCreator(UsersTypes.GET_STATUSES_REQUEST, params);

export const activateUser = (params) => actionCreator(UsersTypes.ACTIVATE_USER_REQUEST, params);


export const getUser = (params) => actionCreator(UsersTypes.GET_USER_REQUEST, params);
export const uploadCompanyLogo = (params) => actionCreator(UsersTypes.UPLOAD_COMPANY_LOGO_REQUEST, params);
export const uploadUserLogo = (params) => actionCreator(UsersTypes.UPLOAD_USER_LOGO_REQUEST, params);

export const updateUser = createFormAction('UPDATE_USER');