import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  TextField,
  Button,
  Avatar,
  FormUploader
} from './../../../components/ui-kit';
import { UsersActions } from './../../../modules/users';

const Account = ({
  handleSubmit,
  error,
  flags,
  initialValues,
  actions,
  readOnly,
  formatMessage
}) => (
  <form onSubmit={handleSubmit(UsersActions.updateUser)}>
    <div className="Profile__wrapper">
      <div className="Profile__aside">
        <div className="Profile__avatar">
          {
            !readOnly && (
              <Field
                name="logo"
                component={FormUploader}
                label={formatMessage({ id: 'FORM.UPLOAD_PICTURE' })}
                accepts={['image/*']}
                onUpload={actions.uploadUserLogo}
                id="logo"
                align="center"
                deleteLabel={formatMessage({ id: 'FORM.DELETE_PICTURE' })}
                fileRenderer={(value, Remove) => (
                  <>
                    <Avatar
                      loading={flags.uploadAvatar}
                      src={value}
                      label={`${initialValues.firstName} ${initialValues.lastName}`}
                    />
                    {Remove}
                  </>
                )}
              />
            )
          }
          {
            readOnly && (
              <Avatar
                loading={flags.uploadAvatar}
                src={initialValues.logo}
                label={`${initialValues.firstName} ${initialValues.lastName}`}
              />
            )
          }
        </div>
      </div>
      <div className="Profile__content">
        <div className="row">
          <div className="form-group col-xs-12 col-md-6 col-lg-6">
            <Field
              type="text"
              name="lastName"
              id="lastName"
              disabled={readOnly}
              required
              component={TextField}
              label={formatMessage({ id: 'FORM.LAST_NAME' })}
            />
          </div>
          <div className="form-group col-xs-12 col-md-6 col-lg-6">
            <Field
              type="text"
              name="firstName"
              id="firstName"
              required
              disabled={readOnly}
              component={TextField}
              label={formatMessage({ id: 'FORM.FIRST_NAME' })}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-xs-12 col-md-6 col-lg-6">
            <Field
              type="text"
              name="position"
              id="position"
              required
              disabled={readOnly}
              component={TextField}
              label={formatMessage({ id: 'FORM.POSITION' })}
            />
          </div>
        </div>

        {
          !readOnly && (
            <div className="form-footer lg-static">
              <div className="form-footer__cell"/>
              <div className="form-footer__cell">
                <Button
                  type="submit"
                  label={formatMessage({ id: 'COMMON.SAVE' })}
                  loading={flags.userUpd}
                  prefix="icon-save"
                  color="primary"
                  rounded
                />
              </div>
            </div>
          )
        }
      </div>
    </div>

    {
      error && (
        <div className="Auth__error">{formatMessage({ id: error })}</div>
      )
    }
  </form>
);

export default reduxForm({
  form: 'update-customer-account',
  destroyOnUnmount: false,
  enableReinitialize: true,
})(Account);
