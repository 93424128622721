// @flow

import React from 'react';
import cx from 'classnames';
import {
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import './styles.scss';

type Props = {
  children: any,
  isOpen: boolean,
  onToggle: () => void,
  backdrop?: boolean,
  actions?: Array,
  onConfirm?: () => void,
  onReject?: () => void,
  title: string,
  loading?: boolean,
  disabled?: boolean,
  className?: string,
  wrapClassName?: string,
}

const Dialog = ({
  children,
  isOpen,
  onToggle,
  title,
  footer,
  backdrop,
  className,
  wrapClassName,
}: Props) => (
  <Modal
    wrapClassName={wrapClassName}
    isOpen={isOpen}
    toggle={onToggle}
    className={cx('Dialog', className, {'has-no-title': !title})}
    backdrop={backdrop}
  >
    {
      title && (
        <div className="modal-header">
          <h1 className="modal-title">{title}</h1>
        </div>
      )
    }
    <span className="modal-close" onClick={onToggle}><span className="icon-close2"/></span>
    <ModalBody>{children}</ModalBody>
    {!!footer && <ModalFooter>{footer}</ModalFooter>}
  </Modal>
);

Dialog.defaultProps = {
  actions: [],
  backdrop: false,
  onConfirm: () => false,
  onReject: () => false,
  loading: false,
  disabled: false,
  className: '',
  wrapClassName: '',
};

export default Dialog;
