import React from 'react';
import cx from 'classnames';
// import { Spinner as CoreSpinner } from 'reactstrap';
import './styles.scss';
import { Spinner as CoreSpinner } from './Spinner';

const SIZES = {
  sm: 14,
  md: 28,
  lg: 40,
};

const Spinner = ({ size, isInverted, align, color }) => (
  <span className={cx('Spinner', `align-${align}`, {'is-inverted': isInverted})}>
    <CoreSpinner
      size={SIZES[size]}
      color={color}
    />
  </span>
);

Spinner.defaultProps = {
  align: 'center',
  size: 'md',
  isInverted: false,
};

export default Spinner;
