import { all } from 'redux-saga/effects';

import profileWatchers from '../modules/profile/sagas/watchers';
import projectWatchers from '../modules/projects/sagas/watchers';
import usersWatchers from '../modules/users/sagas/watchers';
import intlSagas from '../modules/intl/sagas/watchers';

export default function* rootSaga() {
  yield all([
    profileWatchers(),
    projectWatchers(),
    usersWatchers(),
    intlSagas(),
  ]);
}
