/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { translationMessages, DEFAULT_LOCALE } from '../../i18n';
import { selectCurrentLocale } from '../../selectors'

export const LanguageProvider = ({ locale, children }) => {
  const translates = translationMessages[locale];

  return (
    <IntlProvider
      locale={locale}
      //key={locale}
      messages={translates}
    >
      {children}
    </IntlProvider>
  );
};

LanguageProvider.defaultProps = {
  locale: DEFAULT_LOCALE
}

export default connect(
  state => ({
    locale: selectCurrentLocale(state),
  }),
)(LanguageProvider)
