import React from 'react';
import type { FormProps } from 'redux-form';
import { Field, reduxForm } from 'redux-form';
import { ProjectActions } from '../../../../modules/projects';
import { ACTIVITY_COMMENT_LENGTH } from '../../../../modules/projects/constants';
import { TextField, Button } from '../../../../components/ui-kit';

type Props = {
  isEdit: boolean,
  submitAction: () => any,
} & FormProps

const CandidateStForm = ({
  handleSubmit,
  loading,
  reset,
  onCancel,
  initialValues,
  error,
  isOpen,
  formatMessage
}: Props) => {
  React.useEffect(() => {
    if (!isOpen) {
      reset()
    }
  }, [isOpen])

  return (
    <form onSubmit={handleSubmit(ProjectActions.changeCandidateStatus)}>
      <Field
        type="hidden"
        id="status"
        name="status"
        component="input"
      />
      {
        initialValues.status.key === 'STATUS_THE_CANDIDATE_IS_HIRED' && (
          <div className="row">
            <div className="form-group col-6">
              <Field
                type="text"
                name="date"
                id="date"
                label={formatMessage({ id: 'FORM.CANDIDATE.DATE_HIRED' })}
                component={TextField}
              />
            </div>
            <div className="form-group col-6">
              <Field
                type="text"
                name="salary"
                id="salary"
                label={formatMessage({ id: 'FORM.CANDIDATE.OFFERED_SALARY' })}
                component={TextField}
              />
            </div>
          </div>
        )
      }
      {
        initialValues.status.key === 'STATUS_WAIT' && (
          <div className="row">
            <div className="form-group col-12">
              <Field
                type="text"
                name="time"
                id="time"
                label={formatMessage({ id: 'FORM.CANDIDATE.WAITING_TIME' })}
                component={TextField}
              />
            </div>
          </div>
        )
      }
      <div className="row">
        <div className="form-group col-12">
          <Field
            type="textarea"
            name="comment"
            id="comment"
            component={TextField}
            label={formatMessage({ id: 'FORM.CANDIDATE.STATUS_COMMENT' })}
            minRows={5}
            maxRows={10}
            maxLength={ACTIVITY_COMMENT_LENGTH}
          />
          {
            error && (
              <div className="Auth__error">{formatMessage({ id: error })}</div>
            )
          }
        </div>
      </div>
      <div className="Projects__actions flex-center">
        <Button
          onClick={onCancel}
          label={formatMessage({ id: 'COMMON.NO' })}
        />
        <Button
          label={formatMessage({ id: 'COMMON.YES' })}
          type="submit"
          loading={loading}
        />
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'candidate-status',
  enableReinitialize: true,
  destroyOnUnmount: false,
})(CandidateStForm)
