import React, { useEffect } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useSelector, useDispatch } from 'react-redux';
import { Page, Spinner } from './../../../components/ui-kit';
import { UsersSelectors, UsersActions } from './../../../modules/users';
import Account from './../Account';

const User = () => {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const flags = useSelector(UsersSelectors.selectFlags);
  const user = useSelector(UsersSelectors.selectUser);
  const { userId } = useParams();

  useEffect(() => {
    dispatch(UsersActions.getUser({ userId }));
  }, [userId]);

  return (
    <div className="Users">
      <BreadcrumbsItem to={url}>
        {user && user.first_name ? `${user.first_name} ${user.last_name}` : `#${userId}`}
      </BreadcrumbsItem>
      <div className="Users__scroll-view">
        {flags.userGet
          ? (
            <Page>
              <Spinner size="md" />
            </Page>
          ) : (
          <Account
            flags={flags}
            readOnly
            initialValues={user || {}}
          />
        )}
      </div>
    </div>
  )
};

export default User;
