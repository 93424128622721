import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {useDispatch} from 'react-redux';
import routs from '../../modules/router/routes';
import { ProfileActions } from './../../modules/profile';
import { Notification } from '../../components/ui-kit';
import Company from './../Company';
import Projects from './../Projects';
import Profile from './../Profile';
import { Layout } from './Layout';
import Recruiters from '../Recruiters';
import Customers from '../Customers';
import Inbox from '../Projects/Inbox';
import { useAuthContext } from './../../hooks/useAuthContext';
import withBreadCrumb from '../../HOC/withBreadCrumb';
import { useHistory } from 'react-router-dom';

const Main = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useAuthContext();

  useEffect(() => {
    dispatch(ProfileActions.getProfile())
  }, []);

  useEffect(() => {
    if (auth.inSafeLocation) {
      history.push(auth.inSafeLocation);
      auth.setInSafeLocation('');
    }
  }, [auth.inSafeLocation]);

  return (
    <Route
      path={routs.HOME}
      render={() => (
        <Layout>
          <Switch>
            <Route path={routs.CUSTOMERS} component={Customers} />
            <Route path={routs.RECRUITERS} component={Recruiters} />
            <Route path={routs.PROFILE} component={Profile} />
            <Route path={routs.PROJECTS__ROOT} component={Projects} />
            <Route path={routs.COMPANY} component={Company} />
            <Route path={routs.INBOX} component={Inbox} />
            <Redirect from={routs.HOME} to={routs.PROFILE} />
          </Switch>
          <Notification/>
        </Layout>
      )}
    />
  );
};

export default withBreadCrumb(Main, routs.HOME, {id: 'NAV.INDEX'})