import React from 'react';
import Auth from './../../containers/Auth';
import Main from './../../containers/Main';
import { useAuthContext, useApi } from '../../hooks';
import {Provider} from '../../app/ResizeProvider'


export const CheckAccess  = () => {
  const auth = useAuthContext();
  const { fetching } = useApi(auth.checkToken);

  if (fetching) {
    return null;
  }

  return (
    <Provider>
      {auth.isAuth ? <Main fetching={fetching} /> : <Auth fetching={fetching} />}
    </Provider>
  )
};
