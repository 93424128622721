import mc from 'mirror-creator';

export default mc([
  'INIT',
  'DESTROY',

  'GET_PROFILE_REQUEST',
  'GET_PROFILE_SUCCESS',
  'GET_PROFILE_ERROR',

  'GET_ACCOUNT_REQUEST',
  'GET_ACCOUNT_SUCCESS',
  'GET_ACCOUNT_ERROR',

  'UPLOAD_FILE_REQUEST',
  'UPLOAD_FILE_SUCCESS',
  'UPLOAD_FILE_ERROR',

  'UPLOAD_IMAGE_REQUEST',
  'UPLOAD_IMAGE_SUCCESS',
  'UPLOAD_IMAGE_ERROR',

  'GET_COMPANY_REQUEST',
  'GET_COMPANY_SUCCESS',
  'GET_COMPANY_ERROR',

  'UPDATE_PROFILE_REQUEST',
  'UPDATE_PROFILE_SUCCESS',
  'UPDATE_PROFILE_ERROR',

  'RESET_EMAIL_REQUEST',
  'RESET_EMAIL_SUCCESS',
  'RESET_EMAIL_ERROR',

  'RESET_PASSWORD_REQUEST',
  'RESET_PASSWORD_SUCCESS',
  'RESET_PASSWORD_ERROR',
], {
  prefix: 'PROFILE:',
});

export const USER_TYPES = {
  ADMIN: 'admin',
  CLIENT: 'client',
  RECRUITER: 'recruiter',
};
