import React, {useCallback, useEffect, useState} from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { ProjectActions } from '../../../modules/projects';
import { TextField, Button } from '../../../components/ui-kit';
import { STATUS_ACTIONS } from '../../../modules/projects/constants';
import { useIntl } from 'react-intl';
import {connect, useDispatch, useSelector} from 'react-redux'
import cx from 'classnames';
import {UsersSelectors} from "../../../modules/users";

function switchActionByType(status) {
  if (status === STATUS_ACTIONS.PAUSE.key
    || status === STATUS_ACTIONS.PAUSE_CONFIRM.key) {
    return ProjectActions.askProjectPause;
  }

  if (
    status === STATUS_ACTIONS.FINISH.key
    || status === STATUS_ACTIONS.ACTIVATE.key
    || status === STATUS_ACTIONS.RESTORE.key
  ) {
    return ProjectActions.changeProjectStatus;
  }

  if (status === STATUS_ACTIONS.CHANGE_RECRUITER.key || status === STATUS_ACTIONS.CHOOSE_RECRUITER.key) {
    return ProjectActions.changeRecruiter;
  }

  return {};
}

const ProjectStForm = ({
  handleSubmit,
  loading,
  onCancel,
  status,
  initialValues,
  change,
  recruiter
}) => {
  const dispatch = useDispatch();
  const recruiters = useSelector(UsersSelectors.selectUsers);
  const [shouldClose, setShouldClose] = useState(false);
  const { formatMessage } = useIntl();
  const userLoaders = useSelector(UsersSelectors.selectFlags);

  const handleChangeRecruiter = useCallback((value) => {
    return () => {
      change('recruiter', value);
    };
  }, []);

  const handleDelete = useCallback(() => {
    dispatch(ProjectActions.deleteProject({
      projectId: initialValues.project.id,
      callBack: onCancel,
      status,
    }));
  }, [initialValues, status]);

  useEffect(() => {
    if (loading !== shouldClose) {
      setShouldClose(loading);
      if (!loading) {
        onCancel()
      }
    }
  }, [loading, shouldClose]);

  return (
    <form onSubmit={handleSubmit(switchActionByType(status))}>
      {status === STATUS_ACTIONS.CHOOSE_RECRUITER.key && (
        <>
        {userLoaders.users && <div>Loading...</div>}
        <Field
          component="input"
          id="recruiter"
          name="recruiter"
          type="hidden"
        />
        <Field
          component="input"
          id="role"
          name="role"
          type="hidden"
        />
        <div className="Projects__recruiters-list">
          {
            recruiters && recruiters.map((user) => (
              <div
                className={cx('Users__avatar is-interact', {
                  'is-active': user.id === recruiter.id
                })}
                key={user.id}
                onClick={handleChangeRecruiter(user)}
              >
                <div className="Users__avatar-name">{user.first_name} {user.last_name}</div>
              </div>
              ))
            }
          </div>
        </>
      )}
      {status === STATUS_ACTIONS.PAUSE.key && (
        <div className="row">
          <div className="form-group col-12">
            <Field
              type="textarea"
              name="comment"
              id="comment"
              component={TextField}
              label={formatMessage({ id: 'FORM.COMMENT' })}
              minRows={5}
              maxRows={10}
            />
          </div>
        </div>
      )}
      {status === STATUS_ACTIONS.FINISH.key && (
        <div className="row">
          <div className="form-group col-12">
            <Field
              type="textarea"
              name="comment"
              id="comment"
              component={TextField}
              label={formatMessage({ id: 'FORM.EVALUATE_RECRUITER_COMMENT' })}
              minRows={5}
              maxRows={10}
            />
          </div>
        </div>
      )}

      <div className="Projects__actions flex-center">
        <Button
          onClick={onCancel}
          label={formatMessage({ id: 'COMMON.NO' })}
        />
        {status === STATUS_ACTIONS.DELETE.key && (
          <Button
            label={formatMessage({ id: 'COMMON.DELETE' })}
            onClick={handleDelete}
            loading={loading}
          />
        )}
        {status === STATUS_ACTIONS.FORCE_DELETE.key && (
          <Button
            label={formatMessage({ id: 'COMMON.FORCE_DELETE' })}
            onClick={handleDelete}
            loading={loading}
          />
        )}
        {status !== STATUS_ACTIONS.DELETE.key && status !== STATUS_ACTIONS.FORCE_DELETE.key && (
          <Button
            label={formatMessage({ id: 'COMMON.YES' })}
            type="submit"
            loading={loading}
          />
        )}
      </div>
    </form>
  );
};

const selector = formValueSelector('project-status');

export default connect(state => ({
  recruiter: selector(state, 'recruiter')
}))(reduxForm({
  form: 'project-status',
  enableReinitialize: true,
  destroyOnUnmount: false,
})(ProjectStForm))