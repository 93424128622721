import { takeLatest } from 'redux-saga/effects';
import { UsersTypes } from '../index';
import * as workers from './workers';

export default function* () {
  yield takeLatest(UsersTypes.INIT, workers.init);
  yield takeLatest(UsersTypes.GET_USERS_REQUEST, workers.getUsers);
  yield takeLatest(UsersTypes.GET_STATUSES_REQUEST, workers.getStatuses);
  yield takeLatest(UsersTypes.ACTIVATE_USER_REQUEST, workers.activateUser);
  yield takeLatest(UsersTypes.GET_USER_REQUEST, workers.getUser);
  yield takeLatest(UsersTypes.UPLOAD_COMPANY_LOGO_REQUEST, workers.uploadCompanyLogo);
  yield takeLatest(UsersTypes.UPLOAD_USER_LOGO_REQUEST, workers.uploadUserLogo);
  //yield takeLatest(UsersActions.updateUser.REQUEST, workers.updateUser);
}
