import React from 'react';
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import routs from '../../modules/router/routes';
import ProjectEdit from './Project/Edit'
import withBreadCrumb from './../../HOC/withBreadCrumb';
import List from './List';
import './styles.sass'

const Projects = () => (
  <Switch>
    <Route path={routs.PROJECTS()} component={List} />
    <Route path={routs.ADD_PROJECT} component={ProjectEdit} />
    <Route path={routs.PROJECT()} component={ProjectEdit} />
    <Redirect to={routs.PROJECTS('active')} />
  </Switch>
);

export default withBreadCrumb(Projects, routs.PROJECTS__ROOT, {id: 'NAV.PROJECTS'});
