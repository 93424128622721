import React, {useCallback} from 'react';
import { useIntl } from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { TextField, Button } from './../../../components/ui-kit';
import {ProfileActions, ProfileSelectors} from './../../../modules/profile';
import { reducePasswordError } from '../../Auth';
import { Yup, password, repeatPassword } from '../../../utils/yup';
import {injectMetaIntoError} from "../../Auth/ConfirmRestoring";

const schema = Yup.object().shape({
  password1: password(),
  password2: password(),
  password3: repeatPassword('password2'),
});

export const ResetPass = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const flags = useSelector(ProfileSelectors.selectFlags);
  const reducePlaceholder = reducePasswordError(formatMessage);
  const form = useForm({
    defaultValues: {
      password1: '',
      password2: '',
      password3: '',
    },
    resolver: yupResolver(schema),
  });

  const handleSubmit = useCallback((values) => {
    dispatch(ProfileActions.resetPassword(values))
  }, []);

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <div className="row align-end">
        <div className="col-xs-6 col-md-6 col-lg-3 form-group">
          <Controller
            name="password1"
            control={form.control}
            render={input => (
              <TextField
                id="password1"
                type="password"
                input={input}
                maxRows={1}
                meta={{
                  formatted: true,
                  error: form.errors.password1
                    ? injectMetaIntoError(form.errors.password1.message, formatMessage)
                    : ''
                }}
                label={formatMessage({ id: 'FORM.OLD_PASSWORD' })}
              />
            )}
          />
        </div>
        <div className="col-xs-6 col-md-6 col-lg-3 form-group">
          <Controller
            name="password2"
            control={form.control}
            render={input => (
              <TextField
                id="password2"
                type="password"
                maxRows={1}
                input={input}
                meta={{
                  formatted: true,
                  error: form.errors.password2
                    ? injectMetaIntoError(form.errors.password2.message, formatMessage)
                    : ''
                }}
                label={formatMessage({ id: 'FORM.NEW_PASSWORD' })}
                placeholder={reducePlaceholder}
                hint={reducePlaceholder}
              />
            )}
          />
        </div>
        <div className="col-xs-6 col-md-6 col-lg-3 form-group">
          <Controller
            name="password3"
            control={form.control}
            render={input => (
              <TextField
                id="password3"
                type="password"
                maxRows={1}
                input={input}
                meta={{
                  formatted: true,
                  error: form.errors.password3
                    ? injectMetaIntoError(form.errors.password3.message, formatMessage)
                    : ''
                }}
                label={formatMessage({ id: 'FORM.REPEAT_NEW_PASSWORD' })}
              />
            )}
          />
        </div>
        <div className="col-xs-6 col-md-6 col-lg-3 form-group">
          <Button
            type="submit"
            label={formatMessage({ id: 'COMMON.SAVE' })}
            loading={flags.restorePass}
            color="primary"
            rounded
          />
        </div>
      </div>
    </form>
  );
};

