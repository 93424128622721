import {
  createStore,
  compose,
  applyMiddleware,
} from 'redux';
import formActionSaga from 'redux-form-saga';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';

import { getUserConfirmation } from '../components/ui-kit/PromptMsg';
import getReducers from './reducers';
import rootSaga from './rootSaga';

// eslint-disable-next-line no-underscore-dangle
const monitor = process.env.NODE_ENV === 'development' && window.__SAGA_MONITOR_EXTENSION__;

const isDevelopment = process.env.REACT_APP_MODE !== 'production';
const sagas = [rootSaga, formActionSaga];
export const sagaMiddleware = createSagaMiddleware({ sagaMonitor: monitor });

export const runRootSaga = () => sagas.forEach(saga => sagaMiddleware.run(saga));
window.history.replaceState({}, '', window.location.hash.substring(2));
export const history = createBrowserHistory({ getUserConfirmation });

const getMiddleWares = () => applyMiddleware(routerMiddleware(history), sagaMiddleware);
export const configureStore = (initialState = {}) => createStore(
  connectRouter(history)(getReducers(history)),
  initialState,
  compose(
    isDevelopment ? composeWithDevTools(getMiddleWares()) : getMiddleWares()
  ),
);
